"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantApi = exports.TenantApiFactory = exports.TenantApiFp = exports.TenantApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * TenantApi - axios parameter creator
 * @export
 */
exports.TenantApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get all project managers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectManagers: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/tenant/projectManager/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get project manager summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectManagerSummary: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/tenant/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get tenant configuration
         * @param {string} [tenantId] tenantId
         * @param {string} [tenantName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantConfiguration: (tenantId, tenantName, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/tenant/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get tenant enabled services
         * @param {string} [tenantId] tenantId
         * @param {string} [tenantName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantEnabledServices: (tenantId, tenantName, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/tenant/services/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Purge tenant configuration
         * @param {string} [tenantId] tenantId
         * @param {string} [tenantName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purgeTenantConfiguration: (tenantId, tenantName, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/tenant/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * TenantApi - functional programming interface
 * @export
 */
exports.TenantApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.TenantApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get all project managers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectManagers(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllProjectManagers(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get project manager summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectManagerSummary(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getProjectManagerSummary(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get tenant configuration
         * @param {string} [tenantId] tenantId
         * @param {string} [tenantName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantConfiguration(tenantId, tenantName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTenantConfiguration(tenantId, tenantName, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get tenant enabled services
         * @param {string} [tenantId] tenantId
         * @param {string} [tenantName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantEnabledServices(tenantId, tenantName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTenantEnabledServices(tenantId, tenantName, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Purge tenant configuration
         * @param {string} [tenantId] tenantId
         * @param {string} [tenantName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purgeTenantConfiguration(tenantId, tenantName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.purgeTenantConfiguration(tenantId, tenantName, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * TenantApi - factory interface
 * @export
 */
exports.TenantApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.TenantApiFp(configuration);
    return {
        /**
         *
         * @summary Get all project managers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectManagers(options) {
            return localVarFp.getAllProjectManagers(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get project manager summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectManagerSummary(options) {
            return localVarFp.getProjectManagerSummary(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get tenant configuration
         * @param {string} [tenantId] tenantId
         * @param {string} [tenantName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantConfiguration(tenantId, tenantName, options) {
            return localVarFp.getTenantConfiguration(tenantId, tenantName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get tenant enabled services
         * @param {string} [tenantId] tenantId
         * @param {string} [tenantName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantEnabledServices(tenantId, tenantName, options) {
            return localVarFp.getTenantEnabledServices(tenantId, tenantName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Purge tenant configuration
         * @param {string} [tenantId] tenantId
         * @param {string} [tenantName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purgeTenantConfiguration(tenantId, tenantName, options) {
            return localVarFp.purgeTenantConfiguration(tenantId, tenantName, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
class TenantApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get all project managers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    getAllProjectManagers(options) {
        return exports.TenantApiFp(this.configuration).getAllProjectManagers(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get project manager summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    getProjectManagerSummary(options) {
        return exports.TenantApiFp(this.configuration).getProjectManagerSummary(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get tenant configuration
     * @param {string} [tenantId] tenantId
     * @param {string} [tenantName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    getTenantConfiguration(tenantId, tenantName, options) {
        return exports.TenantApiFp(this.configuration).getTenantConfiguration(tenantId, tenantName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get tenant enabled services
     * @param {string} [tenantId] tenantId
     * @param {string} [tenantName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    getTenantEnabledServices(tenantId, tenantName, options) {
        return exports.TenantApiFp(this.configuration).getTenantEnabledServices(tenantId, tenantName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Purge tenant configuration
     * @param {string} [tenantId] tenantId
     * @param {string} [tenantName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    purgeTenantConfiguration(tenantId, tenantName, options) {
        return exports.TenantApiFp(this.configuration).purgeTenantConfiguration(tenantId, tenantName, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.TenantApi = TenantApi;
