import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))((value) => {
	return {
		width: 36,
		height: 20,
		padding: 0,
		"& .MuiSwitch-switchBase": {
			padding: 0,
			margin: 2,
			transitionDuration: "300ms",
			"&.Mui-checked": {
				transform: "translateX(16px)",
				color: "#fff",
				"& + .MuiSwitch-track": {
					backgroundColor:
						// value.color === "secondary" ? value.theme.palette.background.paper : value.theme.palette.primary.main,
						value.theme.palette.success.main,
					opacity: 1,
					border: 0,
				},
				"&.Mui-disabled + .MuiSwitch-track": {
					opacity: 0.5,
				},
			},
			"&.Mui-focusVisible .MuiSwitch-thumb": {
				color: "#33cf4d",
				border: "6px solid #fff",
			},
			"&.Mui-disabled, .MuiSwitch-thumb": {
				color:
					value.theme.palette.mode === "light"
						? value.color === "secondary"
							? value.theme.palette.secondary.main
							: value.theme.palette.grey[100]
						: value.theme.palette.grey[600],
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: value.theme.palette.mode === "light" ? 0.7 : 0.3,
			},
		},
		"& .MuiSwitch-thumb": {
			boxSizing: "border-box",
			width: 16,
			height: 16,
		},
		"& .MuiSwitch-track": {
			borderRadius: 20 / 2,
			backgroundColor:
				value.theme.palette.mode === "light"
					? value.color === "secondary"
						? value.theme.palette.background.paper
						: "#E9E9EA"
					: "#39393D",
			opacity: 1,
			transition: value.theme.transitions.create(["background-color"], {
				duration: 500,
			}),
		},
	};
});

export default IOSSwitch;
