/** @jsxImportSource @emotion/react */
import { Button, Container, DialogContent, IconButton, InputBase, Stack, Typography } from "@mui/material";
import type { SprintContent, SprintContentTypeEnum } from "innovation-api-manager";
import React, { Fragment, useEffect, useState } from "react";
import BottomSheet from "../../../../../../../components/BottomSheet";
import LoadingIconButton from "../../../../../../../components/buttons/LoadingIconButton";
import ContentEditor from "../../../../../../../components/ContentEditor";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { useSprintContentsContextSelector } from "../controllers/SprintContentsController";

type CreateSprintContentDialogProps =
	| {
			open: boolean;
			onClose: () => void;
			mode?: "create";
			contentType?: SprintContentTypeEnum | "questions";
			sprintContent?: never;
			onSave: () => Promise<void>;
			onDelete?: never;
	  }
	| {
			open: boolean;
			onClose: () => void;
			mode?: "edit";
			contentType?: SprintContentTypeEnum | "questions";
			sprintContent?: SprintContent;
			onSave: () => Promise<void>;
			onDelete: () => void;
	  };

const CreateSprintContentDialog: React.FC<CreateSprintContentDialogProps> = (props) => {
	const { open, onClose, mode = "create", contentType = "content", sprintContent, onSave, onDelete } = props;

	const editMode = mode === "edit";

	const temporarySprintContent = useSprintContentsContextSelector("temporarySprintContent");
	const setTemporarySprintContent = useSprintContentsContextSelector("setTemporarySprintContent");

	useEffect(() => {
		if (open && editMode && !!sprintContent) {
			setTemporarySprintContent((draft) => {
				draft.title = sprintContent?.title || "";
				draft.content = { body: sprintContent?.content?.body || "" };
			});
		} else {
			setTemporarySprintContent((draft) => {
				draft.title = "";
				draft.content = { body: "" };
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, sprintContent]);

	const [onSaveLoading, setOnSaveLoading] = useState(false);

	return (
		<BottomSheet open={open} onClose={onClose} containerSx={{ maxHeight: "95vh" }}>
			<Fragment>
				<Container maxWidth="md">
					<DialogContent sx={{ height: "100%", paddingBottom: 0 }}>
						<Stack sx={{ height: "100%" }}>
							<Stack spacing={4}>
								<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
									<Typography
										children={editMode ? "Modifica sprint" : "Crea un nuovo sprint"}
										variant="h6"
										sx={{ fontWeight: 600 }}
									/>
									<IconButton onClick={onClose}>
										<IPlatformIcon name="close" />
									</IconButton>
								</Stack>
								<InputBase
									fullWidth
									value={temporarySprintContent?.title}
									// variant="filled"
									onChange={(e) => {
										// onChange("title", e.target.value);
										// if (errorTitle) setErrorTitle(false);
										setTemporarySprintContent((draft) => {
											draft.title = e.target.value;
										});
									}}
									// label="Nome del contenuto"
									placeholder={"Titolo del contenuto"}
									// multiline
									sx={{
										fontSize: 24,
										padding: 3,
										marginRight: 4,
										// border: errorTitle ? "1px solid red" : undefined,
										borderRadius: 3,
										fontWeight: "bold",
										transition: "background-color 300ms",
										":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
										// textDecoration: (entry.title || "") !== "" ? undefined : "underline",
									}}
								/>
								<div css={{ flex: 1 }}>
									<ContentEditor
										// disableFileArea={false}
										initialValue={sprintContent?.content?.body || ""}
										withFileArea={contentType === "deliverable"}
										// initialValue={(entry as any)?.content?.body?.body || (entry as any)?.content?.body || ""}
										// initialValue={""}
										onChange={(value: any) => {
											// console.log(999, value);
											// onChange("content", value);
											setTemporarySprintContent((draft) => {
												if (!!draft.content) {
													draft.content.body = value;
												}
											});
										}}
										fullHeight
										// key={temporarySprintContent?.title}
									/>
								</div>
							</Stack>
						</Stack>
					</DialogContent>
				</Container>
				<div css={{ width: "100%", height: 76 }}>
					<Container maxWidth="md" sx={{ height: "100%" }}>
						<Stack
							direction="row"
							justifyContent={editMode ? "space-between" : "flex-end"}
							alignItems="center"
							sx={{ height: "100%", px: 6 }}
						>
							{editMode && (
								<Button
									children="Elimina contenuto"
									variant="contained"
									color="error"
									startIcon={<IPlatformIcon name="delete_outline" />}
									onClick={onDelete}
								/>
							)}
							<LoadingIconButton
								loading={onSaveLoading}
								children="Salva contenuto"
								variant="contained"
								icon={<IPlatformIcon name="success" />}
								onClick={async () => {
									if (!temporarySprintContent.content?.body.includes("UPLOAD_FILE_AREA") && contentType === "deliverable") {
										MLDialog.showSnackbar("Il deliverable deve contenere una FILE AREA per essere valido", { variant: "error" });
										return;
									}
									setOnSaveLoading(true);
									await onSave();
									setOnSaveLoading(false);
								}}
							/>
						</Stack>
					</Container>
				</div>
			</Fragment>
		</BottomSheet>
	);
};

export default CreateSprintContentDialog;
