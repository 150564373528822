"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchApi = exports.SearchApiFactory = exports.SearchApiFp = exports.SearchApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * SearchApi - axios parameter creator
 * @export
 */
exports.SearchApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} q
         * @param {number} [books]
         * @param {number} [suppliers]
         * @param {number} [deepeningsections]
         * @param {number} [startups]
         * @param {number} [users]
         * @param {number} [wordpressarticles]
         * @param {number} [discounts]
         * @param {number} [lessons]
         * @param {number} [software]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGlobally: (q, books, suppliers, deepeningsections, startups, users, wordpressarticles, discounts, lessons, software, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'q' is not null or undefined
            common_1.assertParamExists('searchGlobally', 'q', q);
            const localVarPath = `/globalSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }
            if (books !== undefined) {
                localVarQueryParameter['books'] = books;
            }
            if (suppliers !== undefined) {
                localVarQueryParameter['suppliers'] = suppliers;
            }
            if (deepeningsections !== undefined) {
                localVarQueryParameter['deepeningsections'] = deepeningsections;
            }
            if (startups !== undefined) {
                localVarQueryParameter['startups'] = startups;
            }
            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }
            if (wordpressarticles !== undefined) {
                localVarQueryParameter['wordpressarticles'] = wordpressarticles;
            }
            if (discounts !== undefined) {
                localVarQueryParameter['discounts'] = discounts;
            }
            if (lessons !== undefined) {
                localVarQueryParameter['lessons'] = lessons;
            }
            if (software !== undefined) {
                localVarQueryParameter['software'] = software;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * SearchApi - functional programming interface
 * @export
 */
exports.SearchApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.SearchApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} q
         * @param {number} [books]
         * @param {number} [suppliers]
         * @param {number} [deepeningsections]
         * @param {number} [startups]
         * @param {number} [users]
         * @param {number} [wordpressarticles]
         * @param {number} [discounts]
         * @param {number} [lessons]
         * @param {number} [software]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGlobally(q, books, suppliers, deepeningsections, startups, users, wordpressarticles, discounts, lessons, software, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchGlobally(q, books, suppliers, deepeningsections, startups, users, wordpressarticles, discounts, lessons, software, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * SearchApi - factory interface
 * @export
 */
exports.SearchApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.SearchApiFp(configuration);
    return {
        /**
         *
         * @param {string} q
         * @param {number} [books]
         * @param {number} [suppliers]
         * @param {number} [deepeningsections]
         * @param {number} [startups]
         * @param {number} [users]
         * @param {number} [wordpressarticles]
         * @param {number} [discounts]
         * @param {number} [lessons]
         * @param {number} [software]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGlobally(q, books, suppliers, deepeningsections, startups, users, wordpressarticles, discounts, lessons, software, options) {
            return localVarFp.searchGlobally(q, books, suppliers, deepeningsections, startups, users, wordpressarticles, discounts, lessons, software, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
class SearchApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} q
     * @param {number} [books]
     * @param {number} [suppliers]
     * @param {number} [deepeningsections]
     * @param {number} [startups]
     * @param {number} [users]
     * @param {number} [wordpressarticles]
     * @param {number} [discounts]
     * @param {number} [lessons]
     * @param {number} [software]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    searchGlobally(q, books, suppliers, deepeningsections, startups, users, wordpressarticles, discounts, lessons, software, options) {
        return exports.SearchApiFp(this.configuration).searchGlobally(q, books, suppliers, deepeningsections, startups, users, wordpressarticles, discounts, lessons, software, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.SearchApi = SearchApi;
