/** @jsxImportSource @emotion/react */

import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import NotFoundIllustration from "../../assets/images/404_illustration.png";
import ScaffoldAppBar from "../../components/scaffold/components/ScaffoldAppBar";
import { useTopAction } from "../../components/scaffold/controllers/TopActionController";

const NotFoundPage = () => {
	const removeToAction = useTopAction((state) => state.removeTopAction);

	useEffect(() => {
		removeToAction();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<ScaffoldAppBar />
			<Stack sx={{ height: "100vh" }} alignItems="center" justifyContent="center">
				<Grid container alignItems={"center"} justifyContent="center" columnSpacing={15}>
					<Grid item xs={6} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
						<Stack gap={12} sx={{ maxWidth: 520 }}>
							<Box>
								<Typography children="Ooops..." variant="h2" fontWeight={800} />
								<Typography children="Pagina non trovata" variant="h4" fontWeight={400} />
							</Box>
							<Typography
								children="Come hai fatto ad arrivare qui? Torna alla lista dei tenants e magari troverai quello che stavi cercando!"
								variant="h6"
								fontWeight={400}
							/>
							<Button
								href="/tenants"
								children="Vai alla lista tenants"
								size="large"
								variant="contained"
								sx={{ height: 56, textTransform: "uppercase", width: "fit-content" }}
							/>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<img src={NotFoundIllustration} alt="Page not found" css={{ width: "100%", maxWidth: 630 }} />
					</Grid>
				</Grid>
			</Stack>
		</Fragment>
	);
};
export default NotFoundPage;
