"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SprintContentApi = exports.SprintContentApiFactory = exports.SprintContentApiFp = exports.SprintContentApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * SprintContentApi - axios parameter creator
 * @export
 */
exports.SprintContentApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get sprint content interactions
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSprintContentInteractions: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getSprintContentInteractions', 'id', id);
            const localVarPath = `/sprintContent/{id}/interactions/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get sprint contents
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSprintContents: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getSprintContents', 'id', id);
            const localVarPath = `/sprint/{id}/contents/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Set content as completed
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setContentAsCompleted: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('setContentAsCompleted', 'id', id);
            const localVarPath = `/sprintContent/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Set content as uncompleted
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setContentAsUncompleted: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('setContentAsUncompleted', 'id', id);
            const localVarPath = `/sprintContent/{id}/uncomplete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Upload deliverable
         * @param {string} id
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDeliverable: (id, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('uploadDeliverable', 'id', id);
            const localVarPath = `/sprintContent/{id}/deliverable/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * SprintContentApi - functional programming interface
 * @export
 */
exports.SprintContentApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.SprintContentApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get sprint content interactions
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSprintContentInteractions(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSprintContentInteractions(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get sprint contents
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSprintContents(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSprintContents(id, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Set content as completed
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setContentAsCompleted(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setContentAsCompleted(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Set content as uncompleted
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setContentAsUncompleted(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setContentAsUncompleted(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Upload deliverable
         * @param {string} id
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDeliverable(id, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.uploadDeliverable(id, file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * SprintContentApi - factory interface
 * @export
 */
exports.SprintContentApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.SprintContentApiFp(configuration);
    return {
        /**
         *
         * @summary Get sprint content interactions
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSprintContentInteractions(id, options) {
            return localVarFp.getSprintContentInteractions(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get sprint contents
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSprintContents(id, filter, options) {
            return localVarFp.getSprintContents(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set content as completed
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setContentAsCompleted(id, options) {
            return localVarFp.setContentAsCompleted(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set content as uncompleted
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setContentAsUncompleted(id, options) {
            return localVarFp.setContentAsUncompleted(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Upload deliverable
         * @param {string} id
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDeliverable(id, file, options) {
            return localVarFp.uploadDeliverable(id, file, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SprintContentApi - object-oriented interface
 * @export
 * @class SprintContentApi
 * @extends {BaseAPI}
 */
class SprintContentApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get sprint content interactions
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SprintContentApi
     */
    getSprintContentInteractions(id, options) {
        return exports.SprintContentApiFp(this.configuration).getSprintContentInteractions(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get sprint contents
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SprintContentApi
     */
    getSprintContents(id, filter, options) {
        return exports.SprintContentApiFp(this.configuration).getSprintContents(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Set content as completed
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SprintContentApi
     */
    setContentAsCompleted(id, options) {
        return exports.SprintContentApiFp(this.configuration).setContentAsCompleted(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Set content as uncompleted
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SprintContentApi
     */
    setContentAsUncompleted(id, options) {
        return exports.SprintContentApiFp(this.configuration).setContentAsUncompleted(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Upload deliverable
     * @param {string} id
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SprintContentApi
     */
    uploadDeliverable(id, file, options) {
        return exports.SprintContentApiFp(this.configuration).uploadDeliverable(id, file, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.SprintContentApi = SprintContentApi;
