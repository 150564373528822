"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CourseApi = exports.CourseApiFactory = exports.CourseApiFp = exports.CourseApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * CourseApi - axios parameter creator
 * @export
 */
exports.CourseApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get all course modules
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourseModules: (courseId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'courseId' is not null or undefined
            common_1.assertParamExists('getAllCourseModules', 'courseId', courseId);
            const localVarPath = `/course/{courseId}/module/`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all courses
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourses: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/course/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get already started courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlreadyStartedCourses: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/course/started/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get course by id
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseById: (courseId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'courseId' is not null or undefined
            common_1.assertParamExists('getCourseById', 'courseId', courseId);
            const localVarPath = `/course/{courseId}/`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get course sidebar
         * @param {string} courseId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSidebar: (courseId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'courseId' is not null or undefined
            common_1.assertParamExists('getCourseSidebar', 'courseId', courseId);
            const localVarPath = `/course/{courseId}/sidebar/`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get last opened module
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastOpenedModule: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/course/lastOpenedModule/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get next course lesson
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextCourseLesson: (courseId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'courseId' is not null or undefined
            common_1.assertParamExists('getNextCourseLesson', 'courseId', courseId);
            const localVarPath = `/course/{courseId}/next/`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * CourseApi - functional programming interface
 * @export
 */
exports.CourseApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.CourseApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get all course modules
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourseModules(courseId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllCourseModules(courseId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all courses
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourses(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllCourses(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get already started courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlreadyStartedCourses(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAlreadyStartedCourses(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get course by id
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseById(courseId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCourseById(courseId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get course sidebar
         * @param {string} courseId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSidebar(courseId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCourseSidebar(courseId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get last opened module
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastOpenedModule(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLastOpenedModule(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get next course lesson
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextCourseLesson(courseId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getNextCourseLesson(courseId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * CourseApi - factory interface
 * @export
 */
exports.CourseApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.CourseApiFp(configuration);
    return {
        /**
         *
         * @summary Get all course modules
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourseModules(courseId, options) {
            return localVarFp.getAllCourseModules(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all courses
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourses(filter, options) {
            return localVarFp.getAllCourses(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get already started courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlreadyStartedCourses(options) {
            return localVarFp.getAlreadyStartedCourses(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get course by id
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseById(courseId, tenantId, options) {
            return localVarFp.getCourseById(courseId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get course sidebar
         * @param {string} courseId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSidebar(courseId, filter, options) {
            return localVarFp.getCourseSidebar(courseId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get last opened module
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastOpenedModule(options) {
            return localVarFp.getLastOpenedModule(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get next course lesson
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextCourseLesson(courseId, options) {
            return localVarFp.getNextCourseLesson(courseId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * CourseApi - object-oriented interface
 * @export
 * @class CourseApi
 * @extends {BaseAPI}
 */
class CourseApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get all course modules
     * @param {string} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    getAllCourseModules(courseId, options) {
        return exports.CourseApiFp(this.configuration).getAllCourseModules(courseId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all courses
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    getAllCourses(filter, options) {
        return exports.CourseApiFp(this.configuration).getAllCourses(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get already started courses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    getAlreadyStartedCourses(options) {
        return exports.CourseApiFp(this.configuration).getAlreadyStartedCourses(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get course by id
     * @param {string} courseId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    getCourseById(courseId, tenantId, options) {
        return exports.CourseApiFp(this.configuration).getCourseById(courseId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get course sidebar
     * @param {string} courseId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    getCourseSidebar(courseId, filter, options) {
        return exports.CourseApiFp(this.configuration).getCourseSidebar(courseId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get last opened module
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    getLastOpenedModule(options) {
        return exports.CourseApiFp(this.configuration).getLastOpenedModule(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get next course lesson
     * @param {string} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    getNextCourseLesson(courseId, options) {
        return exports.CourseApiFp(this.configuration).getNextCourseLesson(courseId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.CourseApi = CourseApi;
