import { Box, TableCell, TableCellProps } from "@mui/material";

const TouchTargetCell: React.FC<TableCellProps> = (props) => {
	return (
		<TableCell padding="checkbox" sx={{ "&.MuiTableCell-paddingCheckbox": { padding: 0 } }} {...props}>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{props.children}
			</Box>
		</TableCell>
	);
};

export default TouchTargetCell;
