"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./api/admin-api"), exports);
__exportStar(require("./api/admin-alias-api"), exports);
__exportStar(require("./api/admin-book-api"), exports);
__exportStar(require("./api/admin-course-api"), exports);
__exportStar(require("./api/admin-deepening-section-api"), exports);
__exportStar(require("./api/admin-discount-api"), exports);
__exportStar(require("./api/admin-external-content-api"), exports);
__exportStar(require("./api/admin-group-api"), exports);
__exportStar(require("./api/admin-learning-module-api"), exports);
__exportStar(require("./api/admin-lesson-api"), exports);
__exportStar(require("./api/admin-manager-api"), exports);
__exportStar(require("./api/admin-project-api"), exports);
__exportStar(require("./api/admin-software-api"), exports);
__exportStar(require("./api/admin-sprint-api"), exports);
__exportStar(require("./api/admin-sprint-content-api"), exports);
__exportStar(require("./api/admin-sprint-interaction-api"), exports);
__exportStar(require("./api/admin-sprint-question-api"), exports);
__exportStar(require("./api/admin-tag-api"), exports);
__exportStar(require("./api/admin-team-api"), exports);
__exportStar(require("./api/admin-tenant-api"), exports);
__exportStar(require("./api/authentication-api"), exports);
__exportStar(require("./api/book-api"), exports);
__exportStar(require("./api/content-interaction-api"), exports);
__exportStar(require("./api/course-api"), exports);
__exportStar(require("./api/deepening-section-api"), exports);
__exportStar(require("./api/discount-api"), exports);
__exportStar(require("./api/external-content-api"), exports);
__exportStar(require("./api/group-api"), exports);
__exportStar(require("./api/lesson-api"), exports);
__exportStar(require("./api/mentor-feedback-api"), exports);
__exportStar(require("./api/mentor-project-api"), exports);
__exportStar(require("./api/mentor-sprint-api"), exports);
__exportStar(require("./api/mentor-team-api"), exports);
__exportStar(require("./api/models-api"), exports);
__exportStar(require("./api/notification-api"), exports);
__exportStar(require("./api/project-api"), exports);
__exportStar(require("./api/recover-api"), exports);
__exportStar(require("./api/search-api"), exports);
__exportStar(require("./api/software-api"), exports);
__exportStar(require("./api/sprint-api"), exports);
__exportStar(require("./api/sprint-content-api"), exports);
__exportStar(require("./api/sprint-interactions-api"), exports);
__exportStar(require("./api/sprint-question-api"), exports);
__exportStar(require("./api/tag-api"), exports);
__exportStar(require("./api/team-api"), exports);
__exportStar(require("./api/tenant-api"), exports);
__exportStar(require("./api/user-api"), exports);
