import { alpha, createTheme, darken, LinkProps, ThemeOptions } from "@mui/material";
import { red } from "@mui/material/colors";
import type {} from "@mui/lab/themeAugmentation";

import LinkBehavior from "../LinkBehavior";
import { APP_BAR_TOOLBAR_HEIGHT } from "../scaffold/constants/ScaffoldAppBarConstants";
import IPlatformIcon from "../icons/IPlatformIcon";
import BetterTextInput from "../textInput/BetterTextInput";

const theme = createTheme({
	palette: {
		primary: {
			main: "#094074",
			light: "#275785",
			dark: "#0C3A76",
		},
		secondary: {
			main: "#FE3D3D",
			light: "#FE5555",
			dark: "#E63B3A",
		},
		error: {
			main: red.A400,
		},
		success: {
			main: "#07B338",
		},
		warning: {
			main: "#FF9400",
		},
		background: {
			default: "linear-gradient(142.74deg, #0D5BA6 0%, #094074 100%)",
			paper: "#fff",
		},
		white: {
			primary: "#ffffff",
			secondary: alpha("#ffffff", 0.74),
			disabled: alpha("#ffffff", 0.38),
		},
		magenta: {
			main: "#FF2D55",
		},
		orange: {
			main: "#FF9500",
		},
		lightblue: {
			main: "#5AC8FA",
		},
		blue: {
			main: "#007AFF",
		},
		indaco: {
			main: "#5856D6",
		},
		purple: {
			main: "#AF52DE",
		},
		pink: {
			main: "#FFA8E2",
		},
	},
	rawSpacing: (spacing: number) => {
		return spacing * 4;
	},
});

export const themeObject: ThemeOptions = {
	palette: {
		primary: {
			main: "#094074",
		},
		secondary: {
			main: "#FE3D3D",
		},
		error: {
			main: red.A400,
		},
		success: {
			main: "#07B338",
		},
		warning: {
			main: "#FF9400",
		},
		background: {
			default: "#F0F4F7",
		},
		text: {
			primary: "#000000",
			secondary: alpha("#000000", 0.6),
			disabled: alpha("#000000", 0.38),
		},
		white: {
			primary: "#ffffff",
			secondary: alpha("#ffffff", 0.74),
			disabled: alpha("#ffffff", 0.38),
		},
		magenta: {
			main: "#FF2D55",
		},
		orange: {
			main: "#FF9500",
		},
		lightblue: {
			main: "#5AC8FA",
		},
		blue: {
			main: "#007AFF",
		},
		indaco: {
			main: "#5856D6",
		},
		purple: {
			main: "#AF52DE",
		},
		pink: {
			main: "#FFA8E2",
		},
	},

	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					backgroundColor: alpha("#094074", 0.06),
					whiteSpace: "normal",
					width: "100%",
					minHeight: "100%",
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: ({ theme }) => ({
					minHeight: `${APP_BAR_TOOLBAR_HEIGHT}px !important`,
					backgroundColor: theme.palette.background.paper,
				}),
			},
		},
		MuiLink: {
			defaultProps: {
				component: LinkBehavior,
			} as LinkProps, //this is necessary because typescript does not recognise that MuiLink has a component props, which instead is a valid one
		},
		MuiButtonBase: {
			defaultProps: {
				LinkComponent: LinkBehavior,
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					marginTop: 12,
					borderRadius: 12,
					boxShadow: "none",
					"&:before": {
						display: "none",
					},
					"&:first-of-type": {
						marginTop: 0,
					},
					"&.Mui-expanded": {
						boxShadow: "none",
					},
				},
				rounded: {
					borderRadius: 12,
					"&:first-of-type": {
						borderTopLeftRadius: 12,
						borderTopRightRadius: 12,
					},
					"&:last-of-type": {
						borderBottomLeftRadius: 12,
						borderBottomRightRadius: 12,
					},
					"&.Mui-expanded": {
						"&:first-of-type": {
							marginTop: 6,
						},
						"&:last-of-type": {
							marginBottom: 6,
						},
					},
				},
			},
		},

		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					paddingLeft: 4,
					zIndex: 10,
					minHeight: 56,
					borderRadius: 12,
					boxShadow: "0px 5px 10px rgba(9, 64, 116, 0.05)",
					"&.Mui-expanded": {
						minHeight: 56,
					},
				},
				content: {
					margin: "4px 0px",
					paddingLeft: 12,
					"&.Mui-expanded": {
						margin: "4px 0px",
					},
				},
			},
		},

		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					backgroundColor: alpha("#E7ECF1", 0.3),
					position: "relative",
					borderBottomLeftRadius: 12,
					borderBottomRightRadius: 12,
				},
			},
		},

		MuiAlert: {
			styleOverrides: {
				root: {
					borderRadius: 12,
				},
			},
		},

		MuiBackdrop: {
			styleOverrides: {
				root: ({ theme }) => ({
					backgroundColor: alpha(darken(theme.palette.primary.main, 0.82), 0.24),
				}),
			},
		},

		MuiButton: {
			styleOverrides: {
				root: {
					padding: "11px 16px",
					borderRadius: 12,
				},
				sizeLarge: {
					padding: "15px 16px",
				},
				sizeSmall: {
					padding: "5px 6px",
					borderRadius: 8,
				},
			},
		},

		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: 24,
				},
			},
			defaultProps: {
				elevation: 0,
			},
		},

		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: 12,
					"&:last-child": {
						paddingBottom: 12,
					},
				},
			},
		},

		MuiChip: {
			styleOverrides: {
				sizeSmall: ({ theme }) => ({
					backgroundColor: alpha(theme.palette.primary.main, 0.74),
					borderRadius: 6,
				}),
				labelSmall: ({ theme }) => ({
					color: "#ffffff",
					...theme.typography.overline,
				}),
			},
		},

		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: 16,
				},
				paperFullScreen: {
					marginTop: 96,
					borderTopLeftRadius: 36,
					borderTopRightRadius: 36,
				},
			},
		},

		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: 0,
					paddingTop: 8,
				},
			},
		},

		MuiDrawer: {
			styleOverrides: {
				paper: ({ theme }) => ({
					width: 272,
					// zIndex: theme.zIndex.drawer + 2,
					borderRight: 0,
				}),
			},
		},

		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					fontSize: 14,
				},
			},
		},

		MuiIconButton: {
			styleOverrides: {
				root: ({ theme }) => ({
					borderRadius: 12,
					backgroundColor: alpha(theme.palette.primary.main, 0.06),
					color: theme.palette.primary.main,
					".MuiTouchRipple-child": {
						borderRadius: 12,
						color: alpha(theme.palette.primary.main, 0.6),
					},
					"&:hover": {
						backgroundColor: alpha(theme.palette.primary.main, 0.12),
					},
				}),
				sizeMedium: {
					width: 36,
					height: 36,
				},
				sizeSmall: {
					width: 30,
					height: 30,
					borderRadius: 9,
					".MuiTouchRipple-child": {
						borderRadius: 9,
					},
				},
				colorSecondary: ({ theme }) => ({
					backgroundColor: alpha(theme.palette.white.primary, 0.12),
					color: theme.palette.white.primary,
					".MuiTouchRipple-child": {
						color: alpha(theme.palette.white.primary, 0.6),
					},
					"&:hover": {
						backgroundColor: alpha(theme.palette.white.primary, 0.24),
					},
				}),
			},
		},

		MuiListItemButton: {
			styleOverrides: {
				root: ({ theme }) => ({
					marginBottom: 12,
					padding: "5px 8px",
					// paddingTop: 5,
					// paddingBottom: 5,
					borderRadius: 12,

					whiteSpace: "pre-line",
					color: alpha(darken(theme.palette.primary.main, 0.9), 0.6),
					"&:hover": {
						backgroundColor: alpha(theme.palette.primary.main, 0.12),
					},
					"& .MuiListItemText-root, & .MuiListItemIcon-root": {
						color: alpha(darken(theme.palette.primary.main, 0.9), 0.6),
					},
					"&.Mui-selected": {
						color: theme.palette.primary.main,
						"& .MuiListItemText-root, & .MuiListItemIcon-root": {
							color: "inherit",
						},
					},
				}),
			},
		},

		MuiListItemText: {
			styleOverrides: {
				root: { color: "inherit" },
				primary: {
					fontFamily: "Montserrat",
					fontSize: 14,
					fontWeight: 600,
					letterSpacing: 0.1,
				},
			},
		},

		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 36,
					color: "inherit",
				},
			},
		},

		MuiPaper: {
			styleOverrides: {
				elevation1: {
					boxShadow: "0px 5px 10px rgba(9, 64, 116, 0.05)",
				},
			},
		},

		MuiTableCell: {
			styleOverrides: {
				root: {
					// "&:first-of-type": {
					// 	paddingLeft: 0,
					// },
					// "&:last-of-type": {
					// 	paddingRight: 0,
					// },
				},
				sizeSmall: {
					padding: "6px 4px",
				},
			},
		},

		MuiTableRow: {
			styleOverrides: {
				root: {
					transition: theme.transitions.create(["background-color"]),
					td: {
						backgroundColor: theme.palette.background.paper,
					},
					"td:first-of-type": {
						borderTopLeftRadius: 12,
						borderBottomLeftRadius: 12,
					},
					"td:last-of-type": {
						borderTopRightRadius: 12,
						borderBottomRightRadius: 12,
					},
				},
			},
		},

		MuiTablePagination: {
			styleOverrides: {
				select: {
					border: "1px solid rgba(0,0,0,0.12)",
					borderRadius: 8,
					"&:focus": {
						borderRadius: 8,
					},
				},
			},
		},

		MuiMenuItem: {
			styleOverrides: {
				root: {
					transition: theme.transitions.create(["background-color"]),
					"&.Mui-selected": {
						backgroundColor: theme.palette.background.default,
						"&:hover": {
							backgroundColor: alpha(theme.palette.primary.main, 0.12),
						},
						"&.Mui-focusVisible": {
							backgroundColor: alpha(theme.palette.primary.main, 0.12),
						},
					},
					"&:hover": {
						backgroundColor: alpha(theme.palette.primary.main, 0.24),
					},
				},
			},
		},

		MuiPopover: {
			styleOverrides: {
				paper: {
					borderRadius: 12,
					boxShadow: "0px 1px 18px rgba(0, 0, 0, 0.12)",
				},
			},
		},

		MuiFilledInput: {
			styleOverrides: {
				root: {
					borderTopLeftRadius: 12,
					borderTopRightRadius: 12,
					borderBottomLeftRadius: 12,
					borderBottomRightRadius: 12,
					"::before": {
						content: "none",
					},
					"::after": {
						content: "none",
					},
				},
			},
		},

		MuiSelect: {
			styleOverrides: {
				select: {
					paddingTop: 12.5,
					paddingBottom: 12.5,
				},
			},
		},

		MuiFormLabel: {
			styleOverrides: {
				root: ({ theme }) => ({
					...theme.typography.caption,
					paddingBottom: 5,
				}),
			},
		},

		MuiDatePicker: {
			defaultProps: {
				components: {
					OpenPickerIcon: (props) => <IPlatformIcon name="calendar-sg" {...props} />,
				},
				InputAdornmentProps: {
					sx: {
						marginRight: 3,
					},
				},
				renderInput: ({ inputRef, inputProps, InputProps }) => (
					<BetterTextInput
						inputRef={inputRef}
						value={inputProps?.value}
						onChange={inputProps?.onChange}
						endAdornment={InputProps?.endAdornment}
						fullWidth
						size="medium"
						sx={{ position: "relative" }}
					/>
				),
			},
		},

		MuiTab: {
			styleOverrides: {
				root: ({ theme }) => ({
					borderTopLeftRadius: 12,
					borderTopRightRadius: 12,
					[`&.Mui-selected`]: {
						backgroundColor: alpha(theme.palette.primary.main, 0.06),
					},
				}),
			},
		},
	},

	rawSpacing: (spacing: number) => {
		return spacing * 4;
	},

	spacing: 4,

	typography: {
		fontFamily: [
			"Montserrat",
			"Open Sans",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		allVariants: {
			fontFamily: "Montserrat",
			fontWeight: 500,
			lineHeight: 1.2,
			// color: R.colors.primary,
			whiteSpace: "pre-line",
		},
		h1: {
			fontSize: 96,
			letterSpacing: -1.5,
		},
		h2: {
			fontSize: 64,
			letterSpacing: -0.5,
		},
		h3: {
			fontSize: 48,
			letterSpacing: 0,
		},
		h4: {
			fontSize: 36,
			fontWeight: 700,
			letterSpacing: 0.25,
			"@media screen and (max-width: 959px)": {
				fontSize: 20,
				letterSpacing: 0.5,
			},
		},
		h5: {
			fontSize: 24,
			letterSpacing: 0,
			"@media screen and (max-width: 959px)": {
				fontSize: 16,
				letterSpacing: 0.5,
			},
		},
		h6: {
			fontSize: 20,
			letterSpacing: 0.75,
			"@media screen and (max-width: 959px)": {
				fontSize: 16,
				letterSpacing: 0.5,
			},
		},
		subtitle1: {
			fontSize: 16,
			letterSpacing: 0.15,
			fontWeight: 600,
			"@media screen and (max-width: 959px)": {
				fontFamily: "Open Sans",
				fontSize: 14,
				fontWeight: 400,
				lineHeight: 1.6,
				letterSpacing: 0.25,
			},
		},
		subtitle2: {
			fontSize: 14,
			fontWeight: 600,
			letterSpacing: 0.1,
		},
		body1: {
			fontFamily: "Open Sans",
			fontSize: 16,
			fontWeight: 400,
			lineHeight: 1.6,
			letterSpacing: 0.5,
		},
		body2: {
			fontFamily: "Open Sans",
			fontSize: 14,
			fontWeight: 400,
			lineHeight: 1.6,
			letterSpacing: 0.25,
		},
		button: {
			fontFamily: "Open Sans",
			fontSize: 14,
			fontWeight: 600,
			lineHeight: 1.36,
			letterSpacing: 1.25,
			textTransform: "initial",
		},
		caption: {
			fontFamily: "Open Sans",
			fontSize: 12,
			fontWeight: 400,
			lineHeight: 1.33,
			letterSpacing: 0.4,
		},
		overline: {
			fontFamily: "Open Sans",
			fontSize: 10,
			fontWeight: 700,
			lineHeight: "12px",
			letterSpacing: 0.75,
		},
	},
};

declare module "@mui/material/styles" {
	interface Palette {
		white: Palette["text"];
		magenta: Palette["primary"];
		orange: Palette["primary"];
		lightblue: Palette["primary"];
		blue: Palette["primary"];
		indaco: Palette["primary"];
		purple: Palette["primary"];
		pink: Palette["primary"];
	}
	interface PaletteOptions {
		white: PaletteOptions["text"];
		magenta: PaletteOptions["primary"];
		orange: PaletteOptions["primary"];
		lightblue: PaletteOptions["primary"];
		blue: PaletteOptions["primary"];
		indaco: PaletteOptions["primary"];
		purple: PaletteOptions["primary"];
		pink: PaletteOptions["primary"];
	}

	interface ExtendedTheme {
		rawSpacing: (spacing: number) => number;
	}

	interface Theme extends ExtendedTheme {}
	interface ThemeOptions extends ExtendedTheme {}
}

export default theme;
