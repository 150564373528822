import { useEffect } from "react";
import OutletContainer from "../../../../../../components/OutletContainer";
import { useTopAction } from "../../../../../../components/scaffold/controllers/TopActionController";
import ProjectSprintsCard from "./components/ProjectSprintsCard";
import { ProjectSprintsContextProvider, useProjectSprintsController } from "./controllers/ProjectSprintsController";

type ProjectSprintsPageProps = {};

const ProjectSprintsPage: React.FC<ProjectSprintsPageProps> = () => {
	const removeTopAction = useTopAction((state) => state.removeTopAction);

	useEffect(() => {
		removeTopAction();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const projectSprintsController = useProjectSprintsController();

	return (
		<ProjectSprintsContextProvider projectSprintsController={projectSprintsController}>
			<OutletContainer fillScreen>
				<ProjectSprintsCard />
			</OutletContainer>
		</ProjectSprintsContextProvider>
	);
};

export default ProjectSprintsPage;
