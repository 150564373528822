import { TableBody } from "@mui/material";
import type { DataTableColumn } from "../interfaces/DataTable";
import DataTableRow from "./DataTableRow";

type DataTableBodyProps<T> = {
	data?: T[];
	columns?: DataTableColumn<T>[];
	collapsible?: boolean;
	collapsibleRow?: (item: T) => React.ReactNode;
};

function DataTableBody<T>(props: DataTableBodyProps<T>) {
	const { data, columns, collapsible, collapsibleRow } = props;

	return (
		<TableBody>
			{(data || []).map((item: T, index) => {
				return (
					<DataTableRow
						item={item}
						columns={columns}
						index={index}
						collapsible={collapsible}
						collapsibleRow={collapsibleRow}
						key={index}
					/>
				);
			})}
		</TableBody>
	);
}

export default DataTableBody;
