import { Box, CircularProgress, SxProps, Theme, Typography } from "@mui/material";
import React from "react";

type Props = {
	title: string;
	sx?: SxProps<Theme>;
	color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
};

const Loading = React.forwardRef<Props, any>(({ title, sx, color = "primary", ...otherProps }, ref) => {
	return (
		<Box
			ref={ref}
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				gap: 3,
				padding: 3,
				...sx,
			}}
			{...otherProps}
		>
			<CircularProgress color={color} />
			<Typography children={title} variant="subtitle1" color={color} />
		</Box>
	);
});

export default Loading;
