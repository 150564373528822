import {
	AdminSprintQuestionApi,
	PossibleAnswer,
	SprintQuestion,
	SprintQuestionQuestionTypeEnum,
} from "innovation-api-manager";
import { useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { createContext, useContextSelector } from "use-context-selector";
import { useImmer } from "use-immer";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { transformToStringFrom } from "../../../../../../../components/tables/AdminTable";
import { useApi, useFetch } from "../../../../../../../hooks/api";

type TemporarySprintQuestion = {
	question: string;
	description: string;
	questionType: SprintQuestionQuestionTypeEnum;
	possibleAnswers?: Array<PossibleAnswer>;
};

export function useSprintQuestionsController() {
	const { tenantId, projectId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const sprintId = searchParams.get("sprint");

	const adminSprintQuestionApi = useApi(AdminSprintQuestionApi);

	const {
		data: sprintQuestions,
		loading: loadingSprintQuestions,
		error: errorSprintQuestions,
		revalidate: revalidateSprintQuestions,
		mutateOptimistic: mutateSprintQuestionsOptimistic,
	} = useFetch(
		adminSprintQuestionApi.getAllAdminSprintQuestionsInSprint,
		sprintId as string,
		tenantId as string,
		transformToStringFrom({ order: "asc", orderBy: "order", perPage: 10_000 }),
		{
			skip: !sprintId || !tenantId,
		}
	);

	const [selectedSprintQuestion, setSelectedSprintQuestion] = useState<SprintQuestion>();

	const [temporarySprintQuestion, setTemporarySprintQuestion] = useImmer<TemporarySprintQuestion>({
		question: "",
		description: "",
		questionType: "text",
		possibleAnswers: [],
	});

	const addQuestion = () => {
		if (!temporarySprintQuestion?.question || temporarySprintQuestion?.question === "") {
			MLDialog.showSnackbar("Scrivi la domanda da porre all'utente", { variant: "warning" });
			return;
		}

		return adminSprintQuestionApi.createSprintQuestion(sprintId as string, tenantId as string, {
			...temporarySprintQuestion,
		});
	};

	const editQuestion = () => {
		if (!temporarySprintQuestion?.question || temporarySprintQuestion?.question === "") {
			MLDialog.showSnackbar("Scrivi la domanda da porre all'utente", { variant: "warning" });
			return;
		}

		return adminSprintQuestionApi.editSprintQuestion(selectedSprintQuestion?.id as string, tenantId as string, {
			...temporarySprintQuestion,
		});
	};

	const deleteQuestion = () => {
		return adminSprintQuestionApi.deleteSprintQuestion(selectedSprintQuestion?.id as string, tenantId);
	};

	return {
		sprintId,
		sprintQuestions,
		loadingSprintQuestions,
		errorSprintQuestions,
		revalidateSprintQuestions,
		selectedSprintQuestion,
		setSelectedSprintQuestion,
		temporarySprintQuestion,
		setTemporarySprintQuestion,
		addQuestion,
		editQuestion,
		deleteQuestion,
		mutateSprintQuestionsOptimistic,
	};
}

type SprintQuestionsContextType = ReturnType<typeof useSprintQuestionsController>;

const SprintQuestionsContext = createContext<SprintQuestionsContextType | undefined>(undefined);

export const SprintQuestionsContextProvider: React.FC<{
	sprintQuestionsController: SprintQuestionsContextType;
	children: React.ReactNode;
}> = ({ children, sprintQuestionsController }) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoChildren = useMemo(() => children, []);
	return (
		<SprintQuestionsContext.Provider value={sprintQuestionsController}>{memoChildren}</SprintQuestionsContext.Provider>
	);
};

export function useSprintQuestionsContextSelector<T extends keyof SprintQuestionsContextType>(
	field: T
): SprintQuestionsContextType[T] {
	return useContextSelector(SprintQuestionsContext, (v) => v![field]);
}

export function useSprintQuestionsContextUnoptimized() {
	const context = useContextSelector(SprintQuestionsContext, (v) => v);

	if (context === undefined) {
		throw new Error("Trying to use context outside of provider");
	}

	return context;
}
