import { Link, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { forwardRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import IPlatformIcon, { IPlatformIconNames } from "../../../icons/IPlatformIcon";

const routes: { path: string; title: string; icon: IPlatformIconNames }[] = [
	{
		path: "info",
		title: "Info generali",
		icon: "comunication_outline_02",
	},
	{
		path: "participants",
		title: "Partecipanti",
		icon: "profile_outline_01",
	},
	{
		path: "groups",
		title: "Gruppi",
		icon: "rocket-sg",
	},
	{
		path: "sprints",
		title: "Sprint del progetto",
		icon: "play-sg",
	},
	{
		path: "deliverable",
		title: "Deliverable & feedback",
		icon: "whistle-sg",
	},
];
const ScaffoldProjectRoutes = forwardRef<any, any>((props, ref) => {
	const location = useLocation();
	const { tenantId, projectId } = useParams();

	return (
		<List ref={ref} {...props} sx={{ padding: 6, position: "absolute", top: 0, width: "100%" }}>
			{routes.map((route) => {
				return (
					<Link
						key={route.path}
						href={`/tenants/${tenantId}/projects/${projectId}/${route.path}`}
						sx={{ textDecoration: "none" }}
					>
						<ListItemButton selected={location.pathname.includes(route.path)}>
							<ListItemIcon>
								<IPlatformIcon size={24} color="inherit" name={route.icon} />
							</ListItemIcon>
							<ListItemText primary={route.title} />
						</ListItemButton>
					</Link>
				);
			})}
		</List>
	);
});
export default ScaffoldProjectRoutes;
