import { AdminApi } from "innovation-api-manager";
import { useApi, useFetch } from "./api";

export function useAccount() {
	const adminApi = useApi(AdminApi);

	const controller = useFetch(adminApi.getMeAdmin);

	return controller;
}
