/** @jsxImportSource @emotion/react */

import { Box, Card, CardContent, IconButton, Stack, Typography } from "@mui/material";
import type { SprintContent } from "innovation-api-manager";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import { contentPadding } from "../../../../../../../utils/functions/contentPadding";

type SprintContentItemProps = {
	content: SprintContent;
	onEdit: (content: SprintContent) => void;
	readOnly?: boolean;
};
const SprintContentItem: React.FC<SprintContentItemProps> = ({ content, onEdit, readOnly }) => {
	return (
		<Box sx={{ pt: 2, userSelect: "none" }}>
			<Card
				sx={{
					backgroundColor: "#f1f4f7",
					borderRadius: 3,
					cursor: "grab",
					listStyle: "none",
					position: "relative",
				}}
			>
				<CardContent sx={{ ...contentPadding(2) }}>
					<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
						<Stack direction="row" alignItems="center" spacing={3}>
							<IPlatformIcon name="document_outline" />
							<Typography children={content?.title} variant="body2" />
						</Stack>
						{!readOnly && (
							<IconButton
								onClick={() => {
									onEdit(content);
								}}
							>
								<IPlatformIcon name="edit-sg" />
							</IconButton>
						)}
					</Stack>
				</CardContent>
			</Card>
		</Box>
	);
};
export default SprintContentItem;
