import {
	Alert,
	alpha,
	Button,
	Card,
	CardContent,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormLabel,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import type { SprintQuestion, SprintQuestionQuestionTypeEnum } from "innovation-api-manager";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import BetterTextInput from "../../../../../../../components/textInput/BetterTextInput";
import { contentPadding } from "../../../../../../../utils/functions/contentPadding";
import { useSprintQuestionsContextSelector } from "../controllers/SprintQuestionsController";

type CreateSprintQuestionDialogProps =
	| {
			open: boolean;
			onClose: () => void;
			mode?: "create";
			sprintQuestion?: never;
			onSave: () => void;
			onDelete?: never;
	  }
	| {
			open: boolean;
			onClose: () => void;
			mode?: "edit";
			sprintQuestion?: SprintQuestion;
			onSave: () => void;
			onDelete: () => void;
	  };

const CreateSprintQuestionDialog: React.FC<CreateSprintQuestionDialogProps> = (props) => {
	const theme = useTheme();

	const { open, onClose, mode = "create", sprintQuestion, onSave, onDelete } = props;

	const editMode = mode === "edit";

	const temporarySprintQuestion = useSprintQuestionsContextSelector("temporarySprintQuestion");
	const setTemporarySprintQuestion = useSprintQuestionsContextSelector("setTemporarySprintQuestion");

	const [noAnswerError, setNoAnswerError] = useState(false);

	const [newAnswer, setNewAnswer] = useState("");

	const addAnswer = () => {
		setNewAnswer("");

		setTemporarySprintQuestion((draft) => {
			draft.possibleAnswers?.push({
				value: uuidv4(),
				content: newAnswer,
			});
		});
	};

	useEffect(() => {
		if (open && editMode && !!sprintQuestion) {
			setTemporarySprintQuestion((draft) => {
				draft.question = sprintQuestion?.question || "";
				draft.description = sprintQuestion?.description || "";
				draft.questionType = sprintQuestion?.questionType || "text";
				draft.possibleAnswers = [...(sprintQuestion?.possibleAnswers || [])] || undefined;
			});
		} else {
			setTemporarySprintQuestion((draft) => {
				draft.question = "";
				draft.description = "";
				draft.questionType = "text";
				draft.possibleAnswers = [];
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, sprintQuestion]);

	useEffect(() => {
		if ((temporarySprintQuestion.possibleAnswers?.length ?? 0) >= 2) {
			setNoAnswerError(false);
		}
	}, [temporarySprintQuestion.possibleAnswers?.length]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle children={editMode ? "Modifica domanda" : "Crea una domanda"} sx={{ fontWeight: 600 }} />
			<DialogContent>
				<div style={{ overflow: "hidden", height: "calc(100% + 2px)", width: "calc(100% + 2px)", padding: 2, margin: -2 }}>
					<Stack>
						<Stack spacing={3}>
							<Typography children="Specifiche della domanda" variant="subtitle2" sx={{ fontWeight: 600 }} />
							<FormControl fullWidth>
								<BetterTextInput
									value={temporarySprintQuestion?.question}
									onChange={(event) => {
										setTemporarySprintQuestion((draft) => {
											draft.question = event.target.value;
										});
									}}
									placeholder={"Domanda"}
									size="medium"
									label={"Domanda"}
									fullWidth
								/>
							</FormControl>
							<FormControl fullWidth>
								<BetterTextInput
									value={temporarySprintQuestion?.description}
									onChange={(event) => {
										setTemporarySprintQuestion((draft) => {
											draft.description = event.target.value;
										});
									}}
									placeholder={"Descrizione aggiuntiva"}
									size="medium"
									label={"Descrizione aggiuntiva (opzionale)"}
									fullWidth
									multiline
									maxRows={3}
								/>
							</FormControl>
							<FormControl fullWidth>
								<FormLabel children="Tipologia di risposta" />
								<Select
									value={temporarySprintQuestion?.questionType}
									onChange={(event) => {
										setTemporarySprintQuestion((draft) => {
											draft.questionType = event.target.value as SprintQuestionQuestionTypeEnum;
										});
									}}
									variant="filled"
									size="small"
								>
									<MenuItem children="Risposta scritta" value="text" />
									<MenuItem children="Risposta singola" value="radio" />
									<MenuItem children="Risposta multipla" value="checkbox" />
								</Select>
							</FormControl>
						</Stack>
						<Collapse in={temporarySprintQuestion?.questionType !== "text"}>
							<Stack spacing={6} sx={{ marginTop: 6 }}>
								<Stack spacing={2}>
									<Typography children="Risposte a questa domanda" variant="subtitle2" sx={{ fontWeight: 600 }} />
									<Stack spacing={2}>
										{temporarySprintQuestion?.possibleAnswers?.map((answer) => {
											return (
												<Card
													sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.06), borderRadius: 3 }}
													key={answer?.value}
												>
													<CardContent sx={{ ...contentPadding(2) }}>
														<Stack direction="row" alignItems="center" spacing={3}>
															<IPlatformIcon name="document_outline" />
															<Typography children={answer?.content} variant="body2" noWrap sx={{ flexGrow: 1, minWidth: 0 }} />
															<IconButton
																onClick={() => {
																	setTemporarySprintQuestion((draft) => {
																		draft.possibleAnswers = draft.possibleAnswers?.filter((a) => a?.value !== answer?.value);
																	});
																}}
															>
																<IPlatformIcon name="delete_outline" />
															</IconButton>
														</Stack>
													</CardContent>
												</Card>
											);
										})}
									</Stack>
									<Stack direction="row" spacing={3} alignItems="flex-end">
										<FormControl sx={{ flex: 1 }}>
											<BetterTextInput
												value={newAnswer}
												onChange={(event) => {
													setNewAnswer(event.target.value);
												}}
												placeholder={"Risposta"}
												size="medium"
												label={"Crea risposta"}
												fullWidth
												onKeyDown={(event) => {
													if (event.key === "Enter") {
														addAnswer();
													}
												}}
											/>
										</FormControl>
										<Button
											children="Aggiungi"
											startIcon={<IPlatformIcon name="add" />}
											onClick={addAnswer}
											sx={{ height: 48 }}
										/>
									</Stack>
								</Stack>
							</Stack>
						</Collapse>
						<Collapse in={noAnswerError}>
							<Alert children="Inserire almeno due risposte" severity="error" sx={{ marginTop: 6 }} />
						</Collapse>
						<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3} sx={{ marginTop: 6 }}>
							{editMode && (
								<Button
									children="Elimina"
									variant="contained"
									color="error"
									startIcon={<IPlatformIcon name="delete_outline" />}
									onClick={onDelete}
								/>
							)}
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								spacing={3}
								sx={{ flex: editMode ? 0 : 1 }}
							>
								<Button children="Annulla" startIcon={<IPlatformIcon name="close" />} onClick={onClose} />
								<Button
									children={editMode ? "Salva" : "Aggiungi domanda"}
									variant="contained"
									startIcon={<IPlatformIcon name="success" />}
									onClick={() => {
										if (
											temporarySprintQuestion?.questionType !== "text" &&
											(temporarySprintQuestion?.possibleAnswers?.length ?? 0) < 2
										) {
											setNoAnswerError(true);
											return;
										}
										setNoAnswerError(false);
										onSave();
									}}
								/>
							</Stack>
						</Stack>
					</Stack>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default CreateSprintQuestionDialog;
