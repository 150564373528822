"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminGroupApi = exports.AdminGroupApiFactory = exports.AdminGroupApiFp = exports.AdminGroupApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminGroupApi - axios parameter creator
 * @export
 */
exports.AdminGroupApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add team to group
         * @param {string} teamId
         * @param {string} groupId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamToGroup: (teamId, groupId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'teamId' is not null or undefined
            common_1.assertParamExists('addTeamToGroup', 'teamId', teamId);
            // verify required parameter 'groupId' is not null or undefined
            common_1.assertParamExists('addTeamToGroup', 'groupId', groupId);
            const localVarPath = `/admin/team/{teamId}/group/{groupId}/`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create team group
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateGroup} [editCreateGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeamGroup: (projectId, tenantId, editCreateGroup, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            common_1.assertParamExists('createTeamGroup', 'projectId', projectId);
            const localVarPath = `/admin/project/{projectId}/group/`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateGroup, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"delete\", \"Group\"]
         * @summary Delete group | Permission required: [\"delete\", \"Group\"]
         * @param {string} groupId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup: (groupId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'groupId' is not null or undefined
            common_1.assertParamExists('deleteGroup', 'groupId', groupId);
            const localVarPath = `/admin/group/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Group\"]
         * @summary Edit group | Permission required: [\"update\", \"Group\"]
         * @param {string} groupId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateGroup} [editCreateGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editGroup: (groupId, tenantId, editCreateGroup, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'groupId' is not null or undefined
            common_1.assertParamExists('editGroup', 'groupId', groupId);
            const localVarPath = `/admin/group/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateGroup, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all project groups
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectGroups: (projectId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            common_1.assertParamExists('getAllProjectGroups', 'projectId', projectId);
            const localVarPath = `/admin/project/{projectId}/group/`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove team from group
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTeamFromGroup: (teamId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'teamId' is not null or undefined
            common_1.assertParamExists('removeTeamFromGroup', 'teamId', teamId);
            const localVarPath = `/admin/team/{teamId}/group/`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminGroupApi - functional programming interface
 * @export
 */
exports.AdminGroupApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminGroupApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add team to group
         * @param {string} teamId
         * @param {string} groupId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamToGroup(teamId, groupId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addTeamToGroup(teamId, groupId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create team group
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateGroup} [editCreateGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeamGroup(projectId, tenantId, editCreateGroup, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createTeamGroup(projectId, tenantId, editCreateGroup, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"delete\", \"Group\"]
         * @summary Delete group | Permission required: [\"delete\", \"Group\"]
         * @param {string} groupId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(groupId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteGroup(groupId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Group\"]
         * @summary Edit group | Permission required: [\"update\", \"Group\"]
         * @param {string} groupId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateGroup} [editCreateGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editGroup(groupId, tenantId, editCreateGroup, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editGroup(groupId, tenantId, editCreateGroup, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all project groups
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectGroups(projectId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllProjectGroups(projectId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Remove team from group
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTeamFromGroup(teamId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeTeamFromGroup(teamId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminGroupApi - factory interface
 * @export
 */
exports.AdminGroupApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminGroupApiFp(configuration);
    return {
        /**
         *
         * @summary Add team to group
         * @param {string} teamId
         * @param {string} groupId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamToGroup(teamId, groupId, tenantId, options) {
            return localVarFp.addTeamToGroup(teamId, groupId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create team group
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateGroup} [editCreateGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeamGroup(projectId, tenantId, editCreateGroup, options) {
            return localVarFp.createTeamGroup(projectId, tenantId, editCreateGroup, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"delete\", \"Group\"]
         * @summary Delete group | Permission required: [\"delete\", \"Group\"]
         * @param {string} groupId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(groupId, tenantId, options) {
            return localVarFp.deleteGroup(groupId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Group\"]
         * @summary Edit group | Permission required: [\"update\", \"Group\"]
         * @param {string} groupId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateGroup} [editCreateGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editGroup(groupId, tenantId, editCreateGroup, options) {
            return localVarFp.editGroup(groupId, tenantId, editCreateGroup, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all project groups
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectGroups(projectId, tenantId, options) {
            return localVarFp.getAllProjectGroups(projectId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove team from group
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTeamFromGroup(teamId, tenantId, options) {
            return localVarFp.removeTeamFromGroup(teamId, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminGroupApi - object-oriented interface
 * @export
 * @class AdminGroupApi
 * @extends {BaseAPI}
 */
class AdminGroupApi extends base_1.BaseAPI {
    /**
     *
     * @summary Add team to group
     * @param {string} teamId
     * @param {string} groupId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGroupApi
     */
    addTeamToGroup(teamId, groupId, tenantId, options) {
        return exports.AdminGroupApiFp(this.configuration).addTeamToGroup(teamId, groupId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create team group
     * @param {string} projectId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateGroup} [editCreateGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGroupApi
     */
    createTeamGroup(projectId, tenantId, editCreateGroup, options) {
        return exports.AdminGroupApiFp(this.configuration).createTeamGroup(projectId, tenantId, editCreateGroup, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"delete\", \"Group\"]
     * @summary Delete group | Permission required: [\"delete\", \"Group\"]
     * @param {string} groupId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGroupApi
     */
    deleteGroup(groupId, tenantId, options) {
        return exports.AdminGroupApiFp(this.configuration).deleteGroup(groupId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Group\"]
     * @summary Edit group | Permission required: [\"update\", \"Group\"]
     * @param {string} groupId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateGroup} [editCreateGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGroupApi
     */
    editGroup(groupId, tenantId, editCreateGroup, options) {
        return exports.AdminGroupApiFp(this.configuration).editGroup(groupId, tenantId, editCreateGroup, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all project groups
     * @param {string} projectId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGroupApi
     */
    getAllProjectGroups(projectId, tenantId, options) {
        return exports.AdminGroupApiFp(this.configuration).getAllProjectGroups(projectId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove team from group
     * @param {string} teamId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGroupApi
     */
    removeTeamFromGroup(teamId, tenantId, options) {
        return exports.AdminGroupApiFp(this.configuration).removeTeamFromGroup(teamId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminGroupApi = AdminGroupApi;
