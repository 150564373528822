import { Link, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { forwardRef } from "react";
import { useLocation } from "react-router-dom";
import IPlatformIcon, { IPlatformIconNames } from "../../../icons/IPlatformIcon";

const routes: { path: string; title: string; icon: IPlatformIconNames }[] = [
	{
		path: "/tenants",
		title: "Tenants",
		icon: "home-sg",
	},
	{
		path: "/users",
		title: "Utenti",
		icon: "scheda_personale-sg",
	},
	{
		path: "/settings",
		title: "Impostazioni",
		icon: "settings-sg",
	},
];
const ScaffoldHomeRoutes = forwardRef<any, any>((props, ref) => {
	const location = useLocation();

	return (
		<List
			ref={ref}
			{...props}
			className="hide-scrollbar"
			sx={{ padding: 6, position: "absolute", top: 0, width: "100%", height: "100%", overflow: "auto" }}
		>
			{routes.map((route) => {
				return (
					<Link key={route.path} href={route.path} sx={{ textDecoration: "none" }}>
						<ListItemButton selected={location.pathname.includes(route.path)}>
							<ListItemIcon>
								<IPlatformIcon size={24} color="inherit" name={route.icon} />
							</ListItemIcon>
							<ListItemText primary={route.title} />
						</ListItemButton>
					</Link>
				);
			})}
		</List>
	);
});
export default ScaffoldHomeRoutes;
