import {
	alpha,
	Avatar,
	Button,
	Card,
	CardContent,
	Chip,
	Dialog,
	DialogContent,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import type { User } from "innovation-api-manager";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import { backendMediaUrlParser } from "../../../../../../../utils/functions/backendMediaUrlParser";

type ProjectTeamMembersDialogProps = {
	open: boolean;
	onClose: () => void;
	members: User[];
	areReferents?: boolean;
};

const ProjectTeamMembersDialog: React.FC<ProjectTeamMembersDialogProps> = (props) => {
	const theme = useTheme();

	const { open, onClose, members, areReferents } = props;

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
			<DialogContent>
				<Stack spacing={6}>
					<Stack direction="row" spacing={3} alignItems="center">
						<IPlatformIcon name="scheda_personale-sg" size={32} />
						<Typography children={areReferents ? "Referenti" : "Partecipanti"} variant="h6" sx={{ fontWeight: 600 }} />
					</Stack>
					<Stack spacing={2}>
						{members.map((member) => (
							<Card sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.06) }}>
								<CardContent>
									<Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
										<Stack direction="row" spacing={2} alignItems="center">
											<Avatar src={backendMediaUrlParser(member.image)} alt={`${member.name ?? ""} ${member.surname ?? ""}`} />
											<Typography children={`${member.name ?? ""} ${member.surname ?? ""}`} />
										</Stack>
										<Chip label="Mentor" />
									</Stack>
								</CardContent>
							</Card>
						))}
					</Stack>
					<Stack direction="row" justifyContent="flex-end" alignItems="center">
						<Button children="Chiudi" onClick={onClose} />
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};

export default ProjectTeamMembersDialog;
