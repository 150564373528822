import { alpha, Avatar, Button, Card, CardContent, Stack, Typography, useTheme } from "@mui/material";
import type { MentorFeedback } from "innovation-api-manager";
import { DateTime } from "luxon";
import ContentEditor from "../../../../../../../components/ContentEditor";

type ProjectTeamCommentProps = {
	feedback: MentorFeedback;
	onFeedbackEdit?: () => void;
};

const ProjectTeamComment: React.FC<ProjectTeamCommentProps> = (props) => {
	const theme = useTheme();

	const { feedback, onFeedbackEdit } = props;

	return (
		<Card sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.06) }}>
			<CardContent>
				<Stack direction="row" spacing={3} justifyContent="flex-start">
					<Avatar />
					<Stack spacing={6} sx={{ flex: 1 }}>
						<Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center" sx={{ height: 40 }}>
							<Stack direction="row" spacing={3} alignItems="center">
								<Typography
									children={`${feedback?.admin?.name || feedback?.mentor?.name} ${
										feedback?.admin?.surname || feedback?.mentor?.surname
									}`}
									variant="subtitle1"
								/>
								<Typography children={feedback?.adminId ? "ADMIN" : "MENTOR"} color="secondary.main" variant="caption" />
							</Stack>
							{feedback?.updatedAt && (
								<Typography children={DateTime.fromISO(feedback?.updatedAt).toFormat("d MMM, HH:mm")} variant="body2" />
							)}
						</Stack>
						<ContentEditor initialValue={feedback?.content?.body || ""} readOnly />
						{Boolean(onFeedbackEdit) && (
							<Stack direction="row" spacing={3} justifyContent="flex-end" alignItems="center">
								<Button children="Modifica commento" variant="contained" onClick={onFeedbackEdit} />
							</Stack>
						)}
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default ProjectTeamComment;
