export function reorderArray<T>(
	array: T[],
	startIndex: number,
	endIndex?: number
): { reorderedArray: T[]; movedItem?: T } {
	if (endIndex !== undefined && endIndex >= 0 && endIndex < array.length) {
		const result = [...array];
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return { reorderedArray: result, movedItem: removed };
	}
	return { reorderedArray: [...array] };
}
