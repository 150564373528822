import { Popper, PopperProps, styled } from "@mui/material";

interface PopperArrowProps extends PopperProps {
	arrow: boolean;
}

const MuiArrowPopper = styled(Popper, {
	shouldForwardProp: (prop) => prop !== "arrow",
})<PopperArrowProps>(({ theme, arrow }) => ({
	zIndex: 1,
	"& > div": {
		position: "relative",
	},
	'&[data-popper-placement*="bottom"]': {
		"& > div": {
			marginTop: arrow ? 2 : 0,
		},
		"& .MuiPopper-arrow": {
			top: 0,
			left: 0,
			marginTop: "-0.9em",
			width: "3em",
			height: "1em",
			"&::before": {
				borderWidth: "0 1em 1em 1em",
				borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
			},
		},
	},
	'&[data-popper-placement*="top"]': {
		"& > div": {
			marginBottom: arrow ? 2 : 0,
		},
		"& .MuiPopper-arrow": {
			bottom: 0,
			left: 0,
			marginBottom: "-0.9em",
			width: "3em",
			height: "1em",
			"&::before": {
				borderWidth: "1em 1em 0 1em",
				borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
			},
		},
	},
	'&[data-popper-placement*="right"]': {
		"& > div": {
			marginLeft: arrow ? 2 : 0,
		},
		"& .MuiPopper-arrow": {
			left: 0,
			marginLeft: "-0.9em",
			height: "3em",
			width: "1em",
			"&::before": {
				borderWidth: "1em 1em 1em 0",
				borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
			},
		},
	},
	'&[data-popper-placement*="left"]': {
		"& > div": {
			marginRight: arrow ? 2 : 0,
		},
		"& .MuiPopper-arrow": {
			right: 0,
			marginRight: "-0.9em",
			height: "3em",
			width: "1em",
			"&::before": {
				borderWidth: "1em 0 1em 1em",
				borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
			},
		},
	},
}));

export const PopperArrow = styled("div")({
	position: "absolute",
	fontSize: 7,
	width: "3em",
	height: "3em",
	"&::before": {
		content: '""',
		margin: "auto",
		display: "block",
		width: 0,
		height: 0,
		borderStyle: "solid",
	},
});

export default MuiArrowPopper;
