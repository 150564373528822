import OutletContainer from "../../../../../../components/OutletContainer";
import ProjectTeamContainer from "./components/ProjectTeamContainer";
import { ProjectTeamContextProvider, useProjectTeamController } from "./controllers/ProjectTeamController";

type ProjectCandidacyPageProps = {
	children?: React.ReactNode;
};

const ProjectTeamPage: React.FC<ProjectCandidacyPageProps> = (props) => {
	const projectTeamController = useProjectTeamController();

	return (
		<ProjectTeamContextProvider projectTeamController={projectTeamController}>
			<OutletContainer fillScreen>
				<ProjectTeamContainer />
			</OutletContainer>
		</ProjectTeamContextProvider>
	);
};

export default ProjectTeamPage;
