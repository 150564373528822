"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminDeepeningSectionApi = exports.AdminDeepeningSectionApiFactory = exports.AdminDeepeningSectionApiFp = exports.AdminDeepeningSectionApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminDeepeningSectionApi - axios parameter creator
 * @export
 */
exports.AdminDeepeningSectionApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"create\", \"DeepeningSection\"]
         * @summary Add deepening section | Permission required: [\"create\", \"DeepeningSection\"]
         * @param {string} [tenantId] tenantId
         * @param {EditCreateDeepeningSection} [editCreateDeepeningSection]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeepeningSection: (tenantId, editCreateDeepeningSection, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/deepeningSection/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateDeepeningSection, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"DeepeningSection\"]
         * @summary Add lesson to deepening section | Permission required: [\"update\", \"DeepeningSection\"]
         * @param {string} deepeningSectionId
         * @param {string} [tenantId] tenantId
         * @param {InputAddLessonToDeepeningSection} [inputAddLessonToDeepeningSection]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLessonToDeepeningSection: (deepeningSectionId, tenantId, inputAddLessonToDeepeningSection, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deepeningSectionId' is not null or undefined
            common_1.assertParamExists('addLessonToDeepeningSection', 'deepeningSectionId', deepeningSectionId);
            const localVarPath = `/admin/deepeningSection/{deepeningSectionId}/lesson/`
                .replace(`{${"deepeningSectionId"}}`, encodeURIComponent(String(deepeningSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputAddLessonToDeepeningSection, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"delete\", \"DeepeningSection\"]
         * @summary Delete deepening section | Permission required: [\"delete\", \"DeepeningSection\"]
         * @param {string} deepeningSectionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeepeningSection: (deepeningSectionId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deepeningSectionId' is not null or undefined
            common_1.assertParamExists('deleteDeepeningSection', 'deepeningSectionId', deepeningSectionId);
            const localVarPath = `/admin/deepeningSection/{deepeningSectionId}/`
                .replace(`{${"deepeningSectionId"}}`, encodeURIComponent(String(deepeningSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"DeepeningSection\"]
         * @summary Edit deepening section | Permission required: [\"update\", \"DeepeningSection\"]
         * @param {string} deepeningSectionId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateDeepeningSection} [editCreateDeepeningSection]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDeepeningSection: (deepeningSectionId, tenantId, editCreateDeepeningSection, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deepeningSectionId' is not null or undefined
            common_1.assertParamExists('editDeepeningSection', 'deepeningSectionId', deepeningSectionId);
            const localVarPath = `/admin/deepeningSection/{deepeningSectionId}/`
                .replace(`{${"deepeningSectionId"}}`, encodeURIComponent(String(deepeningSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateDeepeningSection, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"DeepeningSection\"]
         * @summary Get admin deepening sections | Permission required: [\"readAll\", \"DeepeningSection\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminDeepeningSections: (tenantId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/deepeningSection/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update deepening section image
         * @param {string} deepeningSectionId
         * @param {string} [tenantId] tenantId
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeepeningSectionImage: (deepeningSectionId, tenantId, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deepeningSectionId' is not null or undefined
            common_1.assertParamExists('updateDeepeningSectionImage', 'deepeningSectionId', deepeningSectionId);
            const localVarPath = `/admin/deepeningSection/{deepeningSectionId}/image/`
                .replace(`{${"deepeningSectionId"}}`, encodeURIComponent(String(deepeningSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminDeepeningSectionApi - functional programming interface
 * @export
 */
exports.AdminDeepeningSectionApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminDeepeningSectionApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"DeepeningSection\"]
         * @summary Add deepening section | Permission required: [\"create\", \"DeepeningSection\"]
         * @param {string} [tenantId] tenantId
         * @param {EditCreateDeepeningSection} [editCreateDeepeningSection]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeepeningSection(tenantId, editCreateDeepeningSection, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addDeepeningSection(tenantId, editCreateDeepeningSection, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"DeepeningSection\"]
         * @summary Add lesson to deepening section | Permission required: [\"update\", \"DeepeningSection\"]
         * @param {string} deepeningSectionId
         * @param {string} [tenantId] tenantId
         * @param {InputAddLessonToDeepeningSection} [inputAddLessonToDeepeningSection]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLessonToDeepeningSection(deepeningSectionId, tenantId, inputAddLessonToDeepeningSection, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addLessonToDeepeningSection(deepeningSectionId, tenantId, inputAddLessonToDeepeningSection, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"delete\", \"DeepeningSection\"]
         * @summary Delete deepening section | Permission required: [\"delete\", \"DeepeningSection\"]
         * @param {string} deepeningSectionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeepeningSection(deepeningSectionId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteDeepeningSection(deepeningSectionId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"DeepeningSection\"]
         * @summary Edit deepening section | Permission required: [\"update\", \"DeepeningSection\"]
         * @param {string} deepeningSectionId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateDeepeningSection} [editCreateDeepeningSection]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDeepeningSection(deepeningSectionId, tenantId, editCreateDeepeningSection, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editDeepeningSection(deepeningSectionId, tenantId, editCreateDeepeningSection, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"DeepeningSection\"]
         * @summary Get admin deepening sections | Permission required: [\"readAll\", \"DeepeningSection\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminDeepeningSections(tenantId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminDeepeningSections(tenantId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Update deepening section image
         * @param {string} deepeningSectionId
         * @param {string} [tenantId] tenantId
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeepeningSectionImage(deepeningSectionId, tenantId, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateDeepeningSectionImage(deepeningSectionId, tenantId, file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminDeepeningSectionApi - factory interface
 * @export
 */
exports.AdminDeepeningSectionApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminDeepeningSectionApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"DeepeningSection\"]
         * @summary Add deepening section | Permission required: [\"create\", \"DeepeningSection\"]
         * @param {string} [tenantId] tenantId
         * @param {EditCreateDeepeningSection} [editCreateDeepeningSection]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeepeningSection(tenantId, editCreateDeepeningSection, options) {
            return localVarFp.addDeepeningSection(tenantId, editCreateDeepeningSection, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"DeepeningSection\"]
         * @summary Add lesson to deepening section | Permission required: [\"update\", \"DeepeningSection\"]
         * @param {string} deepeningSectionId
         * @param {string} [tenantId] tenantId
         * @param {InputAddLessonToDeepeningSection} [inputAddLessonToDeepeningSection]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLessonToDeepeningSection(deepeningSectionId, tenantId, inputAddLessonToDeepeningSection, options) {
            return localVarFp.addLessonToDeepeningSection(deepeningSectionId, tenantId, inputAddLessonToDeepeningSection, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"delete\", \"DeepeningSection\"]
         * @summary Delete deepening section | Permission required: [\"delete\", \"DeepeningSection\"]
         * @param {string} deepeningSectionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeepeningSection(deepeningSectionId, tenantId, options) {
            return localVarFp.deleteDeepeningSection(deepeningSectionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"DeepeningSection\"]
         * @summary Edit deepening section | Permission required: [\"update\", \"DeepeningSection\"]
         * @param {string} deepeningSectionId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateDeepeningSection} [editCreateDeepeningSection]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDeepeningSection(deepeningSectionId, tenantId, editCreateDeepeningSection, options) {
            return localVarFp.editDeepeningSection(deepeningSectionId, tenantId, editCreateDeepeningSection, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"DeepeningSection\"]
         * @summary Get admin deepening sections | Permission required: [\"readAll\", \"DeepeningSection\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminDeepeningSections(tenantId, filter, options) {
            return localVarFp.getAdminDeepeningSections(tenantId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update deepening section image
         * @param {string} deepeningSectionId
         * @param {string} [tenantId] tenantId
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeepeningSectionImage(deepeningSectionId, tenantId, file, options) {
            return localVarFp.updateDeepeningSectionImage(deepeningSectionId, tenantId, file, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminDeepeningSectionApi - object-oriented interface
 * @export
 * @class AdminDeepeningSectionApi
 * @extends {BaseAPI}
 */
class AdminDeepeningSectionApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"create\", \"DeepeningSection\"]
     * @summary Add deepening section | Permission required: [\"create\", \"DeepeningSection\"]
     * @param {string} [tenantId] tenantId
     * @param {EditCreateDeepeningSection} [editCreateDeepeningSection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDeepeningSectionApi
     */
    addDeepeningSection(tenantId, editCreateDeepeningSection, options) {
        return exports.AdminDeepeningSectionApiFp(this.configuration).addDeepeningSection(tenantId, editCreateDeepeningSection, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"DeepeningSection\"]
     * @summary Add lesson to deepening section | Permission required: [\"update\", \"DeepeningSection\"]
     * @param {string} deepeningSectionId
     * @param {string} [tenantId] tenantId
     * @param {InputAddLessonToDeepeningSection} [inputAddLessonToDeepeningSection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDeepeningSectionApi
     */
    addLessonToDeepeningSection(deepeningSectionId, tenantId, inputAddLessonToDeepeningSection, options) {
        return exports.AdminDeepeningSectionApiFp(this.configuration).addLessonToDeepeningSection(deepeningSectionId, tenantId, inputAddLessonToDeepeningSection, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"delete\", \"DeepeningSection\"]
     * @summary Delete deepening section | Permission required: [\"delete\", \"DeepeningSection\"]
     * @param {string} deepeningSectionId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDeepeningSectionApi
     */
    deleteDeepeningSection(deepeningSectionId, tenantId, options) {
        return exports.AdminDeepeningSectionApiFp(this.configuration).deleteDeepeningSection(deepeningSectionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"DeepeningSection\"]
     * @summary Edit deepening section | Permission required: [\"update\", \"DeepeningSection\"]
     * @param {string} deepeningSectionId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateDeepeningSection} [editCreateDeepeningSection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDeepeningSectionApi
     */
    editDeepeningSection(deepeningSectionId, tenantId, editCreateDeepeningSection, options) {
        return exports.AdminDeepeningSectionApiFp(this.configuration).editDeepeningSection(deepeningSectionId, tenantId, editCreateDeepeningSection, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"DeepeningSection\"]
     * @summary Get admin deepening sections | Permission required: [\"readAll\", \"DeepeningSection\"]
     * @param {string} [tenantId] tenantId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDeepeningSectionApi
     */
    getAdminDeepeningSections(tenantId, filter, options) {
        return exports.AdminDeepeningSectionApiFp(this.configuration).getAdminDeepeningSections(tenantId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update deepening section image
     * @param {string} deepeningSectionId
     * @param {string} [tenantId] tenantId
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDeepeningSectionApi
     */
    updateDeepeningSectionImage(deepeningSectionId, tenantId, file, options) {
        return exports.AdminDeepeningSectionApiFp(this.configuration).updateDeepeningSectionImage(deepeningSectionId, tenantId, file, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminDeepeningSectionApi = AdminDeepeningSectionApi;
