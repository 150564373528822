import {
	alpha,
	Box,
	Link,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Slide,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { forwardRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNotifierValue } from "react-use-notifier";
import IPlatformIcon, { IPlatformIconNames } from "../../../icons/IPlatformIcon";
import { useScaffoldContextUnoptimized } from "../../controllers/ScaffoldController";
import ScaffoldProjectRoutes from "./ScaffoldProjectRoutes";

const mainRoutes: { path: string; title: string; icon: IPlatformIconNames }[] = [
	{
		path: "assign_tm",
		title: "Assegnazioni TM",
		icon: "whistle-sg",
	},
	{
		path: "info",
		title: "Info generali",
		icon: "comunication_outline_02",
	},
	{
		path: "invited_users",
		title: "Utenti invitati",
		icon: "scheda_personale-sg",
	},
	{
		path: "mentors",
		title: "Mentor e PM",
		icon: "medal-sg",
	},
	{
		path: "customize",
		title: "Personalizza",
		icon: "edit-sg",
	},
	{
		path: "mail_settings",
		title: "Impostazioni mail",
		icon: "mail-sg",
	},
	{
		path: "tags",
		title: "Tags",
		icon: "hive-sg",
	},
	{
		path: "visualized_avatar",
		title: "Avatar visualizzato",
		icon: "smile-sg",
	},
];

const serviceRoutes: { path: string; title: string; icon: IPlatformIconNames }[] = [
	{
		path: "projects",
		title: "Progetti attivi",
		icon: "settings-sg",
	},
	{
		path: "suggested_contents",
		title: "Contenuti consigliati",
		icon: "libri_consigliati-sg",
	},
	{
		path: "training",
		title: "Formazione",
		icon: "book-sg",
	},
	{
		path: "courses",
		title: "Percorsi formativi",
		icon: "corsi-sg",
	},
];

const BACK_HEIGHT = 40;

const TenantRoutes = forwardRef<any, any>((props, ref) => {
	const location = useLocation();
	const { tenantId } = useParams();
	return (
		<List ref={ref} {...props} sx={{ padding: 6, position: "absolute", top: 0, width: "100%" }}>
			{mainRoutes.map((route) => {
				return (
					<Link key={route.path} href={`/tenants/${tenantId}/${route.path}`} sx={{ textDecoration: "none" }}>
						<ListItemButton selected={location.pathname.includes(route.path)}>
							<ListItemIcon>
								<IPlatformIcon size={24} color="inherit" name={route.icon} />
							</ListItemIcon>
							<ListItemText primary={route.title} />
						</ListItemButton>
					</Link>
				);
			})}
			<Stack direction={"row"} alignItems="center" sx={{ marginBottom: 3 }}>
				<Typography children="Servizi collegati" variant="overline" sx={{ opacity: 0.38 }} />
				<Box sx={{ height: "1px", flexGrow: 1, backgroundColor: alpha("#000000", 0.12), marginLeft: 3 }} />
			</Stack>
			{serviceRoutes.map((route) => {
				return (
					<Link key={route.path} href={`/tenants/${tenantId}/${route.path}`} sx={{ textDecoration: "none" }}>
						<ListItemButton selected={location.pathname.includes(route.path)}>
							<ListItemIcon>
								<IPlatformIcon size={24} color="inherit" name={route.icon} />
							</ListItemIcon>
							<ListItemText primary={route.title} />
						</ListItemButton>
					</Link>
				);
			})}
		</List>
	);
});

const ScaffoldTenantsRoutes = forwardRef<any, { slideContainer: HTMLDivElement | null }>(
	({ slideContainer, ...props }, ref) => {
		const { backTitle: backTitleNotifier, backHref: backHrefNotifier } = useScaffoldContextUnoptimized();
		const backTitle = useNotifierValue(backTitleNotifier);
		const backHref = useNotifierValue(backHrefNotifier);
		const theme = useTheme();

		const { projectId } = useParams();

		return (
			<Box ref={ref} {...props} sx={{ position: "absolute", top: 0, width: "100%", height: "100%" }}>
				<Stack
					direction="row"
					alignItems={"center"}
					sx={{
						backgroundColor: "background.default",
						height: BACK_HEIGHT,
						px: 6,
					}}
				>
					<Link href={backHref} sx={{ textDecoration: "none", userSelect: "none" }}>
						<Stack
							direction="row"
							alignItems={"center"}
							sx={{
								width: "auto",
								"&:hover": {
									"& i": {
										transform: "translateX(-4px)",
									},
								},
							}}
						>
							<IPlatformIcon
								size={24}
								color="primary"
								name={"arrow_direction_outline_left"}
								iconCss={{ transition: theme.transitions.create(["transform"]) }}
							/>
							<Typography children={backTitle} variant="subtitle2" color="primary" sx={{ marginLeft: 2 }} />
						</Stack>
					</Link>
				</Stack>

				<Box
					className="hide-scrollbar"
					sx={{ position: "relative", height: `calc(100% - ${BACK_HEIGHT}px)`, overflow: "auto" }}
				>
					<Slide
						direction="right"
						in={projectId === undefined}
						appear={false}
						mountOnEnter
						unmountOnExit
						container={slideContainer}
					>
						<TenantRoutes />
					</Slide>
					<Slide
						direction="left"
						in={projectId !== undefined}
						appear={false}
						mountOnEnter
						unmountOnExit
						container={slideContainer}
					>
						<ScaffoldProjectRoutes />
					</Slide>
				</Box>
			</Box>
		);
	}
);
export default ScaffoldTenantsRoutes;
