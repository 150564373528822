/** @jsxImportSource @emotion/react */

import { Button, Card, CardContent, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import EllipseWrapper from "../../../../../../../components/EllipseWrapper";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import Loading from "../../../../../../../components/Loading";
import { contentPadding } from "../../../../../../../utils/functions/contentPadding";
import { useProjectTeamContextSelector } from "../controllers/ProjectTeamController";
import ProjectTeamCommentSection from "./ProjectTeamCommentSection";
import ProjectTeamDeliverablesInteractions from "./ProjectTeamDeliverablesInteractions";
import ProjectTeamFeedbackCard from "./ProjectTeamFeedbackCard";
import ProjectTeamHead from "./ProjectTeamHead";
import ProjectTeamSprintSelector from "./ProjectTeamSprintSelector";

type ProjectTeamContainerProps = {};

const ProjectTeamContainer: React.FC<ProjectTeamContainerProps> = (props) => {
	const sprints = useProjectTeamContextSelector("sprints");
	const loadingSprints = useProjectTeamContextSelector("loadingSprints");
	const selectedSprint = useProjectTeamContextSelector("selectedSprint");

	const readOnly = useProjectTeamContextSelector("readOnly");

	return (
		<div css={{ flex: 1, position: "relative", marginTop: -36, marginLeft: -36, marginRight: -36 }}>
			<EllipseWrapper additionalSpace={{ top: 80, bottom: 220 }}>
				<Container>
					<ProjectTeamHead />
				</Container>
			</EllipseWrapper>
			<Container>
				<Stack
					spacing={6}
					css={{
						marginTop: 48,
						zIndex: 500,
						position: "relative",
					}}
				>
					<Card>
						<CardContent sx={{ ...contentPadding(6) }}>
							<Stack spacing={6}>
								<Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
									<Stack direction="row" spacing={3} alignItems="center">
										<IPlatformIcon name="book-sg" size={32} />
										<Typography children="I deliverable degli sprint" variant="h6" sx={{ fontWeight: 600 }} />
									</Stack>
									<Link to="../sprints" css={{ textDecoration: "none" }}>
										<Button children="Vedi contenuti sprint" endIcon={<IPlatformIcon name="arrow_mini_outline_right" />} />
									</Link>
								</Stack>
								{Boolean(sprints) && Boolean(sprints?.length) && Boolean(selectedSprint) && (
									<React.Fragment>
										<ProjectTeamSprintSelector />
										<ProjectTeamDeliverablesInteractions />
									</React.Fragment>
								)}
								{loadingSprints && <Loading />}
							</Stack>
						</CardContent>
					</Card>
					<ProjectTeamFeedbackCard />
					{!readOnly && <ProjectTeamCommentSection />}
				</Stack>
			</Container>
		</div>
	);
};

export default ProjectTeamContainer;
