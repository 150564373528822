import { FormControlLabel } from "@mui/material";
import { AdminProjectApi } from "innovation-api-manager";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import IOSSwitch from "../../../../../../../components/IOSSwitch";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { apiErrorParser, useApi } from "../../../../../../../hooks/api";
import { useProjectSprintsContextSelector } from "../controllers/ProjectSprintsController";

const SwitchProjectSprintsOpening = () => {
	const { tenantId, projectId } = useParams();
	const adminProjectApi = useApi(AdminProjectApi);

	const {
		data: project,
		loading: projectLoading,
		error: projectError,
		mutateOptimistic: mutateProjectOptimistic,
		revalidate: revalidateProject,
	} = useProjectSprintsContextSelector("projectFetch");

	useEffect(() => {
		if (projectError) {
			MLDialog.showSnackbar(apiErrorParser(projectError), { variant: "error" });
		}
	}, [projectError]);

	const [toggleLoading, setToggleLoading] = useState(false);

	const toggleAllSprintOpen = useCallback(() => {
		if (projectId && tenantId) {
			const shouldUnlock = !project?.allUnlocked;
			setToggleLoading(true);
			mutateProjectOptimistic({ ...project, allUnlocked: shouldUnlock }, { shouldRevalidate: false });
			adminProjectApi
				.editProject(projectId, tenantId, {
					allUnlocked: shouldUnlock,
				})
				.then(() => {
					revalidateProject();
				})
				.catch((err) => {
					MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
				})
				.finally(() => {
					setToggleLoading(false);
				});
		}
	}, [adminProjectApi, mutateProjectOptimistic, project, projectId, revalidateProject, tenantId]);

	return (
		<FormControlLabel
			label={"Gli sprint di questo progetto hanno delle date di apertura e di chiusura"}
			control={
				<IOSSwitch
					disabled={projectLoading || toggleLoading || (project?.sprintIds?.length ?? 0) > 0}
					checked={!project?.allUnlocked}
					onChange={toggleAllSprintOpen}
				/>
			}
			labelPlacement="end"
			componentsProps={{
				typography: {
					variant: "body2",
					sx: { paddingLeft: 3, userSelect: "none" },
				},
			}}
		/>
	);
};
export default SwitchProjectSprintsOpening;
