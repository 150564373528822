import { css } from "@emotion/css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import { MLDialogProvider } from "./components/poppers/MLDialog";
import { ThemeProvider } from "./components/theme/ThemeProvider";
import RoutesList from "./routes/RoutesList";

const queryClient = new QueryClient();

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider>
				<MLDialogProvider
					maxSnack={100}
					classes={{
						variantSuccess: css`
							background-color: rgba(52, 199, 89, 1) !important;
							padding: 4px 8px !important;
							border-radius: 8px !important;
							box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.24);
							& .SnackbarItem-action {
								margin-right: unset !important;
							}
						`,
						variantError: css`
							background-color: rgba(240, 55, 45, 1) !important;
							padding: 4px 8px !important;
							border-radius: 8px !important;
							box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.24);
							& .SnackbarItem-action {
								margin-right: unset !important;
							}
						`,
						variantInfo: css`
							background-color: rgba(67, 169, 191, 1) !important;
							padding: 4px 8px !important;
							border-radius: 8px !important;
							box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.24);
							& .SnackbarItem-action {
								margin-right: unset !important;
							}
						`,
						variantWarning: css`
							background-color: rgba(255, 149, 0, 1) !important;
							padding: 4px 8px !important;
							border-radius: 8px !important;
							box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.24);
							& .SnackbarItem-action {
								margin-right: unset !important;
							}
						`,
					}}
					iconVariant={
						{
							// success: <CertiblokIcon size={24} color="white" iconCss={{ marginRight: 4 }} name={"success"} />,
							// error: <CertiblokIcon size={24} color="white" iconCss={{ marginRight: 4 }} name={"comunication_outline_03"} />,
							// warning: (
							// 	<CertiblokIcon size={24} color="white" iconCss={{ marginRight: 4 }} name={"comunication_outline_01"} />
							// ),
							// info: <CertiblokIcon size={24} color="white" iconCss={{ marginRight: 4 }} name={"comunication_outline_02"} />,
						}
					}
				>
					<LocalizationProvider dateAdapter={AdapterLuxon}>
						<RoutesList />
					</LocalizationProvider>
				</MLDialogProvider>
			</ThemeProvider>
		</QueryClientProvider>
	);
}

export default App;
