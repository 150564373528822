import { Box, SxProps, Theme } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import { useCallback, useLayoutEffect, useRef, useState } from "react";

type Props = {
	sx?: SxProps<Theme>;
	children: React.ReactNode;
	disableFixedHeight?: boolean;
	debugName?: string;
	className?: string;
};

const FillScreenScrollView: React.FC<Props> = ({ children, sx, disableFixedHeight, debugName, className }) => {
	const ref = useRef<HTMLDivElement>();

	const [height, setHeight] = useState((ref.current?.offsetParent?.clientHeight ?? 0) - (ref.current?.offsetTop ?? 0));

	const computeHeight = useCallback(() => {
		if (ref.current?.offsetParent) {
			const paddingBottomValue =
				parseFloat(window.getComputedStyle(ref.current.offsetParent, null).getPropertyValue("padding-bottom")) ?? 0;
			return (ref.current?.offsetParent?.clientHeight ?? 0) - (ref.current?.offsetTop ?? 0) - paddingBottomValue;
		}
		return 0;
	}, []);

	useLayoutEffect(() => {
		const newHeight = computeHeight();
		setHeight(newHeight);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useResizeObserver(document.body, () => {
		const newHeight = computeHeight();
		if (height !== newHeight) setHeight(newHeight);
	});

	return (
		<Box
			ref={ref}
			className={className}
			sx={{
				width: "100%",
				height: disableFixedHeight ? "auto" : height,
				overflowY: "auto",
				position: "relative",
				...sx,
			}}
		>
			{children}
		</Box>
	);
};

export default FillScreenScrollView;
