import OutletContainer from "../../../../../../components/OutletContainer";
import ProjectInfoCard from "./components/ProjectInfoCard";
import { ProjectInfoContextProvider, useProjectInfoController } from "./controllers/ProjectInfoController";

type ProjectPageProps = {};

const ProjectInfoPage: React.FC<ProjectPageProps> = () => {
	const projectInfoController = useProjectInfoController();

	return (
		<ProjectInfoContextProvider ProjectInfoController={projectInfoController}>
			<OutletContainer fillScreen>
				<ProjectInfoCard />
			</OutletContainer>
		</ProjectInfoContextProvider>
	);
};

export default ProjectInfoPage;
