"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminBookApi = exports.AdminBookApiFactory = exports.AdminBookApiFp = exports.AdminBookApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminBookApi - axios parameter creator
 * @export
 */
exports.AdminBookApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"create\", \"Book\"]
         * @summary Clone books | Permission required: [\"create\", \"Book\"]
         * @param {string} [tenantId] tenantId
         * @param {ContentCloneData} [contentCloneData]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneBooks: (tenantId, contentCloneData, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/book/clone/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(contentCloneData, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create book
         * @param {string} [tenantId] tenantId
         * @param {EditCreateBook} [editCreateBook]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBook: (tenantId, editCreateBook, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/book/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateBook, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete book
         * @param {string} bookId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBook: (bookId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'bookId' is not null or undefined
            common_1.assertParamExists('deleteBook', 'bookId', bookId);
            const localVarPath = `/admin/book/{bookId}/`
                .replace(`{${"bookId"}}`, encodeURIComponent(String(bookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit book
         * @param {string} bookId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateBook} [editCreateBook]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBook: (bookId, tenantId, editCreateBook, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'bookId' is not null or undefined
            common_1.assertParamExists('editBook', 'bookId', bookId);
            const localVarPath = `/admin/book/{bookId}/`
                .replace(`{${"bookId"}}`, encodeURIComponent(String(bookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateBook, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Book\"]
         * @summary Edit book image | Permission required: [\"update\", \"Book\"]
         * @param {string} bookId
         * @param {any} file
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBookImage: (bookId, file, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'bookId' is not null or undefined
            common_1.assertParamExists('editBookImage', 'bookId', bookId);
            // verify required parameter 'file' is not null or undefined
            common_1.assertParamExists('editBookImage', 'file', file);
            const localVarPath = `/admin/book/{bookId}/image/`
                .replace(`{${"bookId"}}`, encodeURIComponent(String(bookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Book\"]
         * @summary Get admin books | Permission required: [\"readAll\", \"Book\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBooks: (tenantId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/book/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminBookApi - functional programming interface
 * @export
 */
exports.AdminBookApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminBookApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"Book\"]
         * @summary Clone books | Permission required: [\"create\", \"Book\"]
         * @param {string} [tenantId] tenantId
         * @param {ContentCloneData} [contentCloneData]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneBooks(tenantId, contentCloneData, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.cloneBooks(tenantId, contentCloneData, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create book
         * @param {string} [tenantId] tenantId
         * @param {EditCreateBook} [editCreateBook]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBook(tenantId, editCreateBook, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createBook(tenantId, editCreateBook, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete book
         * @param {string} bookId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBook(bookId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteBook(bookId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit book
         * @param {string} bookId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateBook} [editCreateBook]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBook(bookId, tenantId, editCreateBook, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editBook(bookId, tenantId, editCreateBook, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Book\"]
         * @summary Edit book image | Permission required: [\"update\", \"Book\"]
         * @param {string} bookId
         * @param {any} file
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBookImage(bookId, file, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editBookImage(bookId, file, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Book\"]
         * @summary Get admin books | Permission required: [\"readAll\", \"Book\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBooks(tenantId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminBooks(tenantId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminBookApi - factory interface
 * @export
 */
exports.AdminBookApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminBookApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"Book\"]
         * @summary Clone books | Permission required: [\"create\", \"Book\"]
         * @param {string} [tenantId] tenantId
         * @param {ContentCloneData} [contentCloneData]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneBooks(tenantId, contentCloneData, options) {
            return localVarFp.cloneBooks(tenantId, contentCloneData, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create book
         * @param {string} [tenantId] tenantId
         * @param {EditCreateBook} [editCreateBook]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBook(tenantId, editCreateBook, options) {
            return localVarFp.createBook(tenantId, editCreateBook, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete book
         * @param {string} bookId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBook(bookId, tenantId, options) {
            return localVarFp.deleteBook(bookId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit book
         * @param {string} bookId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateBook} [editCreateBook]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBook(bookId, tenantId, editCreateBook, options) {
            return localVarFp.editBook(bookId, tenantId, editCreateBook, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Book\"]
         * @summary Edit book image | Permission required: [\"update\", \"Book\"]
         * @param {string} bookId
         * @param {any} file
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBookImage(bookId, file, tenantId, options) {
            return localVarFp.editBookImage(bookId, file, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Book\"]
         * @summary Get admin books | Permission required: [\"readAll\", \"Book\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBooks(tenantId, filter, options) {
            return localVarFp.getAdminBooks(tenantId, filter, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminBookApi - object-oriented interface
 * @export
 * @class AdminBookApi
 * @extends {BaseAPI}
 */
class AdminBookApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"create\", \"Book\"]
     * @summary Clone books | Permission required: [\"create\", \"Book\"]
     * @param {string} [tenantId] tenantId
     * @param {ContentCloneData} [contentCloneData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBookApi
     */
    cloneBooks(tenantId, contentCloneData, options) {
        return exports.AdminBookApiFp(this.configuration).cloneBooks(tenantId, contentCloneData, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create book
     * @param {string} [tenantId] tenantId
     * @param {EditCreateBook} [editCreateBook]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBookApi
     */
    createBook(tenantId, editCreateBook, options) {
        return exports.AdminBookApiFp(this.configuration).createBook(tenantId, editCreateBook, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete book
     * @param {string} bookId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBookApi
     */
    deleteBook(bookId, tenantId, options) {
        return exports.AdminBookApiFp(this.configuration).deleteBook(bookId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit book
     * @param {string} bookId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateBook} [editCreateBook]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBookApi
     */
    editBook(bookId, tenantId, editCreateBook, options) {
        return exports.AdminBookApiFp(this.configuration).editBook(bookId, tenantId, editCreateBook, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Book\"]
     * @summary Edit book image | Permission required: [\"update\", \"Book\"]
     * @param {string} bookId
     * @param {any} file
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBookApi
     */
    editBookImage(bookId, file, tenantId, options) {
        return exports.AdminBookApiFp(this.configuration).editBookImage(bookId, file, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Book\"]
     * @summary Get admin books | Permission required: [\"readAll\", \"Book\"]
     * @param {string} [tenantId] tenantId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBookApi
     */
    getAdminBooks(tenantId, filter, options) {
        return exports.AdminBookApiFp(this.configuration).getAdminBooks(tenantId, filter, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminBookApi = AdminBookApi;
