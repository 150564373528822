/** @jsxImportSource @emotion/react */
import { Button, Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import { AdminApi } from "innovation-api-manager";
import React, { useEffect, useRef, useState } from "react";
import ContentEditor, { ContentEditorRef } from "../../../../../../../components/ContentEditor";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import Loading from "../../../../../../../components/Loading";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { useApi, useFetch } from "../../../../../../../hooks/api";
import { contentPadding } from "../../../../../../../utils/functions/contentPadding";
import { useProjectTeamContextSelector } from "../controllers/ProjectTeamController";
import ProjectTeamComment from "./ProjectTeamComment";

type ProjectTeamCommentSectionProps = {};

const ProjectTeamCommentSection: React.FC<ProjectTeamCommentSectionProps> = (props) => {
	const adminApi = useApi(AdminApi);

	const { data: me, loading } = useFetch(adminApi.getMeAdmin);

	const mentorFeedbacks = useProjectTeamContextSelector("mentorFeedbacks");
	const loadingMentorFeedbacks = useProjectTeamContextSelector("loadingMentorFeedbacks");
	const errorMentorFeedbacks = useProjectTeamContextSelector("errorMentorFeedbacks");
	const revalidateMentorFeedbacks = useProjectTeamContextSelector("revalidateMentorFeedbacks");

	const addMentorFeedback = useProjectTeamContextSelector("addMentorFeedback");
	const editMentorFeedback = useProjectTeamContextSelector("editMentorFeedback");

	const sprintInteractionId = useProjectTeamContextSelector("sprintInteractionId");
	const selectedSprint = useProjectTeamContextSelector("selectedSprint");

	const [mentorFeedbackContent, setMentorFeedbackContent] = useState("");

	const adminFeedback = mentorFeedbacks?.find((mentorFeedback) => mentorFeedback.adminId === me?.id);

	const [editorVisible, setEditorVisible] = useState(!adminFeedback || adminFeedback?.isDraft);
	const [editorKeyRef, setEditorKeyRef] = useState(1);
	const contentEditorRef = useRef<ContentEditorRef>(null);

	useEffect(() => {
		contentEditorRef.current?.clearEditor();
	}, [selectedSprint]);

	const addMentorFeedbackFunction = ({ isDraft }: { isDraft: boolean }) => {
		addMentorFeedback(mentorFeedbackContent, isDraft).then(() => {
			MLDialog.showSnackbar("Commento aggiunto", { variant: "success" });
			revalidateMentorFeedbacks();
		});
	};

	const editMentorFeedbackFunction = ({ isDraft }: { isDraft: boolean }) => {
		editMentorFeedback(adminFeedback?.id as string, mentorFeedbackContent, isDraft).then(() => {
			MLDialog.showSnackbar("Commento modificato correttamente", { variant: "success" });
			revalidateMentorFeedbacks();
		});
	};

	const moreComments =
		(mentorFeedbacks || [])?.filter((mentorFeedback) => {
			return !mentorFeedback.adminId || mentorFeedback.adminId !== me?.id;
		}).length > 0;

	useEffect(() => {
		setEditorVisible(!adminFeedback || adminFeedback?.isDraft);
	}, [adminFeedback]);

	useEffect(() => {
		setEditorKeyRef((value) => value + 1);
		setMentorFeedbackContent(adminFeedback?.content?.body || "");
	}, [editorVisible]);

	if (!sprintInteractionId) {
		return null;
	}

	return (
		<Card>
			<CardContent sx={{ ...contentPadding(6) }}>
				{!loading && !loadingMentorFeedbacks ? (
					<Stack spacing={6}>
						<Stack direction="row" spacing={3} alignItems="center">
							<IPlatformIcon name="chat_outline" size={32} />
							<Typography children="Lascia un commento" variant="subtitle1" />
						</Stack>
						{(editorVisible || adminFeedback?.isDraft) && (
							<React.Fragment>
								<ContentEditor
									initialValue={
										(adminFeedback?.isDraft || (editorVisible && !adminFeedback?.isDraft)) && adminFeedback?.content?.body
											? adminFeedback?.content?.body
											: ""
									}
									onChange={(value) => {
										setMentorFeedbackContent(value);
									}}
									simpleMode
									minHeight={200}
									maxHeight={200}
									ref={contentEditorRef}
								/>
								<Stack
									direction="row"
									spacing={3}
									justifyContent={editorVisible && adminFeedback?.isDraft ? "space-between" : "flex-end"}
									alignItems="center"
								>
									{editorVisible && adminFeedback?.isDraft && (
										<Button
											children="Salva bozza"
											variant="outlined"
											onClick={() => {
												Boolean(adminFeedback)
													? editMentorFeedbackFunction({ isDraft: true })
													: addMentorFeedbackFunction({ isDraft: true });
											}}
										/>
									)}
									<Button
										children="Invia feedback"
										variant="contained"
										size="medium"
										onClick={() => {
											Boolean(adminFeedback)
												? editMentorFeedbackFunction({ isDraft: false })
												: addMentorFeedbackFunction({ isDraft: false });
										}}
									/>
								</Stack>
							</React.Fragment>
						)}
						{!editorVisible && adminFeedback && (
							<ProjectTeamComment
								feedback={adminFeedback}
								onFeedbackEdit={() => {
									setEditorVisible(true);
								}}
							/>
						)}
						{moreComments && (
							<React.Fragment>
								<Divider />
								<Stack spacing={3}>
									<Typography children="Altri commenti" variant="subtitle1" />
									{/* <ProjectTeamComment feedback={adminFeedback} /> */}
									{(mentorFeedbacks || [])
										?.filter((mentorFeedback) => {
											return !mentorFeedback.adminId || mentorFeedback.adminId !== me?.id;
										})
										?.map((mentorFeedback) => {
											return <ProjectTeamComment feedback={mentorFeedback} key={mentorFeedback?.id} />;
										})}
								</Stack>
							</React.Fragment>
						)}
					</Stack>
				) : (
					<Loading />
				)}
			</CardContent>
		</Card>
	);
};

export default ProjectTeamCommentSection;
