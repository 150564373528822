import { alpha, Button, Card, CardContent, useTheme } from "@mui/material";
import type { MissingFeedbackData } from "innovation-api-manager";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import LinkBehavior from "../../../../../../../components/LinkBehavior";
import DataTable from "../../../../../../../components/tables/DataTable/DataTable";
import type { DataTableColumn } from "../../../../../../../components/tables/DataTable/interfaces/DataTable";
import { useSprintFeedbacksContextSelector } from "../controllers/SprintDeliverableController";

type SprintFeedbackCardProps = {
	children?: React.ReactNode;
};

const SprintFeedbackCard: React.FC<SprintFeedbackCardProps> = (props) => {
	const theme = useTheme();

	const missingFeedbacks = useSprintFeedbacksContextSelector("missingFeedbacks");
	const loadingMissingFeedbacks = useSprintFeedbacksContextSelector("loadingMissingFeedbacks");
	const errorMissingFeedbacks = useSprintFeedbacksContextSelector("errorMissingFeedbacks");
	const revalidateMissingFeedbacks = useSprintFeedbacksContextSelector("revalidateMissingFeedbacks");

	const readOnly = useSprintFeedbacksContextSelector("readOnly");

	const sprints = useSprintFeedbacksContextSelector("sprints");
	const loadingSprints = useSprintFeedbacksContextSelector("loadingSprints");

	const columns: DataTableColumn<MissingFeedbackData>[] = [
		{
			id: "team",
			label: "Team",
			renderData: (team) => {
				return team?.name;
			},
		},
		{
			id: "sprint",
			label: "Sprint",
			renderData: (sprint, fullObject) => {
				const index = sprints?.findIndex((s) => s.id === sprint.id);

				if (index === undefined) {
					return null;
				}

				return (
					<Button
						LinkComponent={LinkBehavior}
						href={`../team/${fullObject.team?.id ?? ""}?sprint=${sprint.id}`}
						target="_blank"
						rel="noopener noreferrer"
						children={`Deliverable allo Sprint ${index + 1}`}
						endIcon={<IPlatformIcon name="arrow_mini_outline_right" />}
						sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.06) }}
					/>
				);
			},
		},
		{
			id: "button_0",
			renderData: () => {
				if (readOnly) {
					return null;
				}
				return <Button children={"Salta feedback"} endIcon={<IPlatformIcon name="chat_outline" />} />;
			},
		},
	];

	return (
		<Card sx={{ maxWidth: theme.breakpoints.values.lg }}>
			<CardContent>
				<DataTable data={missingFeedbacks || []} columns={columns} loading={loadingMissingFeedbacks} />
			</CardContent>
		</Card>
	);
};

export default SprintFeedbackCard;
