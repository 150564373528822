/** @jsxImportSource @emotion/react */

import { Skeleton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import { useProjectTeamContextSelector } from "../controllers/ProjectTeamController";
import ProjectTeamMembersCard from "./ProjectTeamMembersCard";
import ProjectTeamMembersDialog from "./ProjectTeamMembersDialog";

type ProjectTeamHeadProps = {
	children?: React.ReactNode;
};

const ProjectTeamHead: React.FC<ProjectTeamHeadProps> = (props) => {
	const [referentsOpen, setReferentsOpen] = useState(false);
	const [participantsOpen, setParticipantsOpen] = useState(false);

	const teamName = useProjectTeamContextSelector("teamName");
	const loadingTeamName = useProjectTeamContextSelector("loadingTeamName");

	const teamMembers = useProjectTeamContextSelector("teamMembers");
	const loadingTeamMembers = useProjectTeamContextSelector("teamMembers");

	const teamMentors = useProjectTeamContextSelector("teamMentors");
	const loadingTeamMentors = useProjectTeamContextSelector("teamMentors");

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
			<Link to={"../deliverable"} css={{ textDecoration: "none" }}>
				<Stack direction={"row"} alignItems="center" gap={2}>
					<IPlatformIcon name="arrow_mini_outline_left" color="#fff" size={36} />
					<Typography children={teamName ?? ""} variant="h4" component="span" color="white.primary" />
				</Stack>
			</Link>
			<Stack direction="row" alignItems="center" spacing={3}>
				<ProjectTeamMembersCard
					members={teamMentors || []}
					onClick={() => {
						setReferentsOpen(true);
					}}
					areReferents
				/>
				<ProjectTeamMembersCard
					members={teamMembers || []}
					onClick={() => {
						setParticipantsOpen(true);
					}}
				/>
			</Stack>
			<ProjectTeamMembersDialog
				open={referentsOpen}
				onClose={() => {
					setReferentsOpen(false);
				}}
				members={teamMentors || []}
				areReferents
			/>
			<ProjectTeamMembersDialog
				open={participantsOpen}
				onClose={() => {
					setParticipantsOpen(false);
				}}
				members={teamMembers || []}
			/>
		</Stack>
	);
};

export default ProjectTeamHead;
