/** @jsxImportSource @emotion/react */

import { Button, CircularProgress, Stack, Tab, Tabs } from "@mui/material";
import type { SprintContent, SprintContentTypeEnum } from "innovation-api-manager";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import NoContents from "../../../../../../../components/NoContents";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { useSprintContentsContextSelector } from "../controllers/SprintContentsController";
import { SprintQuestionsContextProvider, useSprintQuestionsController } from "../controllers/SprintQuestionsController";
import { reorderArray } from "../functions/reorderArray";
import { useReorderSprintContent } from "../hooks/useReorderSprintContent";
import CreateSprintContentDialog from "./CreateSprintContentDialog";
import SprintContentItem from "./SprintContentItem";
import SprintQuestionsSection from "./SprintQuestionsSection";

const tabs: (SprintContentTypeEnum | "questions")[] = [
	"content",
	"deliverable",
	"needHelp",
	"extraReading",
	"questions",
];

type SprintContentsSectionProps = {
	readOnly?: boolean;
};

const SprintContentsSection: React.FC<SprintContentsSectionProps> = (props) => {
	const { readOnly } = props;

	const { t } = useTranslation();

	const sprintQuestionsController = useSprintQuestionsController();

	const sprintContents = useSprintContentsContextSelector("sprintContents");
	const loadingSprintContents = useSprintContentsContextSelector("loadingSprintContents");
	const revalidateSprintContents = useSprintContentsContextSelector("revalidateSprintContents");

	const selectedSprintContent = useSprintContentsContextSelector("selectedSprintContent");
	const setSelectedSprintContent = useSprintContentsContextSelector("setSelectedSprintContent");

	const selectedTab = useSprintContentsContextSelector("selectedTab");
	const setSelectedTab = useSprintContentsContextSelector("setSelectedTab");

	const addSprintContent = useSprintContentsContextSelector("addSprintContent");
	const editSprintContent = useSprintContentsContextSelector("editSprintContent");
	const deleteSprintContent = useSprintContentsContextSelector("deleteSprintContent");

	const [createDialogOpen, setCreateDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);

	const [contents, setContents] = useState<SprintContent[]>([]);

	useEffect(() => {
		setContents(
			sprintContents?.filter((content) => {
				return content?.type === selectedTab;
			}) ?? []
		);
	}, [selectedTab, sprintContents]);

	const { orderContent } = useReorderSprintContent();

	return (
		<div>
			<Stack spacing={6}>
				<Tabs
					value={selectedTab}
					onChange={(event, value) => {
						setSelectedTab(value);
					}}
				>
					{tabs?.map((tab) => {
						return <Tab label={t(`sprint.tabs.${tab}`)} value={tab} key={tab} />;
					})}
				</Tabs>
				{!loadingSprintContents && !!sprintContents && selectedTab !== "questions" && (
					<DragDropContext
						onDragEnd={(result, provided) => {
							setContents((prev) => {
								const startIndex = result.source.index;
								const endIndex = result.destination?.index;
								const { reorderedArray, movedItem } = reorderArray(prev, startIndex, endIndex);
								if (movedItem && endIndex !== undefined) {
									orderContent(movedItem.id, endIndex);
								}
								return reorderedArray;
							});
						}}
					>
						<Droppable droppableId={"sprint-contents"} isCombineEnabled={false}>
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{contents.map((content, index) => {
										const draggableId = content.id;
										return (
											<Draggable key={draggableId} draggableId={draggableId} index={index}>
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														css={{ outline: "none", position: "relative" }}
													>
														<SprintContentItem
															content={content}
															key={content.id}
															onEdit={(contentToEdit) => {
																setSelectedSprintContent(contentToEdit);
																setEditDialogOpen(true);
															}}
															readOnly={readOnly}
														/>
													</div>
												)}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				)}
				{loadingSprintContents && selectedTab !== "questions" && (
					<Stack direction="row" justifyContent="center" sx={{ my: 3 }}>
						<CircularProgress />
					</Stack>
				)}
				{!loadingSprintContents &&
					selectedTab !== "questions" &&
					(!sprintContents ||
						sprintContents?.filter((content) => {
							return content?.type === selectedTab;
						})?.length === 0) && <NoContents title="Nessun contenuto" sx={{ padding: 0, justifyContent: "center" }} />}
				{!loadingSprintContents && selectedTab !== "questions" && !readOnly && (
					<Button
						children="Aggiungi un contenuto"
						variant="outlined"
						startIcon={<IPlatformIcon name="add" />}
						onClick={() => {
							setCreateDialogOpen(true);
						}}
					/>
				)}
				{selectedTab === "questions" && (
					<SprintQuestionsContextProvider sprintQuestionsController={sprintQuestionsController}>
						<SprintQuestionsSection readOnly={readOnly} />
					</SprintQuestionsContextProvider>
				)}
			</Stack>
			<CreateSprintContentDialog
				open={createDialogOpen}
				onClose={() => {
					setCreateDialogOpen(false);
				}}
				mode="create"
				contentType={selectedTab}
				onSave={async () => {
					await addSprintContent()
						?.then(() => {
							revalidateSprintContents();
							MLDialog.showSnackbar("Contenuto aggiunto con successo", { variant: "success" });
							setCreateDialogOpen(false);
						})
						.catch((err) => {
							console.log("error", err);
							MLDialog.showSnackbar("C'è stato un errore durante l'aggiunta del contenuto", { variant: "error" });
						});
				}}
			/>
			<CreateSprintContentDialog
				open={editDialogOpen}
				onClose={() => {
					setEditDialogOpen(false);
				}}
				mode="edit"
				contentType={selectedTab}
				sprintContent={selectedSprintContent}
				onSave={async () => {
					await editSprintContent()
						?.then(() => {
							revalidateSprintContents();
							MLDialog.showSnackbar("Contenuto aggiornato con successo", { variant: "success" });
							setEditDialogOpen(false);
						})
						.catch((err) => {
							console.log("error", err);
							MLDialog.showSnackbar("C'è stato un errore durante l'aggiornamento del contenuto", { variant: "error" });
						});
				}}
				onDelete={() => {
					MLDialog.showModal(
						"Elimina contenuto",
						"Sei sicuro di voler eliminare questo contenuto? Una volta eliminato non potrai ripristinarlo",
						{
							positiveButton: (onClick) => (
								<Button
									children="Elimina contenuto"
									variant="contained"
									color="error"
									startIcon={<IPlatformIcon name="delete_outline" />}
									onClick={onClick}
								/>
							),
							onPositiveClick: async () => {
								await deleteSprintContent()
									.then(() => {
										revalidateSprintContents();
										MLDialog.hideModal();
										setEditDialogOpen(false);
										MLDialog.showSnackbar("Contenuto eliminato correttamente", { variant: "success" });
									})
									.catch((err) => {
										console.log("err", err);
										MLDialog.showSnackbar("C'è stato un problema durante l'eliminazione del contenuto", { variant: "error" });
									});
							},
						}
					);
				}}
			/>
		</div>
	);
};

export default SprintContentsSection;
