import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormLabel, Stack } from "@mui/material";
import type { Sprint } from "innovation-api-manager";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useImmer } from "use-immer";
import LoadingIconButton from "../../../../../../../components/buttons/LoadingIconButton";
import BetterDatePicker from "../../../../../../../components/datePicker/BetterDatePicker";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import BetterTextInput from "../../../../../../../components/textInput/BetterTextInput";
import { useProjectSprintsContextSelector } from "../controllers/ProjectSprintsController";

type CreateSprintDialogProps = { open: boolean; onClose: () => void; onSave: () => Promise<void> } & (
	| {
			mode?: "create";
			sprint?: never;
			onDelete?: never;
	  }
	| {
			mode?: "edit";
			sprint: Sprint;
			onDelete: () => void;
	  }
);

const CreateSprintDialog: React.FC<CreateSprintDialogProps> = (props) => {
	const { t } = useTranslation();

	const { open, onClose, mode = "create", sprint, onSave, onDelete } = props;

	const editMode = mode === "edit";

	const temporarySprint = useProjectSprintsContextSelector("temporarySprint");
	const setTemporarySprint = useProjectSprintsContextSelector("setTemporarySprint");

	const { data: project } = useProjectSprintsContextSelector("projectFetch");

	useEffect(() => {
		if (open && editMode && !!sprint) {
			setTemporarySprint((draft) => {
				draft.title = sprint?.title || "";
				draft.startDate = sprint?.startDate || "";
				draft.endDate = sprint?.endDate || "";
				draft.description = sprint?.description || "";
			});
		} else {
			setTemporarySprint((draft) => {
				draft.title = "";
				draft.startDate = "";
				draft.endDate = "";
				draft.description = "";
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, sprint]);

	const [onSaveLoading, setOnSaveLoading] = useState(false);
	const [dateErrors, setDateErrors] = useImmer({
		closingBeforeOpening: false,
		openingBeforeProjectOpening: false,
		closingAfterProjectClosing: false,
	});

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle children={editMode ? "Modifica sprint" : "Crea un nuovo sprint"} sx={{ fontWeight: 600 }} />
			<DialogContent>
				<div style={{ overflow: "hidden", height: "calc(100% + 2px)", width: "calc(100% + 2px)", padding: 2, margin: -2 }}>
					<Stack spacing={8}>
						<FormControl fullWidth>
							<BetterTextInput
								value={temporarySprint?.title}
								onChange={(event) => {
									setTemporarySprint((draft) => {
										draft.title = event.target.value;
									});
								}}
								placeholder={"Nome dello sprint"}
								size="medium"
								label={"Nome dello sprint"}
								fullWidth
							/>
						</FormControl>
						{!project?.allUnlocked && (
							<>
								<FormControl fullWidth sx={{ position: "relative" }}>
									<FormLabel children={t("project.startDate")} />
									<BetterDatePicker
										label={t("project.startDate")}
										value={temporarySprint?.startDate}
										onChange={(date) => {
											setTemporarySprint((draft) => {
												draft.startDate = date || DateTime.now().toISODate();
											});
										}}
										textInputProps={{
											helperText: "La data di inizio deve essere successiva alla data di apertura del progetto",
											status: dateErrors.openingBeforeProjectOpening ? "error" : undefined,
										}}
									/>
								</FormControl>
								<FormControl fullWidth sx={{ position: "relative" }}>
									<FormLabel children={t("project.endDate")} />
									<BetterDatePicker
										label={t("project.endDate")}
										value={temporarySprint?.endDate}
										onChange={(date) => {
											setTemporarySprint((draft) => {
												draft.endDate = date || DateTime.now().toISODate();
											});
											if (DateTime.fromISO(date) >= DateTime.fromISO(temporarySprint?.startDate)) {
												setDateErrors((draft) => {
													draft.closingBeforeOpening = false;
												});
											}
										}}
										textInputProps={{
											helperText: dateErrors.closingBeforeOpening
												? "La data di fine deve essere successiva alla data di inizio"
												: "La data di fine deve essere precedente alla data di chiusura del progetto",
											status: dateErrors.closingBeforeOpening || dateErrors.closingAfterProjectClosing ? "error" : undefined,
										}}
									/>
								</FormControl>
							</>
						)}
						<FormControl fullWidth>
							<BetterTextInput
								value={temporarySprint?.description}
								onChange={(event) => {
									setTemporarySprint((draft) => {
										draft.description = event.target.value;
									});
								}}
								placeholder={"Descrizione dello sprint"}
								size="medium"
								label={"Descrizione dello sprint"}
								fullWidth
							/>
						</FormControl>
						<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
							{editMode && (
								<Button
									children="Elimina sprint"
									variant="contained"
									color="error"
									startIcon={<IPlatformIcon name="delete_outline" />}
									onClick={onDelete}
								/>
							)}
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								spacing={3}
								sx={{ width: editMode ? "auto" : "100%" }}
							>
								<Button children="Annulla" onClick={onClose} startIcon={<IPlatformIcon name="close" />} />
								<LoadingIconButton
									loading={onSaveLoading}
									children={editMode ? "Salva sprint" : "Aggiungi sprint"}
									variant="contained"
									icon={<IPlatformIcon name="success" />}
									onClick={async () => {
										const sprintEndDate = DateTime.fromISO(temporarySprint.endDate).startOf("day");
										const sprintStartDate = DateTime.fromISO(temporarySprint.startDate).startOf("day");

										const projectEndDate = DateTime.fromISO(project?.closingDate || DateTime.now().toISODate()).startOf("day");
										const projectStartDate = DateTime.fromISO(project?.openingDate || DateTime.now().toISODate()).startOf("day");

										if (sprintEndDate <= sprintStartDate && !project?.allUnlocked) {
											setDateErrors((draft) => {
												draft.closingBeforeOpening = true;
											});
											return;
										}
										if (project?.datesEnabled) {
											if (sprintEndDate > projectEndDate) {
												setDateErrors((draft) => {
													draft.closingAfterProjectClosing = true;
												});
												return;
											}
											if (sprintStartDate < projectStartDate) {
												setDateErrors((draft) => {
													draft.openingBeforeProjectOpening = true;
												});
												return;
											}
										}
										setDateErrors((draft) => {
											draft.closingBeforeOpening = false;
											draft.closingAfterProjectClosing = false;
											draft.openingBeforeProjectOpening = false;
										});
										setOnSaveLoading(true);
										await onSave();
										setOnSaveLoading(false);
									}}
								/>
							</Stack>
						</Stack>
					</Stack>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default CreateSprintDialog;
