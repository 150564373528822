import { Box, Fade, Modal, ModalProps, SxProps, Theme, useTheme } from "@mui/material";

interface Props extends ModalProps {
	containerSx?: SxProps<Theme>;
	responsive?: boolean;
}

const WhiteModal: React.FC<Props> = ({ children, containerSx, responsive, ...otherProps }) => {
	const theme = useTheme();
	return (
		<Modal
			keepMounted
			closeAfterTransition
			BackdropProps={{
				timeout: 500,
			}}
			{...otherProps}
		>
			<Fade in={otherProps.open}>
				<Box
					sx={{
						"&:focus": {
							outline: "none",
						},
					}}
				>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							bgcolor: "background.paper",
							boxShadow: 24,
							borderRadius: 4,
							p: 6,
							transition: theme.transitions.create(["width", "opacity"]),
							boxSizing: responsive ? "border-box" : undefined,
							width: responsive
								? {
										xs: "90vw",
										sm: 500,
										lg: 600,
								  }
								: undefined,

							...containerSx,
						}}
					>
						{children}
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default WhiteModal;
