import { Typography } from "@mui/material";
import { Suspense } from "react";
import FillScreenScrollView from "../../components/FillScreenScrollView";

type SuspenseWrapperProps = {
	children?: React.ReactNode;
};

const SuspenseWrapper: React.FC<SuspenseWrapperProps> = ({ children }) => {
	return (
		<Suspense
			fallback={
				<FillScreenScrollView sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					{/**TODO: replace with a serious component */}
					<Typography children="Loading" variant="h6" />
				</FillScreenScrollView>
			}
		>
			{children}
		</Suspense>
	);
};
export default SuspenseWrapper;
