import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useNotifier } from "react-use-notifier";
import { createContext, useContextSelector } from "use-context-selector";

export function useScaffoldController() {
	const backTitle = useNotifier("");
	const backHref = useNotifier("");

	const { tenantId, projectId } = useParams();

	useEffect(() => {
		if (projectId) {
			backTitle.value = "Progetti";
			backHref.value = `/tenants/${tenantId}/projects`;
		} else {
			backTitle.value = "Tenants";
			backHref.value = `/tenants`;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenantId, projectId]);

	return { backTitle, backHref };
}

type ScaffoldContextType = ReturnType<typeof useScaffoldController>;

const ScaffoldContext = createContext<ScaffoldContextType | undefined>(undefined);

export const ScaffoldContextProvider: React.FC<{
	scaffoldController: ScaffoldContextType;
	children: React.ReactNode;
}> = ({ children, scaffoldController }) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoChildren = useMemo(() => children, []);
	return <ScaffoldContext.Provider value={scaffoldController}>{memoChildren}</ScaffoldContext.Provider>;
};

export function useScaffoldContextSelector<T extends keyof ScaffoldContextType>(field: T): ScaffoldContextType[T] {
	return useContextSelector(ScaffoldContext, (v) => v![field]);
}

export function useScaffoldContextUnoptimized() {
	const context = useContextSelector(ScaffoldContext, (v) => v);

	if (context === undefined) {
		throw new Error("Trying to use context outside of provider");
	}

	return context;
}
