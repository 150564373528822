import { Avatar, AvatarGroup, ButtonBase, Card, CardContent, Stack, Typography } from "@mui/material";
import type { User } from "innovation-api-manager";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import { contentPadding } from "../../../../../../../utils/functions/contentPadding";

type ProjectTeamMembersCardProps = {
	members: User[];
	onClick?: () => void;
	areReferents?: boolean;
};

const ProjectTeamMembersCard: React.FC<ProjectTeamMembersCardProps> = (props) => {
	const { members, onClick, areReferents } = props;

	return (
		<ButtonBase onClick={onClick}>
			<Card sx={{ borderRadius: 3 }}>
				<CardContent sx={{ ...contentPadding(2) }}>
					<Stack spacing={1}>
						<Stack direction="row" spacing={1} alignItems="center">
							<IPlatformIcon name="scheda_personale-sg" />
							<Typography
								children={areReferents ? "Referenti" : "Partecipanti"}
								variant="subtitle2"
								sx={{ fontWeight: 600 }}
							/>
						</Stack>
						<Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center" sx={{ minHeight: 24 }}>
							{members?.length > 0 && (
								<AvatarGroup max={4} componentsProps={{ additionalAvatar: { sx: { width: 20, height: 20, fontSize: 12 } } }}>
									{members.map((member, index) => {
										return (
											<Avatar
												src="https://images.unsplash.com/photo-1653608186993-6222f33857bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
												alt={`${member?.name} ${member?.surname}`}
												sx={{ width: 20, height: 20 }}
												key={member?.id}
											/>
										);
									})}
								</AvatarGroup>
							)}
							<Typography children={`${members.length} ${areReferents ? "referenti" : "partecipanti"}`} variant="caption" />
							{/** TODO gestire mancanza di utenti con testo "Nessun referente"/"Nessun partecipante" */}
						</Stack>
					</Stack>
				</CardContent>
			</Card>
		</ButtonBase>
	);
};

export default ProjectTeamMembersCard;
