import {
	AdminSprintApi,
	AdminSprintContentApi,
	AdminSprintInteractionApi,
	MentorFeedbackApi,
	MentorTeamApi,
	Sprint,
	TeamApi,
} from "innovation-api-manager";
import { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { createContext, useContextSelector } from "use-context-selector";
import { transformToStringFrom } from "../../../../../../../components/tables/AdminTable";
import { useApi, useFetch } from "../../../../../../../hooks/api";
import { useAccount } from "../../../../../../../hooks/useAccount";

export function useProjectTeamController() {
	const { tenantId, projectId, teamId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const sprintId = searchParams.get("sprint");

	const { data: me } = useAccount();
	const readOnly = me?.role === "ReadOnlyTenantManager";

	const adminSprintApi = useApi(AdminSprintApi);
	const adminSprintContentApi = useApi(AdminSprintContentApi);
	const adminSprintInteractionApi = useApi(AdminSprintInteractionApi);
	const mentorFeedbackApi = useApi(MentorFeedbackApi);
	const mentorTeamApi = useApi(MentorTeamApi);
	const teamApi = useApi(TeamApi);

	const [selectedSprint, setSelectedSprint] = useState<Sprint>();

	const { data: teamName, loading: loadingTeamName } = useFetch(
		teamApi.getTeamNameById,
		teamId as string,
		tenantId as string,
		{
			skip: !tenantId || !teamId,
		}
	);

	const { data: teamMembers, loading: loadingTeamMembers } = useFetch(
		mentorTeamApi.getTeamMembers,
		teamId as string,
		tenantId as string,
		{
			skip: !tenantId || !teamId,
		}
	);

	const { data: teamMentors, loading: loadingTeamMentors } = useFetch(
		mentorTeamApi.getTeamMentors,
		teamId as string,
		tenantId as string,
		{
			skip: !tenantId || !teamId,
		}
	);

	const {
		data: sprints,
		loading: loadingSprints,
		error: errorSprints,
		revalidate: revalidateSprints,
	} = useFetch(adminSprintApi.getAllProjectSprints, projectId as string, tenantId as string, {
		skip: !projectId || !tenantId,
	});

	const {
		data: sprintContents,
		loading: loadingSprintContents,
		error: errorSprintContents,
		revalidate: revalidateSprintContents,
	} = useFetch(
		adminSprintContentApi.getAdminSprintContents,
		sprintId as string,
		tenantId as string,
		transformToStringFrom({ filter: [{ type: "deliverable" }] }),
		{
			skip: !sprintId || !tenantId,
		}
	);

	const {
		data: teamDeliverables,
		loading: loadingTeamDeliverables,
		error: errorTeamDeliverables,
		revalidate: revalidateTeamDeliverables,
	} = useFetch(adminSprintApi.getAdminTeamDeliverables, sprintId as string, teamId as string, tenantId as string, {
		skip: !sprintId || !teamId || !tenantId,
	});

	const sprintInteractionId = teamDeliverables?.find((sprintInteraction) => {
		return sprintInteraction.outdated === false;
	})?.sprintInteractionId;

	const {
		data: adminEvaluation,
		loading: loadingAdminEvaluation,
		error: errorAdminEvaluation,
		revalidate: revalidateAdminEvaluation,
	} = useFetch(adminSprintInteractionApi.getAdminEvaluation, sprintInteractionId as string, tenantId as string, {
		skip: !sprintInteractionId || !tenantId,
	});

	const giveEvaluation = (value: number) => {
		return adminSprintInteractionApi.giveAdminEvaluation(sprintInteractionId as string, tenantId, {
			adminEvaluation: value,
		});
	};

	const {
		data: sprintAnswers,
		loading: loadingSprintAnswers,
		error: errorSprintAnswers,
		revalidate: revalidateSprintAnswers,
	} = useFetch(adminSprintInteractionApi.getSprintAnswers, sprintInteractionId as string, tenantId as string, {
		skip: !sprintInteractionId || !tenantId,
	});

	const {
		data: mentorFeedbacks,
		loading: loadingMentorFeedbacks,
		error: errorMentorFeedbacks,
		revalidate: revalidateMentorFeedbacks,
	} = useFetch(
		mentorFeedbackApi.getAllAdminSprintInteractionFeedbacks,
		sprintInteractionId as string,
		tenantId as string,
		{
			skip: !sprintInteractionId || !tenantId,
		}
	);

	const addMentorFeedback = (body: string, isDraft: boolean) => {
		return mentorFeedbackApi.addAdminFeedback(sprintInteractionId as string, tenantId, { content: { body }, isDraft });
	};

	const editMentorFeedback = (feedbackId: string, body: string, isDraft: boolean) => {
		return mentorFeedbackApi.editAdminMentorFeedback(feedbackId, tenantId, { content: { body }, isDraft });
	};

	useEffect(() => {
		if (sprints?.length) {
			const selectedIndex = sprintId
				? sprints?.findIndex((sprint) => sprint?.id === sprintId)
				: sprints?.findIndex((sprint) => sprint?.id === selectedSprint?.id);

			if (selectedIndex !== -1) {
				setSelectedSprint(sprints[selectedIndex]);
			} else {
				setSelectedSprint(sprints[0]);
			}
		}
	}, [sprints]);

	useEffect(() => {
		if (selectedSprint?.id) {
			setSearchParams({ sprint: selectedSprint?.id });
		}
	}, [selectedSprint?.id]);

	return {
		selectedSprint,
		setSelectedSprint,
		teamName,
		loadingTeamName,
		teamMembers,
		loadingTeamMembers,
		teamMentors,
		loadingTeamMentors,
		sprints,
		loadingSprints,
		errorSprints,
		revalidateSprints,
		sprintContents,
		loadingSprintContents,
		errorSprintContents,
		revalidateSprintContents,
		teamDeliverables,
		loadingTeamDeliverables,
		errorTeamDeliverables,
		revalidateTeamDeliverables,
		adminEvaluation,
		loadingAdminEvaluation,
		errorAdminEvaluation,
		revalidateAdminEvaluation,
		giveEvaluation,
		sprintAnswers,
		loadingSprintAnswers,
		errorSprintAnswers,
		revalidateSprintAnswers,
		mentorFeedbacks,
		loadingMentorFeedbacks,
		errorMentorFeedbacks,
		revalidateMentorFeedbacks,
		addMentorFeedback,
		editMentorFeedback,
		sprintInteractionId,
		readOnly,
	};
}

type ProjectTeamContextType = ReturnType<typeof useProjectTeamController>;

const ProjectTeamContext = createContext<ProjectTeamContextType | undefined>(undefined);

export const ProjectTeamContextProvider: React.FC<{
	projectTeamController: ProjectTeamContextType;
	children: React.ReactNode;
}> = ({ children, projectTeamController }) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoChildren = useMemo(() => children, []);
	return <ProjectTeamContext.Provider value={projectTeamController}>{memoChildren}</ProjectTeamContext.Provider>;
};

export function useProjectTeamContextSelector<T extends keyof ProjectTeamContextType>(
	field: T
): ProjectTeamContextType[T] {
	return useContextSelector(ProjectTeamContext, (v) => v![field]);
}

export function useProjectTeamContextUnoptimized() {
	const context = useContextSelector(ProjectTeamContext, (v) => v);

	if (context === undefined) {
		throw new Error("Trying to use context outside of provider");
	}

	return context;
}
