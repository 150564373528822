"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MentorTeamApi = exports.MentorTeamApiFactory = exports.MentorTeamApiFp = exports.MentorTeamApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * MentorTeamApi - axios parameter creator
 * @export
 */
exports.MentorTeamApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get team members
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMembers: (teamId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'teamId' is not null or undefined
            common_1.assertParamExists('getTeamMembers', 'teamId', teamId);
            const localVarPath = `/team/{teamId}/members`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get team mentors
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMentors: (teamId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'teamId' is not null or undefined
            common_1.assertParamExists('getTeamMentors', 'teamId', teamId);
            const localVarPath = `/team/{teamId}/mentors`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * MentorTeamApi - functional programming interface
 * @export
 */
exports.MentorTeamApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.MentorTeamApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get team members
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMembers(teamId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTeamMembers(teamId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get team mentors
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMentors(teamId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTeamMentors(teamId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * MentorTeamApi - factory interface
 * @export
 */
exports.MentorTeamApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.MentorTeamApiFp(configuration);
    return {
        /**
         *
         * @summary Get team members
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMembers(teamId, tenantId, options) {
            return localVarFp.getTeamMembers(teamId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get team mentors
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMentors(teamId, tenantId, options) {
            return localVarFp.getTeamMentors(teamId, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * MentorTeamApi - object-oriented interface
 * @export
 * @class MentorTeamApi
 * @extends {BaseAPI}
 */
class MentorTeamApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get team members
     * @param {string} teamId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorTeamApi
     */
    getTeamMembers(teamId, tenantId, options) {
        return exports.MentorTeamApiFp(this.configuration).getTeamMembers(teamId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get team mentors
     * @param {string} teamId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorTeamApi
     */
    getTeamMentors(teamId, tenantId, options) {
        return exports.MentorTeamApiFp(this.configuration).getTeamMentors(teamId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.MentorTeamApi = MentorTeamApi;
