import queryString from "query-string";

export function backendMediaUrlParser(
	fileUrl?: string,
	options?: { download?: boolean; filename?: string; width?: number; height?: number }
) {
	if (!fileUrl) return undefined;
	if (
		fileUrl.includes("blob:") ||
		fileUrl.includes("data:") ||
		fileUrl.startsWith("https://") ||
		fileUrl.startsWith("http://")
	)
		return fileUrl;

	const { download, filename, width, height } = options || {};

	let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/media/${fileUrl}`;

	if (width || height) {
		url = `${url}?${width ? `w=${width}` : ""}${height ? `${width ? "&" : ""}h=${height}` : ""}`;
	}

	if (download || filename) {
		const tmpParams = queryString.stringify({ download, filename });

		url += `?${tmpParams}`;
	}

	return url;
}
