import ScaffoldAppBar from "./components/ScaffoldAppBar";
import ScaffoldBody from "./components/ScaffoldBody";
import ScaffoldDrawer from "./components/ScaffoldDrawer";
import { ScaffoldContextProvider, useScaffoldController } from "./controllers/ScaffoldController";
import { Provider as JotaiProvider } from "jotai";

type ScaffoldProps = {};

const Scaffold: React.FC<ScaffoldProps> = () => {
	const scaffoldController = useScaffoldController();

	return (
		<JotaiProvider>
			<ScaffoldContextProvider scaffoldController={scaffoldController}>
				<ScaffoldAppBar />
				<ScaffoldDrawer />
				<ScaffoldBody />
			</ScaffoldContextProvider>
		</JotaiProvider>
	);
};
export default Scaffold;
