import type { Theme } from "@mui/material";
import { normal } from "color-blend";
import type { RGBA } from "color-blend/dist/types";
import type { TextInputStatus } from "../../components/textInput/interfaces/TextInputStatus";

export function rgbaStringToRGBA(value: string): RGBA {
	const rgbaArray = value
		.substring(5, value.length - 1)
		.replace(/\s/g, "")
		.split(",");
	return {
		r: parseFloat(rgbaArray[0]),
		g: parseFloat(rgbaArray[1]),
		b: parseFloat(rgbaArray[2]),
		a: parseFloat(rgbaArray[3]),
	};
}

export function blendWithWhite(foreground: RGBA): RGBA {
	return normal({ r: 255, g: 255, b: 255, a: 1 }, foreground);
}

//RGBA is the type of a blended color using color-blend npm module
export function RGBAtoString(value: RGBA): string {
	return `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
}

export function returnTextFieldStatusColor(
	theme: Theme,
	color: "primary" | "secondary" | undefined,
	status: TextInputStatus
): string {
	if (status === undefined) {
		if (color) return theme.palette[color].main;
		else return theme.palette.primary.main;
	}
	if (status === "loading") {
		return theme.palette.lightblue.main;
	}
	if (status === "info") {
		return theme.palette.purple.main;
	}
	return theme.palette[status].main;
}
