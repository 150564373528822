/** @jsxImportSource @emotion/react */

import { Box, Button, Card, CardContent, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import NoContents from "../../../../../../../components/NoContents";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { contentPadding } from "../../../../../../../utils/functions/contentPadding";
import { useSprintQuestionsContextSelector } from "../controllers/SprintQuestionsController";
import { reorderArray } from "../functions/reorderArray";
import { useReorderSprintQuestion } from "../hooks/useReorderSprintQuestion";
import CreateSprintQuestionDialog from "./CreateSprintQuestionDialog";

type SprintQuestionsSectionProps = {
	readOnly?: boolean;
};

const SprintQuestionsSection: React.FC<SprintQuestionsSectionProps> = (props) => {
	const { readOnly } = props;

	const sprintQuestions = useSprintQuestionsContextSelector("sprintQuestions");
	const mutateSprintQuestionsOptimistic = useSprintQuestionsContextSelector("mutateSprintQuestionsOptimistic");
	const loadingSprintQuestions = useSprintQuestionsContextSelector("loadingSprintQuestions");

	const revalidateSprintQuestions = useSprintQuestionsContextSelector("revalidateSprintQuestions");

	const selectedSprintQuestion = useSprintQuestionsContextSelector("selectedSprintQuestion");
	const setSelectecSprintQuestion = useSprintQuestionsContextSelector("setSelectedSprintQuestion");

	const addQuestion = useSprintQuestionsContextSelector("addQuestion");
	const editQuestion = useSprintQuestionsContextSelector("editQuestion");
	const deleteQuestion = useSprintQuestionsContextSelector("deleteQuestion");

	const [createDialogOpen, setCreateDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);

	const { orderQuestion } = useReorderSprintQuestion();

	return (
		<div>
			<Stack spacing={6}>
				<Stack spacing={1}>
					<Typography children="Domande per i partecipanti" variant="h6" sx={{ fontWeight: 600 }} />
					<Typography
						children="Aggiungi delle domande a cui dovranno rispondere i partecipanti a sprint ultimato"
						variant="subtitle2"
						color="text.secondary"
						sx={{ fontWeight: 600 }}
					/>
				</Stack>
				{!loadingSprintQuestions && !!sprintQuestions && (
					<DragDropContext
						onDragEnd={(result, provided) => {
							const startIndex = result.source.index;
							const endIndex = result.destination?.index;
							const { reorderedArray, movedItem } = reorderArray(sprintQuestions, startIndex, endIndex);
							if (movedItem && endIndex !== undefined) {
								orderQuestion(movedItem.id, endIndex);
							}
							mutateSprintQuestionsOptimistic(reorderedArray, { shouldReplaceData: true, shouldRevalidate: false });
						}}
					>
						<Droppable droppableId={"sprint-contents"} isCombineEnabled={false}>
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{sprintQuestions.map((question, index) => {
										const draggableId = question.id;
										return (
											<Draggable key={draggableId} draggableId={draggableId} index={index}>
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														css={{ outline: "none", position: "relative" }}
													>
														<Box sx={{ pt: 2, userSelect: "none" }}>
															<Card sx={{ backgroundColor: "#f1f4f7", borderRadius: 3 }} key={question?.id}>
																<CardContent sx={{ ...contentPadding(2) }}>
																	<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
																		<Stack direction="row" alignItems="center" spacing={3}>
																			<IPlatformIcon name="chat_outline" />
																			<Typography children={question?.question} variant="body2" />
																		</Stack>
																		<IconButton
																			onClick={() => {
																				setSelectecSprintQuestion(question);
																				setEditDialogOpen(true);
																			}}
																		>
																			<IPlatformIcon name="edit-sg" />
																		</IconButton>
																	</Stack>
																</CardContent>
															</Card>
														</Box>
													</div>
												)}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				)}
				{loadingSprintQuestions && (
					<Stack direction="row" justifyContent="center" sx={{ my: 3 }}>
						<CircularProgress />
					</Stack>
				)}
				{!loadingSprintQuestions && (!sprintQuestions || sprintQuestions?.length === 0) && (
					<NoContents title="Nessuna domanda per i partecipanti" sx={{ padding: 0, justifyContent: "center" }} />
				)}
				{!loadingSprintQuestions && !readOnly && (
					<Button
						children="Aggiungi una domanda"
						variant="outlined"
						startIcon={<IPlatformIcon name="add" />}
						onClick={() => {
							setCreateDialogOpen(true);
						}}
					/>
				)}
			</Stack>
			<CreateSprintQuestionDialog
				open={createDialogOpen}
				onClose={() => {
					setCreateDialogOpen(false);
				}}
				mode="create"
				onSave={() => {
					addQuestion()
						?.then(() => {
							revalidateSprintQuestions();
							MLDialog.showSnackbar("Domanda aggiunta con successo", { variant: "success" });
							setCreateDialogOpen(false);
						})
						.catch((err) => {
							console.log("error", err);
							MLDialog.showSnackbar("C'è stato un errore durante l'aggiunta della domanda", { variant: "error" });
						});
				}}
			/>
			<CreateSprintQuestionDialog
				open={editDialogOpen}
				onClose={() => {
					setEditDialogOpen(false);
				}}
				mode="edit"
				sprintQuestion={selectedSprintQuestion}
				onSave={() => {
					editQuestion()
						?.then(() => {
							revalidateSprintQuestions();
							MLDialog.showSnackbar("Domanda modificata con successo", { variant: "success" });
							setEditDialogOpen(false);
						})
						.catch((err) => {
							console.log("error", err);
							MLDialog.showSnackbar("C'è stato un errore durante l'aggiunta della domanda", { variant: "error" });
						});
				}}
				onDelete={() => {
					MLDialog.showModal(
						"Elimina domanda",
						"Sei sicuro di voler eliminare questa domanda? Una volta eliminata non potrai ripristinarla",
						{
							positiveButton: (onClick) => (
								<Button
									children="Elimina domanda"
									variant="contained"
									color="error"
									startIcon={<IPlatformIcon name="delete_outline" />}
									onClick={onClick}
								/>
							),
							onPositiveClick: () => {
								deleteQuestion()
									.then(() => {
										revalidateSprintQuestions();
										MLDialog.hideModal();
										setEditDialogOpen(false);
										MLDialog.showSnackbar("Domanda eliminata correttamente", { variant: "success" });
									})
									.catch((err) => {
										console.log("err", err);
										MLDialog.showSnackbar("C'è stato un problema durante l'eliminazione della domanda", { variant: "error" });
									});
							},
						}
					);
				}}
			/>
		</div>
	);
};
export default SprintQuestionsSection;
