/** @jsxImportSource @emotion/react */

import { alpha, useTheme } from "@mui/material";
import { AtomicBlockUtils, ContentBlock, ContentState, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import type { ContentInteraction, SprintInteraction } from "innovation-api-manager";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { EmbeddedLink } from "./EmbeddedLink";
import { FileAreaUpload } from "./FileAreaUpload";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MLDialog from "./poppers/MLDialog";

export const uploadFileAreaKey = "UPLOAD_FILE_AREA";

type ContentEditorProps = {
	initialValue: string;
	readOnly?: boolean;
	simpleMode?: boolean;
	withFileArea?: boolean;
	onChange?: (value: string) => void;
	placeholder?: string;
	onAcceptedFiles?: OnAcceptedFilesCallback;
	onDeletedFile?: (interactionId: number) => void;
	disableFileArea?: boolean;
	contentInteractions?: ContentInteraction[];
	sprintInteraction?: SprintInteraction;
	minHeight?: number;
	maxHeight?: number;
	fullHeight?: boolean;
};

export type OnAcceptedFilesCallback = (
	files: File[],
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => void;

let Editor: any = () => null;

export type ContentEditorRef = {
	clearEditor: () => void;
};

const ContentEditor: React.ForwardRefRenderFunction<ContentEditorRef, ContentEditorProps> = (props, ref) => {
	const theme = useTheme();

	const {
		initialValue,
		readOnly,
		simpleMode,
		withFileArea,
		onChange,
		placeholder,
		onAcceptedFiles,
		onDeletedFile,
		disableFileArea,
	} = props;

	const forwardInteractions = useRef({
		contentInteractions: props.contentInteractions,
		sprintInteraction: props.sprintInteraction,
	});
	forwardInteractions.current.contentInteractions = props.contentInteractions;
	forwardInteractions.current.sprintInteraction = props.sprintInteraction;

	const [ready, setReady] = useState(0);

	const [editorState, setEditorState] = useState(() => {
		if (initialValue) {
			try {
				const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(initialValue)));
				return editorState;
			} catch (err) {}
		}
		return EditorState.createEmpty();
	});

	useImperativeHandle(
		ref,
		() => ({
			clearEditor: () => {
				setEditorState(() => {
					return EditorState.createEmpty();
				});
			},
		}),
		[]
	);

	const customToolbarButtons = [];

	if (!readOnly && withFileArea) {
		customToolbarButtons.push(<FileAreaButton onClick={addFileAreaToContent} />);
	}

	useEffect(() => {
		import("react-draft-wysiwyg" as string)
			.then(({ Editor: WYSIWYGEditor }) => {
				Editor = WYSIWYGEditor;
				setReady((r) => r + 1);
			})
			.catch((e) => console.log("err loading wysisyg", e));
	}, []);

	useEffect(() => {
		setEditorState(EditorState.createWithContent(editorState.getCurrentContent()));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.contentInteractions]);

	useEffect(() => {
		setEditorState(() => {
			if (initialValue) {
				try {
					const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(initialValue)));
					return editorState;
				} catch (err) {}
			}
			return EditorState.createEmpty();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.initialValue]);

	return (
		<div
			css={{
				color: theme.palette.primary.main,
				".sg-editor-toolbar": {
					border: "1px solid rgba(0, 0, 0, 0.23)",
				},
				".sg-editor-wrapper": {
					// boxShadow: "0 0 5px orange",
					height: props.fullHeight ? "100%" : "auto",
				},
				".sg-editor-area": {
					backgroundColor: "white",
					borderRadius: 2,
					border: "1px solid rgba(0, 0, 0, 0.23)",
					".public-DraftEditorPlaceholder-root": {
						padding: "24px 16px",
					},
					".public-DraftEditor-content": {
						padding: 12,
						minHeight: props.minHeight || "50vh",
						maxHeight: props.maxHeight || "50vh",
						// cursor: "text",
						// boxShadow: "0 5px 15px rgba(0, 0, 0, 0.12)",
					},
				},
				".DraftEditor-root": {
					fontSize: 16,
					color: "rgba(0, 0, 0, 0.87)",
					// minHeight: props.minHeight || "50vh"
				},
				figure: {
					marginTop: 36,
					"@media screen and (max-width: 959px)": {
						marginLeft: 0,
						marginRight: 0,
					},
				},
				"figure img": { maxWidth: "100%" },
			}}
		>
			{ready > 0 &&
				(readOnly ? (
					<Editor
						editorState={editorState}
						readOnly={readOnly}
						toolbarHidden={readOnly}
						customBlockRenderFunc={blockRenderFn}
					/>
				) : (
					<Editor
						editorState={editorState}
						handlePastedText={() => false}
						toolbarCustomButtons={customToolbarButtons}
						customBlockRenderFunc={blockRenderFn}
						onEditorStateChange={(newEditorState: EditorState) => {
							if (!Editor) {
								return;
							}
							setEditorState(newEditorState);
							onEditorStateChange(newEditorState);
						}}
						toolbarClassName="sg-editor-toolbar"
						wrapperClassName="sg-editor-wrapper"
						editorClassName="sg-editor-area"
						placeholder={placeholder || "Inizia a scrivere qui"}
						toolbar={
							simpleMode
								? {
										//? https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
										// to see all options available see the sgeeks project
										options: ["inline", "list", "link", "emoji", "history"],
										inline: {
											options: ["bold", "italic", "underline"],
										},
										list: {
											options: ["unordered", "ordered"],
										},
								  }
								: undefined
						}
					/>
				))}
		</div>
	);

	function onEditorStateChange(newEditorState: EditorState) {
		// props.onChange?.(convertToRaw(newEditorState.getCurrentContent()));
		onChange?.(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())));
	}

	function addFileAreaToContent() {
		const contentState = editorState.getCurrentContent();

		if (editorContentContainsFileArea(contentState)) {
			MLDialog.showSnackbar("È già presente una FILE AREA all'interno del contenuto");
			return;
		}

		const contentStateWithEntity = contentState.createEntity(uploadFileAreaKey, "IMMUTABLE", {});
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
		const newEditorState = AtomicBlockUtils.insertAtomicBlock(
			EditorState.set(editorState, {
				currentContent: contentStateWithEntity,
			}),
			entityKey,
			" "
		);

		setEditorState(newEditorState);
		onEditorStateChange(newEditorState);
	}

	function blockRenderFn(contentBlock: ContentBlock) {
		const { contentInteractions, sprintInteraction } = forwardInteractions.current;
		if (contentBlock.getType() === "atomic") {
			const contentState = editorState.getCurrentContent();
			const entity = contentState.getEntity(contentBlock.getEntityAt(0));

			//? EMBEDDED LINK - VIMEO / YOUTUBE
			if (entity && entity.getType() === "EMBEDDED_LINK") {
				return {
					component: EmbeddedLink,
					editable: false,
				};
			}

			//? UPLOAD FILE AREA
			if (entity && entity.getType() === uploadFileAreaKey) {
				const fileContentInteractions = contentInteractions?.filter((interaction) => !!interaction.file).reverse();
				return {
					component: FileAreaUpload,
					editable: false,
					props: {
						disabled: disableFileArea || !props.readOnly,
						onAcceptedFiles: onAcceptedFiles,
						onDeletedFile: onDeletedFile,
						contentInteractions: fileContentInteractions,
						sprintInteraction: sprintInteraction,
					},
				};
			}
		}
	}
};

export function editorContentContainsFileArea(contentState: ContentState) {
	if (!contentState) return null;
	try {
		// const contentState = editorState.getCurrentContent();

		//contentState.getEntity(contentBlock.getEntityAt(0));

		let fileAreaBlock: ContentBlock;

		contentState.getBlockMap().forEach((contentBlock) => {
			const entityKey = contentBlock?.getEntityAt(0);
			if (!entityKey) return;
			const entity = contentState.getEntity(entityKey);
			if (entity && entity.getType() === uploadFileAreaKey) {
				// @ts-ignore
				fileAreaBlock = contentBlock;
			}
		});

		// @ts-ignore
		return !!fileAreaBlock;
	} catch (e) {
		console.log("error reading content blocks", e);
		return false;
	}
}

const FileAreaButton = (props: { onClick: () => void }) => {
	const theme = useTheme();

	return (
		<div
			className="rwd-option-wrapper"
			title="File Area"
			children="FILE AREA"
			onClick={props.onClick}
			css={{
				backgroundColor: alpha(theme.palette.primary.main, 0.7),
				color: "white",
				// color: R.colors.primary,
				// border: "1px solid " + R.colors.primary,
				alignSelf: "center",
				height: 25,
				padding: "0 12px",
				borderRadius: 5,
				fontWeight: "bold",
				marginBottom: 6,
				cursor: "pointer",
				transition: "all 300ms",
				boxShadow: "0 3px 8px " + alpha(theme.palette.primary.main, 0.2),
				":hover": {
					backgroundColor: alpha(theme.palette.primary.main, 0.5),
					boxShadow: "0 3px 12px " + alpha(theme.palette.primary.main, 0.2),
				},
			}}
		/>
	);
};

export default forwardRef(ContentEditor);
