import { alpha, Button, Stack, Tab, Tabs, tabsClasses, Tooltip, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { apiErrorParser } from "../../../../../../../hooks/api";
import { useProjectSprintsContextSelector } from "../controllers/ProjectSprintsController";
import CreateSprintDialog from "./CreateSprintDialog";

type SprintSelectorProps = {};

const SprintSelector: React.FC<SprintSelectorProps> = () => {
	const theme = useTheme();

	const [createDialogOpen, setCreateDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);

	const sprints = useProjectSprintsContextSelector("sprints");
	const loadingSprints = useProjectSprintsContextSelector("loadingSprints");
	const revalidateSprints = useProjectSprintsContextSelector("revalidateSprints");

	const selectedSprint = useProjectSprintsContextSelector("selectedSprint");
	const setSelectedSprint = useProjectSprintsContextSelector("setSelectedSprint");

	const [selectedTab, setSelectedTab] = useState(selectedSprint?.id);

	const addSprint = useProjectSprintsContextSelector("addSprint");
	const editSprint = useProjectSprintsContextSelector("editSprint");
	const deleteSprint = useProjectSprintsContextSelector("deleteSprint");

	const readOnly = useProjectSprintsContextSelector("readOnly");

	const noSprints = (!sprints || sprints?.length === 0) && !loadingSprints;

	const selectedIndex = sprints?.findIndex((sprint) => sprint.id === selectedSprint?.id);

	useEffect(() => {
		if (selectedSprint) {
			setSelectedTab(selectedSprint.id);
		}
	}, [selectedSprint]);

	return (
		<Stack spacing={8}>
			<Stack direction="row" alignItems="stretch" spacing={2}>
				<Tabs
					value={selectedTab}
					variant="scrollable"
					scrollButtons={"auto"}
					TabIndicatorProps={{
						sx: { display: "none" },
					}}
					sx={{
						// flex: 1,
						[`& .${tabsClasses.flexContainer}`]: {
							gap: 2,
						},
					}}
				>
					{sprints?.map((sprint, index) => {
						const sprintCompleted = DateTime.fromISO(sprint?.endDate as string) < DateTime.now();

						return (
							<Tab
								value={sprint?.id}
								label={
									<Stack direction="row" alignItems="center" spacing={3}>
										{sprintCompleted && <IPlatformIcon name="success" color="primary" />}
										<Stack alignItems="flex-start">
											<Typography
												children={`Sprint ${index + 1}`}
												variant="caption"
												color="primary"
												noWrap
												sx={{ fontWeight: 700, maxWidth: 120 }}
											/>
											{sprint.startDate && (
												<Typography
													children={DateTime.fromISO(sprint?.startDate).toFormat("dd MMMM")}
													variant="caption"
													color="primary"
													noWrap
												/>
											)}
										</Stack>
									</Stack>
								}
								onClick={() => {
									setSelectedSprint(sprint);
								}}
								sx={[
									{
										padding: "11px 12px",
										borderRadius: 3,
										backgroundColor: alpha(theme.palette.primary.main, 0.06),
										minWidth: 0,
									},
									selectedSprint?.id === sprint?.id && {
										boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
										backgroundColor: alpha(theme.palette.primary.main, 0.12),
									},
								]}
								key={sprint?.id}
							/>
						);
					})}
				</Tabs>
				{!readOnly && (
					<Tooltip title={noSprints ? "" : "Aggiungi uno sprint"}>
						<Button
							children={noSprints ? "Aggiungi uno sprint" : <IPlatformIcon name="add" />}
							onClick={() => {
								setCreateDialogOpen(true);
							}}
							startIcon={noSprints && <IPlatformIcon name="add" />}
						/>
					</Tooltip>
				)}
			</Stack>

			{Boolean(selectedSprint) && sprints?.length !== 0 && (
				<Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
					<Stack sx={{ flex: 1 }}>
						{selectedIndex !== undefined && (
							<Typography
								children={`Sprint ${selectedIndex + 1}`}
								variant="overline"
								sx={{ fontWeight: 600, marginBottom: theme.spacing(3) }}
							/>
						)}
						<Typography children={selectedSprint?.title} variant="subtitle2" sx={{ fontWeight: 600 }} />
						<Typography children={selectedSprint?.description} variant="body2" color="text.secondary" />
					</Stack>
					{!readOnly && (
						<Button
							children="Modifica sprint"
							variant="contained"
							onClick={() => {
								setEditDialogOpen(true);
							}}
						/>
					)}
				</Stack>
			)}

			<CreateSprintDialog
				open={createDialogOpen}
				onClose={() => {
					setCreateDialogOpen(false);
				}}
				mode="create"
				onSave={async () => {
					await addSprint()
						?.then(() => {
							revalidateSprints();
							setCreateDialogOpen(false);
							MLDialog.showSnackbar("Sprint aggiunto correttamente", { variant: "success" });
						})
						.catch((err) => {
							if (err.isAxiosError && err.response.status === 400) {
								MLDialog.showSnackbar("Errore, c'è già uno sprint in quelle date", { variant: "error" });
							} else {
								MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
							}
						});
				}}
			/>

			{!!selectedSprint && (
				<CreateSprintDialog
					open={editDialogOpen}
					onClose={() => {
						setEditDialogOpen(false);
					}}
					mode="edit"
					sprint={selectedSprint}
					onSave={async () => {
						await editSprint()
							?.then(() => {
								revalidateSprints();
								setEditDialogOpen(false);
								MLDialog.showSnackbar("Sprint modificato correttamente", { variant: "success" });
							})
							.catch((err) => {
								if (err.isAxiosError && err.response.status === 400) {
									MLDialog.showSnackbar("Errore, c'è già uno sprint in quelle date", { variant: "error" });
								} else {
									MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
								}
							});
					}}
					onDelete={() => {
						MLDialog.showModal(
							"Elimina sprint",
							"Sei sicuro di voler eliminare questo sprint? Una volta eliminato non potrai ripristinarlo",
							{
								positiveButton: (onClick) => (
									<Button
										children="Elimina sprint"
										variant="contained"
										color="error"
										startIcon={<IPlatformIcon name="delete_outline" />}
										onClick={onClick}
									/>
								),
								onPositiveClick: async () => {
									await deleteSprint()
										.then(() => {
											revalidateSprints();
											MLDialog.hideModal();
											setEditDialogOpen(false);
											MLDialog.showSnackbar("Sprint eliminato correttamente", { variant: "success" });
										})
										.catch((err) => {
											console.log("err", err);
										});
								},
							}
						);
					}}
				/>
			)}
		</Stack>
	);
};
export default SprintSelector;
