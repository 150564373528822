"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminExternalContentApi = exports.AdminExternalContentApiFactory = exports.AdminExternalContentApiFp = exports.AdminExternalContentApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminExternalContentApi - axios parameter creator
 * @export
 */
exports.AdminExternalContentApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"update\", \"Tenant\"]
         * @summary Create external content | Permission required: [\"update\", \"Tenant\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [title]
         * @param {string} [url]
         * @param {any} [file]
         * @param {string} [defaultImage]
         * @param {boolean} [useDefaultImage]
         * @param {string} [tagId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalContent: (tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/externalContent/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            if (title !== undefined) {
                localVarFormParams.append('title', title);
            }
            if (url !== undefined) {
                localVarFormParams.append('url', url);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            if (defaultImage !== undefined) {
                localVarFormParams.append('defaultImage', defaultImage);
            }
            if (useDefaultImage !== undefined) {
                localVarFormParams.append('useDefaultImage', useDefaultImage);
            }
            if (tagId !== undefined) {
                localVarFormParams.append('tagId', tagId);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"delete\", \"ExternalContent\"]
         * @summary Delete external content | Permission required: [\"delete\", \"ExternalContent\"]
         * @param {string} externalContentId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternalContent: (externalContentId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'externalContentId' is not null or undefined
            common_1.assertParamExists('deleteExternalContent', 'externalContentId', externalContentId);
            const localVarPath = `/admin/externalContent/{externalContentId}/`
                .replace(`{${"externalContentId"}}`, encodeURIComponent(String(externalContentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"ExternalContent\"]
         * @summary Edit external content | Permission required: [\"update\", \"ExternalContent\"]
         * @param {string} externalContentId
         * @param {string} [tenantId] tenantId
         * @param {string} [title]
         * @param {string} [url]
         * @param {any} [file]
         * @param {string} [defaultImage]
         * @param {boolean} [useDefaultImage]
         * @param {string} [tagId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editExternalContent: (externalContentId, tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'externalContentId' is not null or undefined
            common_1.assertParamExists('editExternalContent', 'externalContentId', externalContentId);
            const localVarPath = `/admin/externalContent/{externalContentId}/`
                .replace(`{${"externalContentId"}}`, encodeURIComponent(String(externalContentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            if (title !== undefined) {
                localVarFormParams.append('title', title);
            }
            if (url !== undefined) {
                localVarFormParams.append('url', url);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            if (defaultImage !== undefined) {
                localVarFormParams.append('defaultImage', defaultImage);
            }
            if (useDefaultImage !== undefined) {
                localVarFormParams.append('useDefaultImage', useDefaultImage);
            }
            if (tagId !== undefined) {
                localVarFormParams.append('tagId', tagId);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Fetch data
         * @param {string} url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchData: (url, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'url' is not null or undefined
            common_1.assertParamExists('fetchData', 'url', url);
            const localVarPath = `/admin/externalContent/fetchData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"ExternalContent\"]
         * @summary Get all tag external content | Permission required: [\"readAll\", \"ExternalContent\"]
         * @param {string} tagId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagExternalContent: (tagId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tagId' is not null or undefined
            common_1.assertParamExists('getAllTagExternalContent', 'tagId', tagId);
            const localVarPath = `/admin/tag/{tagId}/externalContent/`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminExternalContentApi - functional programming interface
 * @export
 */
exports.AdminExternalContentApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminExternalContentApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"update\", \"Tenant\"]
         * @summary Create external content | Permission required: [\"update\", \"Tenant\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [title]
         * @param {string} [url]
         * @param {any} [file]
         * @param {string} [defaultImage]
         * @param {boolean} [useDefaultImage]
         * @param {string} [tagId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalContent(tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createExternalContent(tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"delete\", \"ExternalContent\"]
         * @summary Delete external content | Permission required: [\"delete\", \"ExternalContent\"]
         * @param {string} externalContentId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternalContent(externalContentId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteExternalContent(externalContentId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"ExternalContent\"]
         * @summary Edit external content | Permission required: [\"update\", \"ExternalContent\"]
         * @param {string} externalContentId
         * @param {string} [tenantId] tenantId
         * @param {string} [title]
         * @param {string} [url]
         * @param {any} [file]
         * @param {string} [defaultImage]
         * @param {boolean} [useDefaultImage]
         * @param {string} [tagId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editExternalContent(externalContentId, tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editExternalContent(externalContentId, tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Fetch data
         * @param {string} url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchData(url, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchData(url, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"ExternalContent\"]
         * @summary Get all tag external content | Permission required: [\"readAll\", \"ExternalContent\"]
         * @param {string} tagId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagExternalContent(tagId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllTagExternalContent(tagId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminExternalContentApi - factory interface
 * @export
 */
exports.AdminExternalContentApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminExternalContentApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"update\", \"Tenant\"]
         * @summary Create external content | Permission required: [\"update\", \"Tenant\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [title]
         * @param {string} [url]
         * @param {any} [file]
         * @param {string} [defaultImage]
         * @param {boolean} [useDefaultImage]
         * @param {string} [tagId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalContent(tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options) {
            return localVarFp.createExternalContent(tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"delete\", \"ExternalContent\"]
         * @summary Delete external content | Permission required: [\"delete\", \"ExternalContent\"]
         * @param {string} externalContentId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternalContent(externalContentId, tenantId, options) {
            return localVarFp.deleteExternalContent(externalContentId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"ExternalContent\"]
         * @summary Edit external content | Permission required: [\"update\", \"ExternalContent\"]
         * @param {string} externalContentId
         * @param {string} [tenantId] tenantId
         * @param {string} [title]
         * @param {string} [url]
         * @param {any} [file]
         * @param {string} [defaultImage]
         * @param {boolean} [useDefaultImage]
         * @param {string} [tagId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editExternalContent(externalContentId, tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options) {
            return localVarFp.editExternalContent(externalContentId, tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Fetch data
         * @param {string} url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchData(url, options) {
            return localVarFp.fetchData(url, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"ExternalContent\"]
         * @summary Get all tag external content | Permission required: [\"readAll\", \"ExternalContent\"]
         * @param {string} tagId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagExternalContent(tagId, tenantId, options) {
            return localVarFp.getAllTagExternalContent(tagId, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminExternalContentApi - object-oriented interface
 * @export
 * @class AdminExternalContentApi
 * @extends {BaseAPI}
 */
class AdminExternalContentApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"update\", \"Tenant\"]
     * @summary Create external content | Permission required: [\"update\", \"Tenant\"]
     * @param {string} [tenantId] tenantId
     * @param {string} [title]
     * @param {string} [url]
     * @param {any} [file]
     * @param {string} [defaultImage]
     * @param {boolean} [useDefaultImage]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExternalContentApi
     */
    createExternalContent(tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options) {
        return exports.AdminExternalContentApiFp(this.configuration).createExternalContent(tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"delete\", \"ExternalContent\"]
     * @summary Delete external content | Permission required: [\"delete\", \"ExternalContent\"]
     * @param {string} externalContentId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExternalContentApi
     */
    deleteExternalContent(externalContentId, tenantId, options) {
        return exports.AdminExternalContentApiFp(this.configuration).deleteExternalContent(externalContentId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"ExternalContent\"]
     * @summary Edit external content | Permission required: [\"update\", \"ExternalContent\"]
     * @param {string} externalContentId
     * @param {string} [tenantId] tenantId
     * @param {string} [title]
     * @param {string} [url]
     * @param {any} [file]
     * @param {string} [defaultImage]
     * @param {boolean} [useDefaultImage]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExternalContentApi
     */
    editExternalContent(externalContentId, tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options) {
        return exports.AdminExternalContentApiFp(this.configuration).editExternalContent(externalContentId, tenantId, title, url, file, defaultImage, useDefaultImage, tagId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Fetch data
     * @param {string} url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExternalContentApi
     */
    fetchData(url, options) {
        return exports.AdminExternalContentApiFp(this.configuration).fetchData(url, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"ExternalContent\"]
     * @summary Get all tag external content | Permission required: [\"readAll\", \"ExternalContent\"]
     * @param {string} tagId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExternalContentApi
     */
    getAllTagExternalContent(tagId, tenantId, options) {
        return exports.AdminExternalContentApiFp(this.configuration).getAllTagExternalContent(tagId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminExternalContentApi = AdminExternalContentApi;
