/** @jsxImportSource @emotion/react */
import { TableCell, Typography, TableSortLabel, Grid, Box, Button } from "@mui/material";
import { useRef, useState } from "react";
import IPlatformIcon from "../../icons/IPlatformIcon";
import WhitePopperWithArrow from "../../poppers/WhitePopperWithArrow";
import type { IHeadCell, IQueryFilter } from "../AdminTable";

type Props = {
	headCell: IHeadCell;
	currentFilter: IQueryFilter;
	queryFilter: [IQueryFilter, (value: IQueryFilter) => void];
};

const TableHeaderCell: React.FC<Props> = ({ headCell, currentFilter, queryFilter }) => {
	const [popperOpen, setPopperOpen] = useState(false);

	const popperAnchor = useRef(null);
	return (
		<TableCell
			key={headCell.id}
			colSpan={headCell?.colSpan || 1}
			css={{
				border: "unset",
				width: headCell?.width || "auto",
			}}
			sortDirection={currentFilter.orderBy === headCell.id ? currentFilter.order : false}
		>
			{headCell.notSelectable || headCell.menu !== undefined ? (
				<Box
					onClick={() => {
						if (headCell.menu !== undefined) setPopperOpen(true);
					}}
					sx={{ display: "flex", alignItems: "center", cursor: headCell.menu !== undefined ? "pointer" : "unset" }}
				>
					<Typography variant="subtitle2">{headCell.label}</Typography>
					{headCell.menu !== undefined ? (
						<IPlatformIcon ref={popperAnchor} size={16} color="inherit" name={"arrow_mini_outline_down"} />
					) : null}
				</Box>
			) : (
				<TableSortLabel
					active={currentFilter.orderBy === headCell.id}
					direction={currentFilter.orderBy === headCell.id ? currentFilter.order : "asc"}
					onClick={() => {
						if (headCell.notSelectable) return;
						const isAsc = currentFilter.orderBy === headCell.id && currentFilter.order === "asc";
						queryFilter[1]({
							...currentFilter,
							order: isAsc ? "desc" : "asc",
							orderBy: headCell.id,
						});
					}}
					sx={{
						gap: 1,
						"&.Mui-active": {
							"& .MuiTableSortLabel-icon": {
								color: "black",
							},
						},
						"&:hover": {
							"& .MuiTableSortLabel-icon": {
								opacity: 1,
							},
						},
					}}
				>
					<Grid item>
						<Typography variant="subtitle2">{headCell.label}</Typography>
						{headCell?.subtitle && (
							<Typography
								css={{
									textTransform: "uppercase",
									color: "#5E5873",
									fontSize: 12,
									fontWeight: 300,
									letterSpacing: "1px",
									lineHeight: "15px",
								}}
							>
								{headCell?.subtitle}
							</Typography>
						)}
						{currentFilter.orderBy === headCell.id ? (
							<span
								css={{
									border: 0,
									clip: "rect(0 0 0 0)",
									height: 1,
									margin: -1,
									overflow: "hidden",
									padding: 0,
									position: "absolute",
									top: 20,
									width: 1,
								}}
							>
								{currentFilter.order === "desc" ? "sorted descending" : "sorted ascending"}
							</span>
						) : null}
					</Grid>
				</TableSortLabel>
			)}
			{headCell.menu !== undefined && (
				<WhitePopperWithArrow
					toggleOpen={() => {
						setPopperOpen(!popperOpen);
					}}
					open={popperOpen}
					anchorEl={popperAnchor.current}
				>
					<Box
						sx={{
							boxSizing: "border-box",
							backgroundColor: "surface.main",
							display: "flex",
							flexDirection: "column",
							padding: 1,
							borderRadius: 3,
							minWidth: 150,
						}}
					>
						{headCell.menu.map((elem, index) => {
							return (
								<Button
									key={index}
									color="secondary"
									sx={{ justifyContent: "flex-start", textAlign: "start" }}
									onClick={elem.onClick}
								>
									{elem.label}
								</Button>
							);
						})}
					</Box>
				</WhitePopperWithArrow>
			)}
		</TableCell>
	);
};

export default TableHeaderCell;
