/** @jsxImportSource @emotion/react */

import { CircularProgress, Grid, Stack, Table } from "@mui/material";
import DataTableBody from "./components/DataTableBody";
import DataTableHead from "./components/DataTableHead";
import type { DataTableProps } from "./interfaces/DataTable";

function DataTable<T>(props: DataTableProps<T>) {
	const {
		data,
		columns,
		hideTableHeader,
		stickyHeader,
		responsiveItems,
		alreadyResponsive,
		loading = false,
		collapsible = false,
		collapsibleRow,
		...otherProps
	} = props;

	return !loading ? (
		<div
			css={[
				{
					width: "100%",
					overflowX: "auto",
				},
				!!responsiveItems && {
					[`@media screen and (max-width: ${getMaxResponsiveSize()}px)`]: { overflow: "hidden" },
				},
			]}
			{...otherProps}
		>
			{renderResponsiveGrid()}
			<Table
				stickyHeader={stickyHeader}
				css={{
					"@media screen and (max-width: 1279px)": { minWidth: alreadyResponsive ? "100%" : 820 },
					[`@media screen and (max-width: ${getMaxResponsiveSize()}px)`]: { display: "none" },
				}}
			>
				{!hideTableHeader && <DataTableHead columns={columns} />}
				<DataTableBody data={data} columns={columns} collapsible={collapsible} collapsibleRow={collapsibleRow} />
			</Table>
		</div>
	) : (
		<Stack direction="row" justifyContent="center" css={{ marginTop: 48, marginBottom: 48 }}>
			<CircularProgress />
		</Stack>
	);

	function renderResponsiveGrid() {
		if (!responsiveItems) {
			return null;
		}

		let limitBp = `@media screen and (min-width: ${getMaxResponsiveSize() + 1}px)`;

		return (
			<Grid container spacing={1} css={{ marginTop: 24, [limitBp]: { display: "none" } }}>
				{data?.map((item, index: number) => {
					return Object.keys(responsiveItems)
						.map((item) => parseInt(item))
						.sort((a, b) => {
							return a - b;
						})
						.reverse()
						.map((key, index, array) => {
							const nextKey = array[index + 1];

							const breakpoint = !!nextKey
								? `@media screen and (min-width: ${nextKey + 1}px) and (max-width: ${key}px)`
								: `@media screen and (max-width: ${key}px)`;

							return (
								<Grid item xs={12} key={`${key}-${index}`} css={{ display: "none", [breakpoint]: { display: "block" } }}>
									{responsiveItems[key]?.(item)}
								</Grid>
							);
						});
				})}
			</Grid>
		);
	}

	function getMaxResponsiveSize() {
		if (!responsiveItems) {
			return 0;
		}

		return Object.keys(responsiveItems)
			.map((item) => parseInt(item))
			.sort((a, b) => {
				return a - b;
			})
			.slice(-1)[0];
	}
}

export default DataTable;
