/** @jsxImportSource @emotion/react */
import {
	alpha,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Container,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import ContentEditor from "../../../../../../../components/ContentEditor";
import Loading from "../../../../../../../components/Loading";
import NoContents from "../../../../../../../components/NoContents";
import { useProjectTeamContextSelector } from "../controllers/ProjectTeamController";

type ProjectTeamDeliverablesInteractionsProps = {};

const ProjectTeamDeliverablesInteractions: React.FC<ProjectTeamDeliverablesInteractionsProps> = (props) => {
	const theme = useTheme();

	const sprintContents = useProjectTeamContextSelector("sprintContents");
	const loadingSprintContents = useProjectTeamContextSelector("loadingSprintContents");

	const teamDeliverables = useProjectTeamContextSelector("teamDeliverables");
	const loadingTeamDeliverables = useProjectTeamContextSelector("loadingTeamDeliverables");

	return (
		<Stack spacing={2}>
			{sprintContents?.map((sprintContent) => {
				const interaction = teamDeliverables?.find(
					(deliverable) => deliverable.sprintContentId === sprintContent.id && deliverable.outdated === false
				);

				const completed = interaction?.completed;

				return (
					<Accordion key={sprintContent?.id}>
						<AccordionSummary css={[completed && { backgroundColor: alpha(theme.palette.success.main, 0.06) }]}>
							<Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center" sx={{ flex: 1 }}>
								<Typography children={sprintContent?.title} />
								<Typography
									children={completed ? "Completato" : "Non completato"}
									color={completed ? "success.main" : "text.secondary"}
									variant="body2"
									css={[completed && { fontWeight: 600 }]}
								/>
							</Stack>
						</AccordionSummary>
						<AccordionDetails>
							<Container maxWidth="md">
								<ContentEditor
									initialValue={sprintContent?.content?.body || ""}
									readOnly
									contentInteractions={teamDeliverables}
								/>
							</Container>
						</AccordionDetails>
					</Accordion>
				);
			})}

			{(loadingSprintContents || loadingTeamDeliverables) && <Loading />}

			{!loadingSprintContents && (!sprintContents || !sprintContents?.length) && (
				<Stack direction="row" justifyContent="center" sx={{ my: 6 }}>
					<NoContents
						title={`In questo sprint non c'è nessun deliverable. Puoi aggiungerne tramite il pulsante "Vedi contenuti sprint"`}
					/>
				</Stack>
			)}
		</Stack>
	);
};

export default ProjectTeamDeliverablesInteractions;
