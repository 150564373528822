import { Box, ClickAwayListener, Fade, PopperProps, SxProps, Theme } from "@mui/material";
import { useState } from "react";
import MuiArrowPopper, { PopperArrow } from "./MuiArrowPopper";

interface Props extends PopperProps {
	toggleOpen: () => void;
	children: React.ReactNode;
	arrowDisabled?: boolean;
	containerSx?: SxProps<Theme>;
}

const WhitePopperWithArrow: React.FC<Props> = ({
	open,
	anchorEl,
	children,
	toggleOpen,
	arrowDisabled,
	containerSx = {},
	...otherProps
}) => {
	const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

	return (
		<MuiArrowPopper
			open={open}
			arrow={true}
			anchorEl={anchorEl}
			placement="bottom"
			transition
			modifiers={[
				{
					name: "offset",
					enabled: true,
					options: {
						offset: [-50, 5],
					},
				},
				{
					name: "arrow",
					enabled: true,
					options: {
						element: arrowRef,
					},
				},
			]}
			sx={{
				zIndex: 1300,
			}}
			{...otherProps}
		>
			{({ TransitionProps }) => (
				<Fade {...TransitionProps} timeout={350}>
					<Box>
						<ClickAwayListener onClickAway={() => toggleOpen()}>
							<Box
								sx={{
									filter: "drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12));",
									backgroundColor: "background.paper",
									borderRadius: 3,
									...containerSx,
								}}
							>
								{!arrowDisabled && (
									<PopperArrow
										ref={(node) => {
											setArrowRef(node);
										}}
										className="MuiPopper-arrow"
									/>
								)}
								{children}
							</Box>
						</ClickAwayListener>
					</Box>
				</Fade>
			)}
		</MuiArrowPopper>
	);
};

export default WhitePopperWithArrow;
