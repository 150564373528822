"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MentorFeedbackApi = exports.MentorFeedbackApiFactory = exports.MentorFeedbackApiFp = exports.MentorFeedbackApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * MentorFeedbackApi - axios parameter creator
 * @export
 */
exports.MentorFeedbackApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"create\", \"MentorFeedback\"]
         * @summary Add admin feedback | Permission required: [\"create\", \"MentorFeedback\"]
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminFeedback: (sprintInteractionId, tenantId, editCreateMentorFeedback, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintInteractionId' is not null or undefined
            common_1.assertParamExists('addAdminFeedback', 'sprintInteractionId', sprintInteractionId);
            const localVarPath = `/admin/sprintInteraction/{sprintInteractionId}/feedback`
                .replace(`{${"sprintInteractionId"}}`, encodeURIComponent(String(sprintInteractionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateMentorFeedback, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"create\", \"MentorFeedback\"]
         * @summary Add mentor feedback | Permission required: [\"create\", \"MentorFeedback\"]
         * @param {string} id
         * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMentorFeedback: (id, editCreateMentorFeedback, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('addMentorFeedback', 'id', id);
            const localVarPath = `/sprintInteraction/{id}/feedback`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateMentorFeedback, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete admin mentor feedback
         * @param {string} feedbackId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminMentorFeedback: (feedbackId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'feedbackId' is not null or undefined
            common_1.assertParamExists('deleteAdminMentorFeedback', 'feedbackId', feedbackId);
            const localVarPath = `/admin/mentorFeedback/{feedbackId}`
                .replace(`{${"feedbackId"}}`, encodeURIComponent(String(feedbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete mentor feedback
         * @param {string} feedbackId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMentorFeedback: (feedbackId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'feedbackId' is not null or undefined
            common_1.assertParamExists('deleteMentorFeedback', 'feedbackId', feedbackId);
            const localVarPath = `/mentorFeedback/{feedbackId}`
                .replace(`{${"feedbackId"}}`, encodeURIComponent(String(feedbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit admin mentor feedback
         * @param {string} feedbackId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminMentorFeedback: (feedbackId, tenantId, editCreateMentorFeedback, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'feedbackId' is not null or undefined
            common_1.assertParamExists('editAdminMentorFeedback', 'feedbackId', feedbackId);
            const localVarPath = `/admin/mentorFeedback/{feedbackId}`
                .replace(`{${"feedbackId"}}`, encodeURIComponent(String(feedbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateMentorFeedback, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit mentor feedback
         * @param {string} feedbackId
         * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editMentorFeedback: (feedbackId, editCreateMentorFeedback, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'feedbackId' is not null or undefined
            common_1.assertParamExists('editMentorFeedback', 'feedbackId', feedbackId);
            const localVarPath = `/mentorFeedback/{feedbackId}`
                .replace(`{${"feedbackId"}}`, encodeURIComponent(String(feedbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateMentorFeedback, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all admin sprint interaction feedbacks
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminSprintInteractionFeedbacks: (sprintInteractionId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintInteractionId' is not null or undefined
            common_1.assertParamExists('getAllAdminSprintInteractionFeedbacks', 'sprintInteractionId', sprintInteractionId);
            const localVarPath = `/admin/sprintInteraction/{sprintInteractionId}/feedback`
                .replace(`{${"sprintInteractionId"}}`, encodeURIComponent(String(sprintInteractionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all sprint interaction feedbacks
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSprintInteractionFeedbacks: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAllSprintInteractionFeedbacks', 'id', id);
            const localVarPath = `/sprintInteraction/{id}/feedback`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * MentorFeedbackApi - functional programming interface
 * @export
 */
exports.MentorFeedbackApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.MentorFeedbackApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"MentorFeedback\"]
         * @summary Add admin feedback | Permission required: [\"create\", \"MentorFeedback\"]
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminFeedback(sprintInteractionId, tenantId, editCreateMentorFeedback, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addAdminFeedback(sprintInteractionId, tenantId, editCreateMentorFeedback, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"create\", \"MentorFeedback\"]
         * @summary Add mentor feedback | Permission required: [\"create\", \"MentorFeedback\"]
         * @param {string} id
         * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMentorFeedback(id, editCreateMentorFeedback, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addMentorFeedback(id, editCreateMentorFeedback, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete admin mentor feedback
         * @param {string} feedbackId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminMentorFeedback(feedbackId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteAdminMentorFeedback(feedbackId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete mentor feedback
         * @param {string} feedbackId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMentorFeedback(feedbackId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteMentorFeedback(feedbackId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit admin mentor feedback
         * @param {string} feedbackId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminMentorFeedback(feedbackId, tenantId, editCreateMentorFeedback, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editAdminMentorFeedback(feedbackId, tenantId, editCreateMentorFeedback, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit mentor feedback
         * @param {string} feedbackId
         * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editMentorFeedback(feedbackId, editCreateMentorFeedback, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editMentorFeedback(feedbackId, editCreateMentorFeedback, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all admin sprint interaction feedbacks
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminSprintInteractionFeedbacks(sprintInteractionId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllAdminSprintInteractionFeedbacks(sprintInteractionId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all sprint interaction feedbacks
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSprintInteractionFeedbacks(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllSprintInteractionFeedbacks(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * MentorFeedbackApi - factory interface
 * @export
 */
exports.MentorFeedbackApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.MentorFeedbackApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"MentorFeedback\"]
         * @summary Add admin feedback | Permission required: [\"create\", \"MentorFeedback\"]
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminFeedback(sprintInteractionId, tenantId, editCreateMentorFeedback, options) {
            return localVarFp.addAdminFeedback(sprintInteractionId, tenantId, editCreateMentorFeedback, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"create\", \"MentorFeedback\"]
         * @summary Add mentor feedback | Permission required: [\"create\", \"MentorFeedback\"]
         * @param {string} id
         * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMentorFeedback(id, editCreateMentorFeedback, options) {
            return localVarFp.addMentorFeedback(id, editCreateMentorFeedback, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete admin mentor feedback
         * @param {string} feedbackId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminMentorFeedback(feedbackId, tenantId, options) {
            return localVarFp.deleteAdminMentorFeedback(feedbackId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete mentor feedback
         * @param {string} feedbackId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMentorFeedback(feedbackId, options) {
            return localVarFp.deleteMentorFeedback(feedbackId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit admin mentor feedback
         * @param {string} feedbackId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminMentorFeedback(feedbackId, tenantId, editCreateMentorFeedback, options) {
            return localVarFp.editAdminMentorFeedback(feedbackId, tenantId, editCreateMentorFeedback, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit mentor feedback
         * @param {string} feedbackId
         * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editMentorFeedback(feedbackId, editCreateMentorFeedback, options) {
            return localVarFp.editMentorFeedback(feedbackId, editCreateMentorFeedback, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all admin sprint interaction feedbacks
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminSprintInteractionFeedbacks(sprintInteractionId, tenantId, options) {
            return localVarFp.getAllAdminSprintInteractionFeedbacks(sprintInteractionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all sprint interaction feedbacks
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSprintInteractionFeedbacks(id, options) {
            return localVarFp.getAllSprintInteractionFeedbacks(id, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * MentorFeedbackApi - object-oriented interface
 * @export
 * @class MentorFeedbackApi
 * @extends {BaseAPI}
 */
class MentorFeedbackApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"create\", \"MentorFeedback\"]
     * @summary Add admin feedback | Permission required: [\"create\", \"MentorFeedback\"]
     * @param {string} sprintInteractionId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorFeedbackApi
     */
    addAdminFeedback(sprintInteractionId, tenantId, editCreateMentorFeedback, options) {
        return exports.MentorFeedbackApiFp(this.configuration).addAdminFeedback(sprintInteractionId, tenantId, editCreateMentorFeedback, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"create\", \"MentorFeedback\"]
     * @summary Add mentor feedback | Permission required: [\"create\", \"MentorFeedback\"]
     * @param {string} id
     * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorFeedbackApi
     */
    addMentorFeedback(id, editCreateMentorFeedback, options) {
        return exports.MentorFeedbackApiFp(this.configuration).addMentorFeedback(id, editCreateMentorFeedback, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete admin mentor feedback
     * @param {string} feedbackId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorFeedbackApi
     */
    deleteAdminMentorFeedback(feedbackId, tenantId, options) {
        return exports.MentorFeedbackApiFp(this.configuration).deleteAdminMentorFeedback(feedbackId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete mentor feedback
     * @param {string} feedbackId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorFeedbackApi
     */
    deleteMentorFeedback(feedbackId, options) {
        return exports.MentorFeedbackApiFp(this.configuration).deleteMentorFeedback(feedbackId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit admin mentor feedback
     * @param {string} feedbackId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorFeedbackApi
     */
    editAdminMentorFeedback(feedbackId, tenantId, editCreateMentorFeedback, options) {
        return exports.MentorFeedbackApiFp(this.configuration).editAdminMentorFeedback(feedbackId, tenantId, editCreateMentorFeedback, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit mentor feedback
     * @param {string} feedbackId
     * @param {EditCreateMentorFeedback} [editCreateMentorFeedback]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorFeedbackApi
     */
    editMentorFeedback(feedbackId, editCreateMentorFeedback, options) {
        return exports.MentorFeedbackApiFp(this.configuration).editMentorFeedback(feedbackId, editCreateMentorFeedback, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all admin sprint interaction feedbacks
     * @param {string} sprintInteractionId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorFeedbackApi
     */
    getAllAdminSprintInteractionFeedbacks(sprintInteractionId, tenantId, options) {
        return exports.MentorFeedbackApiFp(this.configuration).getAllAdminSprintInteractionFeedbacks(sprintInteractionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all sprint interaction feedbacks
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorFeedbackApi
     */
    getAllSprintInteractionFeedbacks(id, options) {
        return exports.MentorFeedbackApiFp(this.configuration).getAllSprintInteractionFeedbacks(id, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.MentorFeedbackApi = MentorFeedbackApi;
