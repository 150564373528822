import { Box, Stack, Toolbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import { DRAWER_WIDTH } from "../constants/ScaffoldDrawerConstants";

type ScaffoldBodyProps = {};

const ScaffoldBody: React.FC<ScaffoldBodyProps> = () => {
	return (
		<Stack component="main" alignItems={"flex-end"}>
			<Toolbar />
			<Box sx={{ width: `calc(100% - ${DRAWER_WIDTH}px)` }}>
				<Outlet />
			</Box>
		</Stack>
	);
};
export default ScaffoldBody;
