/** @jsxImportSource @emotion/react */

import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDimensions } from "../hooks/withDimensions";

const dimensions = {
	appbar: {
		standard: 0,
		contained: 0,
	},
	drawer: 272,
};

type EllipseWrapperProps = {
	children: React.ReactNode;
	includeAppbar?: boolean;
	background?: {
		image: string;
		opacity?: number;
	};
	additionalSpace?: {
		top?: number;
		bottom?: number;
	};
	considerDrawer?: boolean;
};

const fixedWidth = 120;
const ellipseFixedHeight = 4;

const EllipseWrapper: React.FC<EllipseWrapperProps> = (props) => {
	const { children, background, additionalSpace, includeAppbar = true, considerDrawer } = props;

	const isMobile = useMediaQuery("(max-width: 959px)");

	const drawerWidth = considerDrawer && !isMobile ? 272 : 0;

	const { image = null, opacity = 0.5 } = background || {};
	let { top: additionalSpaceTop = 0, bottom: additionalSpaceBottom = 0 } = additionalSpace || {};

	if (includeAppbar) {
		additionalSpaceTop += dimensions.appbar.standard;
	}

	const theme = useTheme();

	const contentRef = useRef<HTMLDivElement>(null);
	const svgRef = useRef<SVGSVGElement>(null);

	const windowDimensions = useDimensions()[0];

	const [rectHeight, setRectHeight] = useState(0);

	const updateHeights = () => {
		if (contentRef?.current?.clientHeight && contentRef?.current?.clientWidth) {
			const calculatedHeight =
				((contentRef?.current?.clientHeight + additionalSpaceTop + additionalSpaceBottom) /
					(contentRef?.current?.clientWidth || 1)) *
				fixedWidth;

			setRectHeight(calculatedHeight);
		}
	};

	useEffect(updateHeights, []);
	// useEffect(updateHeights, [dimensions]);
	useEffect(updateHeights, [
		windowDimensions,
		contentRef?.current?.clientHeight || 0,
		additionalSpaceTop,
		additionalSpaceBottom,
		children,
	]);

	return (
		<Grid container css={{ position: "relative", zIndex: 30 }}>
			<div
				ref={contentRef}
				css={{
					marginTop: additionalSpaceTop,
					overflow: "hidden",
					width: "100%",
					position: "relative",
				}}
			>
				{children}
			</div>
			<svg
				width={fixedWidth}
				height={(rectHeight || 0) + ellipseFixedHeight}
				viewBox={`0 0 ${fixedWidth} ${(rectHeight || 0) + ellipseFixedHeight}`}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				preserveAspectRatio="none"
				ref={svgRef}
				css={{
					width: "100%",
					height: "auto",
					position: "absolute",
					// top: 72,
					left: 0,
					right: 0,
					zIndex: -1,
					"@media screen and (max-width: 959px)": {
						// top: 56
					},
				}}
			>
				<defs>
					<linearGradient id="linearGrad" x1="0%" y1="0%" x2="100%" y2="0%">
						<stop
							offset={image ? "20%" : "0%"}
							// offset="44.6%"
							style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }}
						/>
						<stop
							offset="100%"
							// offset="105.77%"
							style={{ stopColor: theme.palette.primary.dark, stopOpacity: 0 }}
							// style={{ stopColor: "#094074", stopOpacity: 1 }}
						/>
					</linearGradient>
				</defs>

				{renderEllipse(theme.palette.primary.main)}

				{image && (
					<React.Fragment>
						<clipPath id="mask" css={{ width: "100%", height: "100%" }}>
							{renderEllipse("url(#linearGrad)")}
						</clipPath>
						<image
							xlinkHref={image}
							clipPath="url(#mask)"
							// preserveAspectRatio="none"
							preserveAspectRatio="xMidYMid slice"
							css={{
								width: "100%",
								height: "100%",
								opacity: image,
							}}
						/>
					</React.Fragment>
				)}

				{renderEllipse("url(#linearGrad)")}
			</svg>
		</Grid>
	);

	function renderEllipse(fill: string) {
		return (
			<path
				transform={`translate(0,${rectHeight})`}
				d={
					`M${fixedWidth} 0 L${fixedWidth} ${-rectHeight} L0 ${-rectHeight} L0 0` +
					`V0.0494832C9.4543 1.03257 34.6452 2.99832 59.8011 3H59.8655C85.0217 2.99832 110.435 1.03257 120 0.0494832V0H0Z`
				}
				fill={fill}
				// fill={"#ff0000"}
			/>
		);
	}
};

export default EllipseWrapper;
