import create from "zustand";
import type { IPlatformIconNames } from "../../icons/IPlatformIcon";

type TopActionType = {
	visible: boolean;
	disabled: boolean;
	title: string;
	icon: IPlatformIconNames;
	onClick: () => any | Promise<any>;
	updateTopAction: (newTopAction: Omit<TopActionType, "updateTopAction" | "removeTopAction">) => void;
	removeTopAction: () => void;
};

// export const topActionAtom = atom<TopActionType>({
// 	title: "",
// 	icon: "success",
// 	visible: false,
// 	disabled: false,
// 	onClick: () => {},
// });

// export const updateTopActionAtom = withImmer(topActionAtom);

export const useTopAction = create<TopActionType>((set) => ({
	title: "",
	icon: "success",
	visible: false,
	disabled: false,
	onClick: () => {},
	updateTopAction: (newTopAction) => set((state) => ({ ...state, ...newTopAction })),
	removeTopAction: () => set((state) => ({ ...state, visible: false, disabled: true })),
}));
