import { Stack, Tab, Tabs, Typography } from "@mui/material";
import { useProjectTeamContextSelector } from "../controllers/ProjectTeamController";

type ProjectTeamSprintSelectorProps = {};

const ProjectTeamSprintSelector: React.FC<ProjectTeamSprintSelectorProps> = (props) => {
	const selectedSprint = useProjectTeamContextSelector("selectedSprint");
	const setSelectedSprint = useProjectTeamContextSelector("setSelectedSprint");

	const sprints = useProjectTeamContextSelector("sprints");
	const loadingSprints = useProjectTeamContextSelector("loadingSprints");

	return (
		<Stack>
			{!loadingSprints && Boolean(sprints) && sprints?.length !== 0 && (
				<Tabs
					value={selectedSprint?.id}
					onChange={(event, newValue) => {
						const sprint = sprints?.find((sprint) => sprint.id === newValue);
						if (Boolean(sprint)) {
							setSelectedSprint(sprint);
						}
					}}
				>
					{sprints?.map((sprint, index) => {
						return <Tab label={`Sprint ${index + 1}`} value={sprint?.id} key={sprint?.id} />;
					})}
				</Tabs>
			)}
			{!loadingSprints && (!Boolean(sprints) || (Boolean(sprints) && sprints?.length === 0)) && (
				<Typography children="Nessuno sprint" />
			)}
		</Stack>
	);
};

export default ProjectTeamSprintSelector;
