"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminTeamApi = exports.AdminTeamApiFactory = exports.AdminTeamApiFp = exports.AdminTeamApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminTeamApi - axios parameter creator
 * @export
 */
exports.AdminTeamApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"update\", \"Team\"]
         * @summary Add user to team | Permission required: [\"update\", \"Team\"]
         * @param {string} teamId
         * @param {InputAddUserToTeam} inputAddUserToTeam
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToTeam: (teamId, inputAddUserToTeam, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'teamId' is not null or undefined
            common_1.assertParamExists('addUserToTeam', 'teamId', teamId);
            // verify required parameter 'inputAddUserToTeam' is not null or undefined
            common_1.assertParamExists('addUserToTeam', 'inputAddUserToTeam', inputAddUserToTeam);
            const localVarPath = `/admin/team/{teamId}/user/`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputAddUserToTeam, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"create\", \"Team\"]
         * @summary Create team | Permission required: [\"create\", \"Team\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateTeam} [editCreateTeam]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam: (projectId, tenantId, editCreateTeam, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            common_1.assertParamExists('createTeam', 'projectId', projectId);
            const localVarPath = `/admin/project/{projectId}/team/`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateTeam, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"delete\", \"Team\"]
         * @summary Delete team | Permission required: [\"delete\", \"Team\"]
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam: (teamId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'teamId' is not null or undefined
            common_1.assertParamExists('deleteTeam', 'teamId', teamId);
            const localVarPath = `/admin/team/{teamId}/`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Team\"]
         * @summary Edit team | Permission required: [\"update\", \"Team\"]
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateTeam} [editCreateTeam]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTeam: (teamId, tenantId, editCreateTeam, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'teamId' is not null or undefined
            common_1.assertParamExists('editTeam', 'teamId', teamId);
            const localVarPath = `/admin/team/{teamId}/`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateTeam, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit team mentors
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {InputEditTeamMentors} [inputEditTeamMentors]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTeamMentors: (teamId, tenantId, inputEditTeamMentors, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'teamId' is not null or undefined
            common_1.assertParamExists('editTeamMentors', 'teamId', teamId);
            const localVarPath = `/admin/team/{teamId}/mentor/`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputEditTeamMentors, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Team\"]
         * @summary Get admin team members | Permission required: [\"readOne\", \"Team\"]
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamMembers: (teamId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'teamId' is not null or undefined
            common_1.assertParamExists('getAdminTeamMembers', 'teamId', teamId);
            const localVarPath = `/admin/team/{teamId}/member/`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all participants with data
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParticipantsWithData: (teamId, tenantId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'teamId' is not null or undefined
            common_1.assertParamExists('getAllParticipantsWithData', 'teamId', teamId);
            const localVarPath = `/admin/team/{teamId}/member/withData`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Tenant\"]
         * @summary Get all project teams | Permission required: [\"readAll\", \"Tenant\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectTeams: (projectId, tenantId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            common_1.assertParamExists('getAllProjectTeams', 'projectId', projectId);
            const localVarPath = `/admin/project/{projectId}/team/`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminTeamApi - functional programming interface
 * @export
 */
exports.AdminTeamApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminTeamApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"update\", \"Team\"]
         * @summary Add user to team | Permission required: [\"update\", \"Team\"]
         * @param {string} teamId
         * @param {InputAddUserToTeam} inputAddUserToTeam
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToTeam(teamId, inputAddUserToTeam, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addUserToTeam(teamId, inputAddUserToTeam, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"create\", \"Team\"]
         * @summary Create team | Permission required: [\"create\", \"Team\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateTeam} [editCreateTeam]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam(projectId, tenantId, editCreateTeam, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createTeam(projectId, tenantId, editCreateTeam, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"delete\", \"Team\"]
         * @summary Delete team | Permission required: [\"delete\", \"Team\"]
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam(teamId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteTeam(teamId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Team\"]
         * @summary Edit team | Permission required: [\"update\", \"Team\"]
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateTeam} [editCreateTeam]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTeam(teamId, tenantId, editCreateTeam, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editTeam(teamId, tenantId, editCreateTeam, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit team mentors
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {InputEditTeamMentors} [inputEditTeamMentors]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTeamMentors(teamId, tenantId, inputEditTeamMentors, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editTeamMentors(teamId, tenantId, inputEditTeamMentors, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Team\"]
         * @summary Get admin team members | Permission required: [\"readOne\", \"Team\"]
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamMembers(teamId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminTeamMembers(teamId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all participants with data
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParticipantsWithData(teamId, tenantId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllParticipantsWithData(teamId, tenantId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Tenant\"]
         * @summary Get all project teams | Permission required: [\"readAll\", \"Tenant\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectTeams(projectId, tenantId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllProjectTeams(projectId, tenantId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminTeamApi - factory interface
 * @export
 */
exports.AdminTeamApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminTeamApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"update\", \"Team\"]
         * @summary Add user to team | Permission required: [\"update\", \"Team\"]
         * @param {string} teamId
         * @param {InputAddUserToTeam} inputAddUserToTeam
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToTeam(teamId, inputAddUserToTeam, tenantId, options) {
            return localVarFp.addUserToTeam(teamId, inputAddUserToTeam, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"create\", \"Team\"]
         * @summary Create team | Permission required: [\"create\", \"Team\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateTeam} [editCreateTeam]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam(projectId, tenantId, editCreateTeam, options) {
            return localVarFp.createTeam(projectId, tenantId, editCreateTeam, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"delete\", \"Team\"]
         * @summary Delete team | Permission required: [\"delete\", \"Team\"]
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam(teamId, tenantId, options) {
            return localVarFp.deleteTeam(teamId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Team\"]
         * @summary Edit team | Permission required: [\"update\", \"Team\"]
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateTeam} [editCreateTeam]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTeam(teamId, tenantId, editCreateTeam, options) {
            return localVarFp.editTeam(teamId, tenantId, editCreateTeam, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit team mentors
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {InputEditTeamMentors} [inputEditTeamMentors]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTeamMentors(teamId, tenantId, inputEditTeamMentors, options) {
            return localVarFp.editTeamMentors(teamId, tenantId, inputEditTeamMentors, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Team\"]
         * @summary Get admin team members | Permission required: [\"readOne\", \"Team\"]
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamMembers(teamId, tenantId, options) {
            return localVarFp.getAdminTeamMembers(teamId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all participants with data
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParticipantsWithData(teamId, tenantId, filter, options) {
            return localVarFp.getAllParticipantsWithData(teamId, tenantId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Tenant\"]
         * @summary Get all project teams | Permission required: [\"readAll\", \"Tenant\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectTeams(projectId, tenantId, filter, options) {
            return localVarFp.getAllProjectTeams(projectId, tenantId, filter, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminTeamApi - object-oriented interface
 * @export
 * @class AdminTeamApi
 * @extends {BaseAPI}
 */
class AdminTeamApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"update\", \"Team\"]
     * @summary Add user to team | Permission required: [\"update\", \"Team\"]
     * @param {string} teamId
     * @param {InputAddUserToTeam} inputAddUserToTeam
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    addUserToTeam(teamId, inputAddUserToTeam, tenantId, options) {
        return exports.AdminTeamApiFp(this.configuration).addUserToTeam(teamId, inputAddUserToTeam, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"create\", \"Team\"]
     * @summary Create team | Permission required: [\"create\", \"Team\"]
     * @param {string} projectId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateTeam} [editCreateTeam]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    createTeam(projectId, tenantId, editCreateTeam, options) {
        return exports.AdminTeamApiFp(this.configuration).createTeam(projectId, tenantId, editCreateTeam, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"delete\", \"Team\"]
     * @summary Delete team | Permission required: [\"delete\", \"Team\"]
     * @param {string} teamId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    deleteTeam(teamId, tenantId, options) {
        return exports.AdminTeamApiFp(this.configuration).deleteTeam(teamId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Team\"]
     * @summary Edit team | Permission required: [\"update\", \"Team\"]
     * @param {string} teamId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateTeam} [editCreateTeam]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    editTeam(teamId, tenantId, editCreateTeam, options) {
        return exports.AdminTeamApiFp(this.configuration).editTeam(teamId, tenantId, editCreateTeam, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit team mentors
     * @param {string} teamId
     * @param {string} [tenantId] tenantId
     * @param {InputEditTeamMentors} [inputEditTeamMentors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    editTeamMentors(teamId, tenantId, inputEditTeamMentors, options) {
        return exports.AdminTeamApiFp(this.configuration).editTeamMentors(teamId, tenantId, inputEditTeamMentors, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Team\"]
     * @summary Get admin team members | Permission required: [\"readOne\", \"Team\"]
     * @param {string} teamId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    getAdminTeamMembers(teamId, tenantId, options) {
        return exports.AdminTeamApiFp(this.configuration).getAdminTeamMembers(teamId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all participants with data
     * @param {string} teamId
     * @param {string} [tenantId] tenantId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    getAllParticipantsWithData(teamId, tenantId, filter, options) {
        return exports.AdminTeamApiFp(this.configuration).getAllParticipantsWithData(teamId, tenantId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Tenant\"]
     * @summary Get all project teams | Permission required: [\"readAll\", \"Tenant\"]
     * @param {string} projectId
     * @param {string} [tenantId] tenantId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    getAllProjectTeams(projectId, tenantId, filter, options) {
        return exports.AdminTeamApiFp(this.configuration).getAllProjectTeams(projectId, tenantId, filter, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminTeamApi = AdminTeamApi;
