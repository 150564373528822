import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React from "react";

interface Props extends ButtonProps {
	icon: React.ReactNode;
	loading: boolean;
}

const LoadingIconButton = React.forwardRef<any, Props>((props, ref) => {
	const { icon, children, loading, disabled, ...otherProps } = { ...props };
	return (
		<Button
			ref={ref}
			startIcon={
				loading ? (
					<CircularProgress
						size={props.size === "small" ? 16 : props.size === "medium" ? 18 : 20}
						sx={{ color: "inherit", flexShrink: 0 }}
					/>
				) : (
					icon
				)
			}
			disabled={loading || disabled}
			{...otherProps}
		>
			{/* <Box sx={{ position: "relative" }}>
				<Fade in={loading} unmountOnExit>
					<CircularProgress size={24} sx={{ color: "inherit", position: "absolute", left: 0 }} />
				</Fade>
				<Fade in={!loading}>
					<Box>{icon}</Box>
				</Fade>
			</Box> */}
			{children}
		</Button>
	);
});

export default LoadingIconButton;
