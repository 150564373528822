/** @jsxImportSource @emotion/react */

import { OptionsObject, SnackbarProvider, SnackbarProviderProps, withSnackbar, WithSnackbarProps } from "notistack";
import * as React from "react";
import { Fragment } from "react";
import LoadingTextButton from "../buttons/LoadingTextButton";
import WhiteModal from "../WhiteModal";
import isFunction from "lodash/isFunction";
import IPlatformIcon from "../icons/IPlatformIcon";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
// import SnackMessage from "./WrappedSnackbar"

export class MLDialogProvider extends React.Component<SnackbarProviderProps> {
	render() {
		return (
			<SnackbarProvider {...this.props}>
				{/* eslint-disable-next-line react/jsx-pascal-case*/}
				<__MLDialog />
				{this.props.children}
			</SnackbarProvider>
		);
	}
}

export interface MLDialogParams {
	// title?: string;
	// message?: string | any
	onPositiveClick?: () => Promise<boolean | void> | boolean | void; // return true for not closing modal
	onNegativeClick?: () => boolean | void; // return true for not closing modal
	positiveText?: string;
	negativeText?: string;
	positiveButton?: ((onClick: any, loading: boolean) => React.ReactNode) | React.ReactNode;
	negativeButton?: ((onClick: any) => React.ReactNode) | React.ReactNode;
	icon?: React.ReactNode;
	contentCentered?: boolean;
	maxWidth?: number;
	fullWidth?: boolean;
	fullHeight?: boolean;
}

export default class MLDialog extends React.Component<WithSnackbarProps> {
	state = MLDialog.defaultState;

	static __uniqueRef: MLDialog;

	static defaultState: {
		open: boolean;
		title: string;
		message: string;
		positiveLoading: boolean;
		onPositiveClick: () => Promise<boolean | void> | boolean | void;
		positiveText: string;
		negativeText: string;
		onNegativeClick: () => boolean | void | null;
		positiveButton: ((onClick: any, loading?: boolean) => React.ReactNode) | React.ReactNode | undefined;
		negativeButton: ((onClick: any) => React.ReactNode) | React.ReactNode | undefined;
		icon?: React.ReactNode;
		contentCentered?: boolean;
		maxWidth?: number;
		fullWidth?: boolean;
		fullHeight?: boolean;
	} = {
		open: false,
		title: "",
		message: "",
		positiveLoading: false,
		onPositiveClick: () => false,
		// TODO se localizzo queste due stringhe crasha, Cannot read property 'strings' of undefined perchè non legge R
		positiveText: "Ok",
		negativeText: "Annulla",
		onNegativeClick: () => false,
		positiveButton: null,
		negativeButton: null,
	};

	static showModal(title: String, message: string | any, params?: MLDialogParams) {
		MLDialog.__uniqueRef.showModal(title, message, params);
	}

	static hideModal() {
		MLDialog.__uniqueRef.hideModal();
	}

	static showSnackbar(message: String, options?: OptionsObject) {
		MLDialog.__uniqueRef.showSnackbar(message, options);
	}

	static hideSnackbar(key: string | number | undefined) {
		MLDialog.__uniqueRef.hideSnackbar(key);
	}

	constructor(props: WithSnackbarProps) {
		super(props);
		MLDialog.__uniqueRef = this;
	}

	showModal(title: String, message: string | any, params?: MLDialogParams) {
		this.setState(
			Object.assign({}, MLDialog.defaultState, params, {
				title: title,
				message: message,
				open: true,
			})
		);
	}

	hideModal = () => {
		this.setState({ open: false });
	};

	showSnackbar(message: String, options?: OptionsObject) {
		const { action, ...otherOptions } = { ...options };
		this.props.enqueueSnackbar(message, {
			anchorOrigin: { horizontal: "right", vertical: "bottom" },
			action: (key) => {
				return (
					action && (
						<Fragment>
							<Divider orientation="vertical" sx={{ mr: 1, borderColor: "rgba(255, 255, 255, 0.38)", height: 32 }} />
							{action instanceof Function ? action(key) : action}
						</Fragment>
					)
				);
			},
			...otherOptions,
		});
	}

	hideSnackbar(key: string | number | undefined) {
		this.props.closeSnackbar(key);
	}

	onPositiveClick = async () => {
		if (this.state.onPositiveClick) {
			const result: any = this.state.onPositiveClick();
			if (result?.then) {
				this.setState((state) => ({ ...state, positiveLoading: true }));
				const response = await result;
				this.setState((state) => ({ ...state, positiveLoading: false }));
				if (response === true) {
					return;
				}
			}
			if (result === true) {
				return;
			}
		}
		this.hideModal();
	};

	onNegativeClick = () => {
		if (this.state.onNegativeClick) {
			const result: any = this.state.onNegativeClick();
			if (result === true) {
				return;
			}
		}
		this.hideModal();
	};

	render() {
		return (
			<WhiteModal
				keepMounted={false}
				open={this.state.open}
				onClose={this.state.positiveLoading ? () => {} : this.hideModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				css={{
					zIndex: 2_000_000,
				}}
				containerSx={{
					borderRadius: 6,
					maxWidth: { xs: "100%", md: this.state.maxWidth },
					width: this.state.fullWidth ? "100%" : "unset",
					height: this.state.fullHeight ? "100%" : "unset",
				}}
			>
				<React.Fragment>
					{this.state.icon && (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: this.state.contentCentered ? "center" : "start",
								paddingBottom: 6,
							}}
						>
							{this.state.icon}
						</Box>
					)}
					<Typography
						variant="subtitle1"
						css={{ "@media only screen and (max-width:500px)": { paddingLeft: 0, paddingRight: 0 } }}
						id="alert-dialog-title"
						children={this.state.title}
						sx={{ marginBottom: 4, textAlign: this.state.contentCentered ? "center" : "start" }}
					/>
					{typeof this.state.message === "string" ? (
						<Typography
							variant="body1"
							color="GrayText"
							id="alert-dialog-description"
							children={this.state.message}
							sx={{ textAlign: this.state.contentCentered ? "center" : "start" }}
						/>
					) : (
						this.state.message
					)}

					<DialogActions
						sx={{
							marginTop: 10,
							pointerEvents: this.state.positiveLoading ? "none" : undefined,
							px: 0,
						}}
					>
						<Stack sx={{ flexShrink: 0, width: "100%" }} gap={2} direction="row" justifyContent="space-between">
							{this.state.negativeButton ? (
								isFunction(this.state.negativeButton) ? (
									this.state.negativeButton(this.onNegativeClick)
								) : (
									this.state.negativeButton
								)
							) : (
								<Button
									variant="text"
									color="primary"
									size="medium"
									disabled={this.state.positiveLoading}
									children={this.state.negativeText}
									onClick={this.onNegativeClick}
									startIcon={<IPlatformIcon name="close" color="inherit" />}
								/>
							)}
							{this.state.positiveButton ? (
								isFunction(this.state.positiveButton) ? (
									this.state.positiveButton(this.onPositiveClick, this.state.positiveLoading)
								) : (
									this.state.positiveButton
								)
							) : (
								<LoadingTextButton
									loading={this.state.positiveLoading}
									variant="text"
									color="primary"
									size="medium"
									children={this.state.positiveText}
									onClick={this.onPositiveClick}
								/>
							)}
						</Stack>
					</DialogActions>
				</React.Fragment>
			</WhiteModal>
		);
	}
}

const __MLDialog = withSnackbar(MLDialog);
