import { AdminProjectApi, AdminSprintApi } from "innovation-api-manager";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { createContext, useContextSelector } from "use-context-selector";
import { useApi, useFetch } from "../../../../../../../hooks/api";
import { useAccount } from "../../../../../../../hooks/useAccount";

export function useSprintFeedbacksController() {
	const { tenantId, projectId } = useParams();

	const adminProjectApi = useApi(AdminProjectApi);
	const adminSprintApi = useApi(AdminSprintApi);

	const { data: me } = useAccount();
	const readOnly = me?.role === "ReadOnlyTenantManager";

	const {
		data: feedbackSummary,
		loading: loadingFeedbackSummary,
		error: errorFeedbackSummary,
		revalidate: revalidateFeedbackSummary,
	} = useFetch(adminProjectApi.getAdminFeedbackSummary, projectId as string, "", tenantId as string);

	const {
		data: missingFeedbacks,
		loading: loadingMissingFeedbacks,
		error: errorMissingFeedbacks,
		revalidate: revalidateMissingFeedbacks,
	} = useFetch(adminProjectApi.getMissingFeedbacks, projectId as string, tenantId as string);

	const {
		data: sprints,
		loading: loadingSprints,
		error: errorSprints,
		revalidate: revalidateSprints,
	} = useFetch(adminSprintApi.getAllProjectSprints, projectId as string, tenantId as string);

	return {
		feedbackSummary,
		loadingFeedbackSummary,
		errorFeedbackSummary,
		revalidateFeedbackSummary,
		missingFeedbacks,
		loadingMissingFeedbacks,
		errorMissingFeedbacks,
		revalidateMissingFeedbacks,
		sprints,
		loadingSprints,
		readOnly,
	};
}

type SprintFeedbacksContextType = ReturnType<typeof useSprintFeedbacksController>;

const SprintFeedbacksContext = createContext<SprintFeedbacksContextType | undefined>(undefined);

export const SprintFeedbacksContextProvider: React.FC<{
	sprintFeedbacksController: SprintFeedbacksContextType;
	children: React.ReactNode;
}> = ({ children, sprintFeedbacksController }) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoChildren = useMemo(() => children, []);
	return (
		<SprintFeedbacksContext.Provider value={sprintFeedbacksController}>{memoChildren}</SprintFeedbacksContext.Provider>
	);
};

export function useSprintFeedbacksContextSelector<T extends keyof SprintFeedbacksContextType>(
	field: T
): SprintFeedbacksContextType[T] {
	return useContextSelector(SprintFeedbacksContext, (v) => v![field]);
}

export function useSprintFeedbacksContextUnoptimized() {
	const context = useContextSelector(SprintFeedbacksContext, (v) => v);

	if (context === undefined) {
		throw new Error("Trying to use context outside of provider");
	}

	return context;
}
