import { AdminProjectApi } from "innovation-api-manager";
import { DateTime } from "luxon";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createContext, useContextSelector } from "use-context-selector";
import { useImmer } from "use-immer";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { apiErrorParser, useApi, useFetch } from "../../../../../../../hooks/api";
import { useAccount } from "../../../../../../../hooks/useAccount";

type TemporaryProjectType = {
	name: string;
	hasClosingDate?: boolean;
	isVisible?: boolean;
	openingDate?: string;
	closingDate?: string;
};

export function useProjectInfoController() {
	const { tenantId, projectId } = useParams();
	const navigate = useNavigate();

	const adminProjectApi = useApi(AdminProjectApi);

	const { data: me } = useAccount();
	const readOnly = me?.role === "ReadOnlyTenantManager";

	const { data, loading, error, revalidate } = useFetch(
		adminProjectApi.getAdminProjectById,
		projectId as string,
		tenantId as string,
		{
			skip: !projectId || !tenantId,
		}
	);

	const [project, setProject] = useImmer<TemporaryProjectType>({
		name: data?.name,
		hasClosingDate: data?.datesEnabled ?? false,
		isVisible: data?.isVisible,
		openingDate: data?.openingDate ?? DateTime.now().toISO(),
		closingDate: data?.openingDate ?? DateTime.now().toISO(),
	} as TemporaryProjectType);

	const updateProject = async () => {
		try {
			await adminProjectApi.editProject(projectId as string, tenantId as string, {
				...project,
				openingDate: project.hasClosingDate ? project.openingDate : (null as unknown as string),
				closingDate: project.hasClosingDate ? project.closingDate : (null as unknown as string),
				datesEnabled: project.hasClosingDate,
			});
			revalidate();
		} catch (err: any) {
			MLDialog.showSnackbar(err, { variant: "error" });
			revalidate();
		}
	};

	const deleteProject = () => {
		adminProjectApi
			.deleteProject(projectId as string, tenantId)
			.then((res) => {
				navigate("../../projects");
			})
			.catch((err) => {
				MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
			});
	};

	useEffect(() => {
		if (!data) {
			return;
		}
		setProject((project) => {
			project.name = data.name;
			project.hasClosingDate = data.datesEnabled;
			project.isVisible = data.isVisible;
			project.openingDate = data.openingDate ?? DateTime.now().toISO();
			project.closingDate = data.closingDate ?? DateTime.now().toISO();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return {
		project,
		setProject,
		data,
		loading,
		revalidate,
		error,
		updateProject,
		deleteProject,
		readOnly,
	};
}

type ProjectInfoContextType = ReturnType<typeof useProjectInfoController>;

const ProjectInfoContext = createContext<ProjectInfoContextType | undefined>(undefined);

export const ProjectInfoContextProvider: React.FC<{
	ProjectInfoController: ProjectInfoContextType;
	children: React.ReactNode;
}> = ({ children, ProjectInfoController }) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoChildren = useMemo(() => children, []);
	return <ProjectInfoContext.Provider value={ProjectInfoController}>{memoChildren}</ProjectInfoContext.Provider>;
};

export function useProjectInfoContextSelector<T extends keyof ProjectInfoContextType>(
	field: T
): ProjectInfoContextType[T] {
	return useContextSelector(ProjectInfoContext, (v) => v![field]);
}

export function useProjectInfoContextUnoptimized() {
	const context = useContextSelector(ProjectInfoContext, (v) => v);

	if (context === undefined) {
		throw new Error("Trying to use context outside of provider");
	}

	return context;
}
