"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminLessonApi = exports.AdminLessonApiFactory = exports.AdminLessonApiFp = exports.AdminLessonApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminLessonApi - axios parameter creator
 * @export
 */
exports.AdminLessonApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add lesson
         * @param {string} [tenantId] tenantId
         * @param {EditCreateLesson} [editCreateLesson]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLesson: (tenantId, editCreateLesson, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/Lesson/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateLesson, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"create\", \"Lesson\"]
         * @summary Clone lessons from tenant | Permission required: [\"create\", \"Lesson\"]
         * @param {string} [tenantId] tenantId
         * @param {InputCloneLessonsFromTenant} [inputCloneLessonsFromTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneLessonsFromTenant: (tenantId, inputCloneLessonsFromTenant, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/Lesson/clone/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputCloneLessonsFromTenant, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"delete\", \"Lesson\"]
         * @summary Delete lesson | Permission required: [\"delete\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLesson: (lessonId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'lessonId' is not null or undefined
            common_1.assertParamExists('deleteLesson', 'lessonId', lessonId);
            const localVarPath = `/admin/Lesson/{lessonId}/`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Lesson\"]
         * @summary Edit lesson | Permission required: [\"update\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateLesson} [editCreateLesson]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editLesson: (lessonId, tenantId, editCreateLesson, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'lessonId' is not null or undefined
            common_1.assertParamExists('editLesson', 'lessonId', lessonId);
            const localVarPath = `/admin/Lesson/{lessonId}/`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateLesson, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all admin lessons
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminLessons: (tenantId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/Lesson/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update lesson image
         * @param {string} lessonId
         * @param {string} [tenantId] tenantId
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLessonImage: (lessonId, tenantId, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'lessonId' is not null or undefined
            common_1.assertParamExists('updateLessonImage', 'lessonId', lessonId);
            const localVarPath = `/admin/Lesson/{lessonId}/image/`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminLessonApi - functional programming interface
 * @export
 */
exports.AdminLessonApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminLessonApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add lesson
         * @param {string} [tenantId] tenantId
         * @param {EditCreateLesson} [editCreateLesson]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLesson(tenantId, editCreateLesson, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addLesson(tenantId, editCreateLesson, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"create\", \"Lesson\"]
         * @summary Clone lessons from tenant | Permission required: [\"create\", \"Lesson\"]
         * @param {string} [tenantId] tenantId
         * @param {InputCloneLessonsFromTenant} [inputCloneLessonsFromTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneLessonsFromTenant(tenantId, inputCloneLessonsFromTenant, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.cloneLessonsFromTenant(tenantId, inputCloneLessonsFromTenant, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"delete\", \"Lesson\"]
         * @summary Delete lesson | Permission required: [\"delete\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLesson(lessonId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteLesson(lessonId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Lesson\"]
         * @summary Edit lesson | Permission required: [\"update\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateLesson} [editCreateLesson]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editLesson(lessonId, tenantId, editCreateLesson, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editLesson(lessonId, tenantId, editCreateLesson, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all admin lessons
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminLessons(tenantId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllAdminLessons(tenantId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Update lesson image
         * @param {string} lessonId
         * @param {string} [tenantId] tenantId
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLessonImage(lessonId, tenantId, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateLessonImage(lessonId, tenantId, file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminLessonApi - factory interface
 * @export
 */
exports.AdminLessonApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminLessonApiFp(configuration);
    return {
        /**
         *
         * @summary Add lesson
         * @param {string} [tenantId] tenantId
         * @param {EditCreateLesson} [editCreateLesson]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLesson(tenantId, editCreateLesson, options) {
            return localVarFp.addLesson(tenantId, editCreateLesson, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"create\", \"Lesson\"]
         * @summary Clone lessons from tenant | Permission required: [\"create\", \"Lesson\"]
         * @param {string} [tenantId] tenantId
         * @param {InputCloneLessonsFromTenant} [inputCloneLessonsFromTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneLessonsFromTenant(tenantId, inputCloneLessonsFromTenant, options) {
            return localVarFp.cloneLessonsFromTenant(tenantId, inputCloneLessonsFromTenant, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"delete\", \"Lesson\"]
         * @summary Delete lesson | Permission required: [\"delete\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLesson(lessonId, tenantId, options) {
            return localVarFp.deleteLesson(lessonId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Lesson\"]
         * @summary Edit lesson | Permission required: [\"update\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateLesson} [editCreateLesson]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editLesson(lessonId, tenantId, editCreateLesson, options) {
            return localVarFp.editLesson(lessonId, tenantId, editCreateLesson, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all admin lessons
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminLessons(tenantId, filter, options) {
            return localVarFp.getAllAdminLessons(tenantId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update lesson image
         * @param {string} lessonId
         * @param {string} [tenantId] tenantId
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLessonImage(lessonId, tenantId, file, options) {
            return localVarFp.updateLessonImage(lessonId, tenantId, file, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminLessonApi - object-oriented interface
 * @export
 * @class AdminLessonApi
 * @extends {BaseAPI}
 */
class AdminLessonApi extends base_1.BaseAPI {
    /**
     *
     * @summary Add lesson
     * @param {string} [tenantId] tenantId
     * @param {EditCreateLesson} [editCreateLesson]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLessonApi
     */
    addLesson(tenantId, editCreateLesson, options) {
        return exports.AdminLessonApiFp(this.configuration).addLesson(tenantId, editCreateLesson, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"create\", \"Lesson\"]
     * @summary Clone lessons from tenant | Permission required: [\"create\", \"Lesson\"]
     * @param {string} [tenantId] tenantId
     * @param {InputCloneLessonsFromTenant} [inputCloneLessonsFromTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLessonApi
     */
    cloneLessonsFromTenant(tenantId, inputCloneLessonsFromTenant, options) {
        return exports.AdminLessonApiFp(this.configuration).cloneLessonsFromTenant(tenantId, inputCloneLessonsFromTenant, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"delete\", \"Lesson\"]
     * @summary Delete lesson | Permission required: [\"delete\", \"Lesson\"]
     * @param {string} lessonId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLessonApi
     */
    deleteLesson(lessonId, tenantId, options) {
        return exports.AdminLessonApiFp(this.configuration).deleteLesson(lessonId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Lesson\"]
     * @summary Edit lesson | Permission required: [\"update\", \"Lesson\"]
     * @param {string} lessonId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateLesson} [editCreateLesson]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLessonApi
     */
    editLesson(lessonId, tenantId, editCreateLesson, options) {
        return exports.AdminLessonApiFp(this.configuration).editLesson(lessonId, tenantId, editCreateLesson, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all admin lessons
     * @param {string} [tenantId] tenantId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLessonApi
     */
    getAllAdminLessons(tenantId, filter, options) {
        return exports.AdminLessonApiFp(this.configuration).getAllAdminLessons(tenantId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update lesson image
     * @param {string} lessonId
     * @param {string} [tenantId] tenantId
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLessonApi
     */
    updateLessonImage(lessonId, tenantId, file, options) {
        return exports.AdminLessonApiFp(this.configuration).updateLessonImage(lessonId, tenantId, file, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminLessonApi = AdminLessonApi;
