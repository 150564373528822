"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminProjectApi = exports.AdminProjectApiFactory = exports.AdminProjectApiFp = exports.AdminProjectApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminProjectApi - axios parameter creator
 * @export
 */
exports.AdminProjectApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"create\", \"Project\"]
         * @summary Clone projects from tenant | Permission required: [\"create\", \"Project\"]
         * @param {string} [tenantId] tenantId
         * @param {InputCloneProjectsFromTenant} [inputCloneProjectsFromTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneProjectsFromTenant: (tenantId, inputCloneProjectsFromTenant, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/project/clone/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputCloneProjectsFromTenant, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create project
         * @param {string} [tenantId] tenantId
         * @param {EditCreateProject} [editCreateProject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: (tenantId, editCreateProject, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/project/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateProject, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"delete\", \"Project\"]
         * @summary Delete project | Permission required: [\"delete\", \"Project\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: (projectId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            common_1.assertParamExists('deleteProject', 'projectId', projectId);
            const localVarPath = `/admin/project/{projectId}/`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Project\"]
         * @summary Edit project | Permission required: [\"update\", \"Project\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateProject} [editCreateProject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProject: (projectId, tenantId, editCreateProject, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            common_1.assertParamExists('editProject', 'projectId', projectId);
            const localVarPath = `/admin/project/{projectId}/`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateProject, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin feedback summary
         * @param {string} projectId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFeedbackSummary: (projectId, filter, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            common_1.assertParamExists('getAdminFeedbackSummary', 'projectId', projectId);
            const localVarPath = `/admin/project/{projectId}/feedback/summary`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Project\"]
         * @summary Get admin project by id | Permission required: [\"readOne\", \"Project\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminProjectById: (projectId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            common_1.assertParamExists('getAdminProjectById', 'projectId', projectId);
            const localVarPath = `/admin/project/{projectId}/`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Project\"]
         * @summary Get all admin projects | Permission required: [\"readAll\", \"Project\"]
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminProjects: (tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/project/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Team\"]
         * @summary Get missing feedbacks | Permission required: [\"readAll\", \"Team\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissingFeedbacks: (projectId, tenantId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            common_1.assertParamExists('getMissingFeedbacks', 'projectId', projectId);
            const localVarPath = `/admin/project/{projectId}/feedback/missing`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"User\"]
         * @summary Get participants without team | Permission required: [\"readAll\", \"User\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantsWithoutTeam: (projectId, tenantId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            common_1.assertParamExists('getParticipantsWithoutTeam', 'projectId', projectId);
            const localVarPath = `/admin/project/{projectId}/participants/`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminProjectApi - functional programming interface
 * @export
 */
exports.AdminProjectApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminProjectApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"Project\"]
         * @summary Clone projects from tenant | Permission required: [\"create\", \"Project\"]
         * @param {string} [tenantId] tenantId
         * @param {InputCloneProjectsFromTenant} [inputCloneProjectsFromTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneProjectsFromTenant(tenantId, inputCloneProjectsFromTenant, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.cloneProjectsFromTenant(tenantId, inputCloneProjectsFromTenant, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create project
         * @param {string} [tenantId] tenantId
         * @param {EditCreateProject} [editCreateProject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(tenantId, editCreateProject, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createProject(tenantId, editCreateProject, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"delete\", \"Project\"]
         * @summary Delete project | Permission required: [\"delete\", \"Project\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteProject(projectId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Project\"]
         * @summary Edit project | Permission required: [\"update\", \"Project\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateProject} [editCreateProject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProject(projectId, tenantId, editCreateProject, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editProject(projectId, tenantId, editCreateProject, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin feedback summary
         * @param {string} projectId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFeedbackSummary(projectId, filter, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminFeedbackSummary(projectId, filter, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Project\"]
         * @summary Get admin project by id | Permission required: [\"readOne\", \"Project\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminProjectById(projectId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminProjectById(projectId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Project\"]
         * @summary Get all admin projects | Permission required: [\"readAll\", \"Project\"]
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminProjects(tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllAdminProjects(tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Team\"]
         * @summary Get missing feedbacks | Permission required: [\"readAll\", \"Team\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissingFeedbacks(projectId, tenantId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMissingFeedbacks(projectId, tenantId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"User\"]
         * @summary Get participants without team | Permission required: [\"readAll\", \"User\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantsWithoutTeam(projectId, tenantId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getParticipantsWithoutTeam(projectId, tenantId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminProjectApi - factory interface
 * @export
 */
exports.AdminProjectApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminProjectApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"Project\"]
         * @summary Clone projects from tenant | Permission required: [\"create\", \"Project\"]
         * @param {string} [tenantId] tenantId
         * @param {InputCloneProjectsFromTenant} [inputCloneProjectsFromTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneProjectsFromTenant(tenantId, inputCloneProjectsFromTenant, options) {
            return localVarFp.cloneProjectsFromTenant(tenantId, inputCloneProjectsFromTenant, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create project
         * @param {string} [tenantId] tenantId
         * @param {EditCreateProject} [editCreateProject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(tenantId, editCreateProject, options) {
            return localVarFp.createProject(tenantId, editCreateProject, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"delete\", \"Project\"]
         * @summary Delete project | Permission required: [\"delete\", \"Project\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId, tenantId, options) {
            return localVarFp.deleteProject(projectId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Project\"]
         * @summary Edit project | Permission required: [\"update\", \"Project\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateProject} [editCreateProject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProject(projectId, tenantId, editCreateProject, options) {
            return localVarFp.editProject(projectId, tenantId, editCreateProject, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin feedback summary
         * @param {string} projectId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFeedbackSummary(projectId, filter, tenantId, options) {
            return localVarFp.getAdminFeedbackSummary(projectId, filter, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Project\"]
         * @summary Get admin project by id | Permission required: [\"readOne\", \"Project\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminProjectById(projectId, tenantId, options) {
            return localVarFp.getAdminProjectById(projectId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Project\"]
         * @summary Get all admin projects | Permission required: [\"readAll\", \"Project\"]
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminProjects(tenantId, options) {
            return localVarFp.getAllAdminProjects(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Team\"]
         * @summary Get missing feedbacks | Permission required: [\"readAll\", \"Team\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissingFeedbacks(projectId, tenantId, filter, options) {
            return localVarFp.getMissingFeedbacks(projectId, tenantId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"User\"]
         * @summary Get participants without team | Permission required: [\"readAll\", \"User\"]
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantsWithoutTeam(projectId, tenantId, filter, options) {
            return localVarFp.getParticipantsWithoutTeam(projectId, tenantId, filter, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminProjectApi - object-oriented interface
 * @export
 * @class AdminProjectApi
 * @extends {BaseAPI}
 */
class AdminProjectApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"create\", \"Project\"]
     * @summary Clone projects from tenant | Permission required: [\"create\", \"Project\"]
     * @param {string} [tenantId] tenantId
     * @param {InputCloneProjectsFromTenant} [inputCloneProjectsFromTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProjectApi
     */
    cloneProjectsFromTenant(tenantId, inputCloneProjectsFromTenant, options) {
        return exports.AdminProjectApiFp(this.configuration).cloneProjectsFromTenant(tenantId, inputCloneProjectsFromTenant, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create project
     * @param {string} [tenantId] tenantId
     * @param {EditCreateProject} [editCreateProject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProjectApi
     */
    createProject(tenantId, editCreateProject, options) {
        return exports.AdminProjectApiFp(this.configuration).createProject(tenantId, editCreateProject, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"delete\", \"Project\"]
     * @summary Delete project | Permission required: [\"delete\", \"Project\"]
     * @param {string} projectId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProjectApi
     */
    deleteProject(projectId, tenantId, options) {
        return exports.AdminProjectApiFp(this.configuration).deleteProject(projectId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Project\"]
     * @summary Edit project | Permission required: [\"update\", \"Project\"]
     * @param {string} projectId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateProject} [editCreateProject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProjectApi
     */
    editProject(projectId, tenantId, editCreateProject, options) {
        return exports.AdminProjectApiFp(this.configuration).editProject(projectId, tenantId, editCreateProject, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin feedback summary
     * @param {string} projectId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProjectApi
     */
    getAdminFeedbackSummary(projectId, filter, tenantId, options) {
        return exports.AdminProjectApiFp(this.configuration).getAdminFeedbackSummary(projectId, filter, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Project\"]
     * @summary Get admin project by id | Permission required: [\"readOne\", \"Project\"]
     * @param {string} projectId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProjectApi
     */
    getAdminProjectById(projectId, tenantId, options) {
        return exports.AdminProjectApiFp(this.configuration).getAdminProjectById(projectId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Project\"]
     * @summary Get all admin projects | Permission required: [\"readAll\", \"Project\"]
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProjectApi
     */
    getAllAdminProjects(tenantId, options) {
        return exports.AdminProjectApiFp(this.configuration).getAllAdminProjects(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Team\"]
     * @summary Get missing feedbacks | Permission required: [\"readAll\", \"Team\"]
     * @param {string} projectId
     * @param {string} [tenantId] tenantId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProjectApi
     */
    getMissingFeedbacks(projectId, tenantId, filter, options) {
        return exports.AdminProjectApiFp(this.configuration).getMissingFeedbacks(projectId, tenantId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"User\"]
     * @summary Get participants without team | Permission required: [\"readAll\", \"User\"]
     * @param {string} projectId
     * @param {string} [tenantId] tenantId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProjectApi
     */
    getParticipantsWithoutTeam(projectId, tenantId, filter, options) {
        return exports.AdminProjectApiFp(this.configuration).getParticipantsWithoutTeam(projectId, tenantId, filter, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminProjectApi = AdminProjectApi;
