"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserWithTeamDataRoleEnum = void 0;
exports.UserWithTeamDataRoleEnum = {
    ProjectManager: 'ProjectManager',
    ProgramManager: 'ProgramManager',
    Mentor: 'Mentor',
    User: 'User'
};
