import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import translationIT from "./locales/it/translation.json";

export const resources = {
	it: {
		translation: translationIT,
	},
};

i18n
	// .use(LanguageDetector) TODO: attivare per prod
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		fallbackLng: "it",
		lng: "it", //rimuovere con langauage detector attivo

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
