"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * UserApi - axios parameter creator
 * @export
 */
exports.UserApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Delete user image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserImage: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/image/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit user image
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserImage: (file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/image/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"User\"]
         * @summary Get me | Permission required: [\"readOne\", \"User\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * UserApi - functional programming interface
 * @export
 */
exports.UserApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.UserApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Delete user image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserImage(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteUserImage(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit user image
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserImage(file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editUserImage(file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"User\"]
         * @summary Get me | Permission required: [\"readOne\", \"User\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMe(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * UserApi - factory interface
 * @export
 */
exports.UserApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.UserApiFp(configuration);
    return {
        /**
         *
         * @summary Delete user image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserImage(options) {
            return localVarFp.deleteUserImage(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit user image
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserImage(file, options) {
            return localVarFp.editUserImage(file, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"User\"]
         * @summary Get me | Permission required: [\"readOne\", \"User\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options) {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
class UserApi extends base_1.BaseAPI {
    /**
     *
     * @summary Delete user image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    deleteUserImage(options) {
        return exports.UserApiFp(this.configuration).deleteUserImage(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit user image
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    editUserImage(file, options) {
        return exports.UserApiFp(this.configuration).editUserImage(file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"User\"]
     * @summary Get me | Permission required: [\"readOne\", \"User\"]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getMe(options) {
        return exports.UserApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.UserApi = UserApi;
