"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminTagApi = exports.AdminTagApiFactory = exports.AdminTagApiFp = exports.AdminTagApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminTagApi - axios parameter creator
 * @export
 */
exports.AdminTagApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"create\", \"Tag\"]
         * @summary Add tag to tenant | Permission required: [\"create\", \"Tag\"]
         * @param {string} [tenantId] tenantId
         * @param {EditCreateTag} [editCreateTag]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTagToTenant: (tenantId, editCreateTag, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/tag/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateTag, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"delete\", \"Tag\"]
         * @summary Delete tag | Permission required: [\"delete\", \"Tag\"]
         * @param {string} tagId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag: (tagId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tagId' is not null or undefined
            common_1.assertParamExists('deleteTag', 'tagId', tagId);
            const localVarPath = `/admin/tag/{tagId}/`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Tag\"]
         * @summary Edit tag | Permission required: [\"update\", \"Tag\"]
         * @param {string} tagId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateTag} [editCreateTag]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTag: (tagId, tenantId, editCreateTag, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tagId' is not null or undefined
            common_1.assertParamExists('editTag', 'tagId', tagId);
            const localVarPath = `/admin/tag/{tagId}/`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateTag, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Tag\"]
         * @summary Get all tenant tags | Permission required: [\"readAll\", \"Tag\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTenantTags: (tenantId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/tag/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Tag\"]
         * @summary Set tag image | Permission required: [\"update\", \"Tag\"]
         * @param {string} tagId
         * @param {string} [tenantId] tenantId
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTagImage: (tagId, tenantId, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tagId' is not null or undefined
            common_1.assertParamExists('setTagImage', 'tagId', tagId);
            const localVarPath = `/admin/tag/{tagId}/image/`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminTagApi - functional programming interface
 * @export
 */
exports.AdminTagApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminTagApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"Tag\"]
         * @summary Add tag to tenant | Permission required: [\"create\", \"Tag\"]
         * @param {string} [tenantId] tenantId
         * @param {EditCreateTag} [editCreateTag]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTagToTenant(tenantId, editCreateTag, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addTagToTenant(tenantId, editCreateTag, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"delete\", \"Tag\"]
         * @summary Delete tag | Permission required: [\"delete\", \"Tag\"]
         * @param {string} tagId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag(tagId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteTag(tagId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Tag\"]
         * @summary Edit tag | Permission required: [\"update\", \"Tag\"]
         * @param {string} tagId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateTag} [editCreateTag]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTag(tagId, tenantId, editCreateTag, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editTag(tagId, tenantId, editCreateTag, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Tag\"]
         * @summary Get all tenant tags | Permission required: [\"readAll\", \"Tag\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTenantTags(tenantId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllTenantTags(tenantId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Tag\"]
         * @summary Set tag image | Permission required: [\"update\", \"Tag\"]
         * @param {string} tagId
         * @param {string} [tenantId] tenantId
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTagImage(tagId, tenantId, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setTagImage(tagId, tenantId, file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminTagApi - factory interface
 * @export
 */
exports.AdminTagApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminTagApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"Tag\"]
         * @summary Add tag to tenant | Permission required: [\"create\", \"Tag\"]
         * @param {string} [tenantId] tenantId
         * @param {EditCreateTag} [editCreateTag]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTagToTenant(tenantId, editCreateTag, options) {
            return localVarFp.addTagToTenant(tenantId, editCreateTag, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"delete\", \"Tag\"]
         * @summary Delete tag | Permission required: [\"delete\", \"Tag\"]
         * @param {string} tagId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag(tagId, tenantId, options) {
            return localVarFp.deleteTag(tagId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Tag\"]
         * @summary Edit tag | Permission required: [\"update\", \"Tag\"]
         * @param {string} tagId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateTag} [editCreateTag]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTag(tagId, tenantId, editCreateTag, options) {
            return localVarFp.editTag(tagId, tenantId, editCreateTag, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Tag\"]
         * @summary Get all tenant tags | Permission required: [\"readAll\", \"Tag\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTenantTags(tenantId, filter, options) {
            return localVarFp.getAllTenantTags(tenantId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Tag\"]
         * @summary Set tag image | Permission required: [\"update\", \"Tag\"]
         * @param {string} tagId
         * @param {string} [tenantId] tenantId
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTagImage(tagId, tenantId, file, options) {
            return localVarFp.setTagImage(tagId, tenantId, file, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminTagApi - object-oriented interface
 * @export
 * @class AdminTagApi
 * @extends {BaseAPI}
 */
class AdminTagApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"create\", \"Tag\"]
     * @summary Add tag to tenant | Permission required: [\"create\", \"Tag\"]
     * @param {string} [tenantId] tenantId
     * @param {EditCreateTag} [editCreateTag]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTagApi
     */
    addTagToTenant(tenantId, editCreateTag, options) {
        return exports.AdminTagApiFp(this.configuration).addTagToTenant(tenantId, editCreateTag, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"delete\", \"Tag\"]
     * @summary Delete tag | Permission required: [\"delete\", \"Tag\"]
     * @param {string} tagId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTagApi
     */
    deleteTag(tagId, tenantId, options) {
        return exports.AdminTagApiFp(this.configuration).deleteTag(tagId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Tag\"]
     * @summary Edit tag | Permission required: [\"update\", \"Tag\"]
     * @param {string} tagId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateTag} [editCreateTag]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTagApi
     */
    editTag(tagId, tenantId, editCreateTag, options) {
        return exports.AdminTagApiFp(this.configuration).editTag(tagId, tenantId, editCreateTag, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Tag\"]
     * @summary Get all tenant tags | Permission required: [\"readAll\", \"Tag\"]
     * @param {string} [tenantId] tenantId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTagApi
     */
    getAllTenantTags(tenantId, filter, options) {
        return exports.AdminTagApiFp(this.configuration).getAllTenantTags(tenantId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Tag\"]
     * @summary Set tag image | Permission required: [\"update\", \"Tag\"]
     * @param {string} tagId
     * @param {string} [tenantId] tenantId
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTagApi
     */
    setTagImage(tagId, tenantId, file, options) {
        return exports.AdminTagApiFp(this.configuration).setTagImage(tagId, tenantId, file, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminTagApi = AdminTagApi;
