import { Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import IPlatformIcon from "./icons/IPlatformIcon";

type Props = {
	title: string;
	sx?: SxProps<Theme>;
	color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
};

const NoContents = React.forwardRef<Props, any>(({ title, sx, color = "text.secondary", ...otherProps }, ref) => {
	return (
		<Box
			ref={ref}
			sx={{
				display: "flex",
				alignItems: "center",
				// justifyContent: "center",
				gap: 3,
				padding: 3,
				...sx,
			}}
			{...otherProps}
		>
			<IPlatformIcon name="smile-sg" iconCss={{ transform: "rotate(180deg)" }} />
			<Typography children={title} variant="body2" color={color} sx={{ fontWeight: 600 }} />
		</Box>
	);
});

export default NoContents;
