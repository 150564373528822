import { Button, Dialog, DialogContent, FormControl, Stack, Typography } from "@mui/material";
import { AdminProjectApi } from "innovation-api-manager";
import { DateTime } from "luxon";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LoadingIconButton from "../../../../../../../components/buttons/LoadingIconButton";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import BetterTextInput from "../../../../../../../components/textInput/BetterTextInput";
import { apiErrorParser, useApi } from "../../../../../../../hooks/api";
import { useProjectInfoContextSelector } from "../controllers/ProjectInfoController";

type CloseProjectModalProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
	projectName: string;
};
const CloseProjectModal: React.FC<CloseProjectModalProps> = ({ open, setOpen, projectName }) => {
	const { t } = useTranslation();
	const { tenantId, projectId } = useParams();

	const adminProjectApi = useApi(AdminProjectApi);

	const [nameConfirmation, setNameConfirmation] = useState("");

	const [closeLoading, setCloseLoading] = useState(false);

	const revalidate = useProjectInfoContextSelector("revalidate");

	const closeProject = useCallback(() => {
		if (projectId) {
			setCloseLoading(true);
			adminProjectApi
				.editProject(projectId, tenantId, {
					closingDate: DateTime.now().toISO(),
				})
				.then(() => {
					MLDialog.showSnackbar(t("project.projectClosedSuccessfully"), { variant: "success" });
					setOpen(false);
					revalidate();
				})
				.catch((e) => {
					MLDialog.showSnackbar(apiErrorParser(e), { variant: "error" });
				})
				.finally(() => {
					setCloseLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adminProjectApi, projectId, setOpen, t, tenantId]);

	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			fullWidth
			maxWidth="sm"
		>
			<DialogContent>
				<div style={{ overflow: "hidden", height: "calc(100% + 2px)", width: "calc(100% + 2px)", padding: 2, margin: -2 }}>
					<Stack spacing={6}>
						<Stack spacing={2}>
							<Typography children="Vuoi davvero chiudere questo progetto?" variant="subtitle1" />
							<Typography variant="body1">
								Una volta chiuso il progetto <b>non potrai più riattivarlo</b>. Per chiudere definitivamente il progetto
								riscrivine il nome qui sotto
							</Typography>
						</Stack>
						<FormControl fullWidth>
							<BetterTextInput
								value={nameConfirmation}
								onChange={(event) => {
									setNameConfirmation(event.target.value);
								}}
								placeholder={t("project.projectName")}
								size="medium"
								label={t("project.projectName")}
								fullWidth
							/>
						</FormControl>
						<Stack direction="row" justifyContent="space-between" alignItems="center">
							<Button
								children={t("global.cancel")}
								startIcon={<IPlatformIcon name="close" />}
								onClick={() => {
									setOpen(false);
								}}
							/>
							<LoadingIconButton
								loading={closeLoading}
								children={t("project.closeProject")}
								variant="contained"
								color="error"
								disabled={nameConfirmation !== projectName}
								icon={<IPlatformIcon name="megaphone-sg" />}
								onClick={closeProject}
							/>
						</Stack>
					</Stack>
				</div>
			</DialogContent>
		</Dialog>
	);
};
export default CloseProjectModal;
