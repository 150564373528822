import { AdminProjectApi, AdminSprintApi, Sprint } from "innovation-api-manager";
import { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { createContext, useContextSelector } from "use-context-selector";
import { useImmer } from "use-immer";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { useApi, useFetch } from "../../../../../../../hooks/api";
import { useAccount } from "../../../../../../../hooks/useAccount";

type TemporarySprint = {
	title: string;
	startDate: string;
	endDate: string;
	description: string;
};

export function useProjectSprintsController() {
	const { tenantId, projectId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const adminSprintApi = useApi(AdminSprintApi);
	const adminProjectApi = useApi(AdminProjectApi);

	const { data: me } = useAccount();
	const readOnly = me?.role === "ReadOnlyTenantManager";

	//@ts-ignore
	const projectFetch = useFetch(adminProjectApi.getAdminProjectById, projectId ?? "", tenantId, {
		skip: !projectId || !tenantId,
	});

	const [selectedSprint, setSelectedSprint] = useState<Sprint>();

	const [temporarySprint, setTemporarySprint] = useImmer<TemporarySprint>({
		title: "",
		startDate: "",
		endDate: "",
		description: "",
	});

	const {
		data: sprints,
		loading: loadingSprints,
		error: errorSprints,
		revalidate: revalidateSprints,
	} = useFetch(adminSprintApi.getAllProjectSprints, projectId as string, tenantId as string, {
		skip: !projectId || !tenantId,
	});

	const addSprint = () => {
		if (
			temporarySprint?.title === "" ||
			(!projectFetch.data?.allUnlocked && (temporarySprint?.startDate === "" || temporarySprint?.endDate === ""))
		) {
			MLDialog.showSnackbar("Inserisci tutti i dati per creare lo sprint");
			return;
		}

		return adminSprintApi
			.addSprintToProject(projectId as string, tenantId, {
				...temporarySprint,
			})
			.then((res) => {
				projectFetch.revalidate();
				return res;
			});
	};

	const editSprint = () => {
		if (
			temporarySprint?.title === "" ||
			(!projectFetch.data?.allUnlocked && (temporarySprint?.startDate === "" || temporarySprint?.endDate === ""))
		) {
			MLDialog.showSnackbar("Inserisci tutti i dati necessari per modificare lo sprint");
			return;
		}

		return adminSprintApi.editSprint(selectedSprint?.id as string, tenantId, { ...temporarySprint }).then((res) => {
			projectFetch.revalidate();
			return res;
		});
	};

	const deleteSprint = () => {
		return adminSprintApi.deleteSprint(selectedSprint?.id as string, tenantId as string).then((res) => {
			projectFetch.revalidate();
			return res;
		});
	};

	useEffect(() => {
		if (!!sprints && !!sprints?.length) {
			const selectedIndex = sprints?.findIndex((sprint) => sprint?.id === selectedSprint?.id);

			if (selectedIndex !== -1) {
				setSelectedSprint(sprints[selectedIndex]);
			} else {
				setSelectedSprint(sprints[0]);
			}
		}
	}, [sprints]);

	useEffect(() => {
		if (!!selectedSprint?.id) {
			setSearchParams({ sprint: selectedSprint?.id });
		}
	}, [selectedSprint?.id]);

	return {
		sprints,
		loadingSprints,
		errorSprints,
		revalidateSprints,
		selectedSprint,
		setSelectedSprint,
		temporarySprint,
		setTemporarySprint,
		addSprint,
		editSprint,
		deleteSprint,
		projectFetch,
		readOnly,
	};
}

type ProjectSprintsContextType = ReturnType<typeof useProjectSprintsController>;

const ProjectSprintsContext = createContext<ProjectSprintsContextType | undefined>(undefined);

export const ProjectSprintsContextProvider: React.FC<{
	projectSprintsController: ProjectSprintsContextType;
	children: React.ReactNode;
}> = ({ children, projectSprintsController }) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoChildren = useMemo(() => children, []);
	return (
		<ProjectSprintsContext.Provider value={projectSprintsController}>{memoChildren}</ProjectSprintsContext.Provider>
	);
};

export function useProjectSprintsContextSelector<T extends keyof ProjectSprintsContextType>(
	field: T
): ProjectSprintsContextType[T] {
	return useContextSelector(ProjectSprintsContext, (v) => v![field]);
}

export function useProjectSprintsContextUnoptimized() {
	const context = useContextSelector(ProjectSprintsContext, (v) => v);

	if (context === undefined) {
		throw new Error("Trying to use context outside of provider");
	}

	return context;
}
