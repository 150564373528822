import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import React from "react";

const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }>(
	(props, ref) => {
		const { href, ...other } = props;
		// Map href (MUI) -> to (react-router)
		return <RouterLink ref={ref} to={href} {...other} />;
	}
);

export default LinkBehavior;
