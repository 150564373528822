"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamApi = exports.TeamApiFactory = exports.TeamApiFp = exports.TeamApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * TeamApi - axios parameter creator
 * @export
 */
exports.TeamApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get team name by id
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamNameById: (teamId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'teamId' is not null or undefined
            common_1.assertParamExists('getTeamNameById', 'teamId', teamId);
            const localVarPath = `/team/{teamId}/name`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Project\"]
         * @summary Get user team mentors | Permission required: [\"readOne\", \"Project\"]
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTeamMentors: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getUserTeamMentors', 'id', id);
            const localVarPath = `/project/{id}/team/mentor/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * TeamApi - functional programming interface
 * @export
 */
exports.TeamApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.TeamApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get team name by id
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamNameById(teamId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTeamNameById(teamId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Project\"]
         * @summary Get user team mentors | Permission required: [\"readOne\", \"Project\"]
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTeamMentors(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserTeamMentors(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * TeamApi - factory interface
 * @export
 */
exports.TeamApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.TeamApiFp(configuration);
    return {
        /**
         *
         * @summary Get team name by id
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamNameById(teamId, tenantId, options) {
            return localVarFp.getTeamNameById(teamId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Project\"]
         * @summary Get user team mentors | Permission required: [\"readOne\", \"Project\"]
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTeamMentors(id, options) {
            return localVarFp.getUserTeamMentors(id, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * TeamApi - object-oriented interface
 * @export
 * @class TeamApi
 * @extends {BaseAPI}
 */
class TeamApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get team name by id
     * @param {string} teamId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    getTeamNameById(teamId, tenantId, options) {
        return exports.TeamApiFp(this.configuration).getTeamNameById(teamId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Project\"]
     * @summary Get user team mentors | Permission required: [\"readOne\", \"Project\"]
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    getUserTeamMentors(id, options) {
        return exports.TeamApiFp(this.configuration).getUserTeamMentors(id, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.TeamApi = TeamApi;
