"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminCourseApi = exports.AdminCourseApiFactory = exports.AdminCourseApiFp = exports.AdminCourseApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminCourseApi - axios parameter creator
 * @export
 */
exports.AdminCourseApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"create\", \"Course\"]
         * @summary Clone course from tenant | Permission required: [\"create\", \"Course\"]
         * @param {string} [tenantId] tenantId
         * @param {InputCloneCourseFromTenant} [inputCloneCourseFromTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneCourseFromTenant: (tenantId, inputCloneCourseFromTenant, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/course/clone/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputCloneCourseFromTenant, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"create\", \"Course\"]
         * @summary Create course | Permission required: [\"create\", \"Course\"]
         * @param {string} [tenantId] tenantId
         * @param {EditCreateCourse} [editCreateCourse]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourse: (tenantId, editCreateCourse, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/course/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateCourse, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"delete\", \"Course\"]
         * @summary Delete course | Permission required: [\"delete\", \"Course\"]
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourse: (courseId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'courseId' is not null or undefined
            common_1.assertParamExists('deleteCourse', 'courseId', courseId);
            const localVarPath = `/admin/course/{courseId}/`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Course\"]
         * @summary Edit course | Permission required: [\"update\", \"Course\"]
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateCourse} [editCreateCourse]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCourse: (courseId, tenantId, editCreateCourse, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'courseId' is not null or undefined
            common_1.assertParamExists('editCourse', 'courseId', courseId);
            const localVarPath = `/admin/course/{courseId}/`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateCourse, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Course\"]
         * @summary Get admin course by id | Permission required: [\"readOne\", \"Course\"]
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCourseById: (courseId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'courseId' is not null or undefined
            common_1.assertParamExists('getAdminCourseById', 'courseId', courseId);
            const localVarPath = `/admin/course/{courseId}/`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin course modules
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCourseModules: (courseId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'courseId' is not null or undefined
            common_1.assertParamExists('getAdminCourseModules', 'courseId', courseId);
            const localVarPath = `/admin/course/{courseId}/module/`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Course\"]
         * @summary Get courses | Permission required: [\"readAll\", \"Course\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourses: (tenantId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/course/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Course\"]
         * @summary Update course image | Permission required: [\"update\", \"Course\"]
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourseImage: (courseId, tenantId, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'courseId' is not null or undefined
            common_1.assertParamExists('updateCourseImage', 'courseId', courseId);
            const localVarPath = `/admin/course/{courseId}/image/`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminCourseApi - functional programming interface
 * @export
 */
exports.AdminCourseApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminCourseApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"Course\"]
         * @summary Clone course from tenant | Permission required: [\"create\", \"Course\"]
         * @param {string} [tenantId] tenantId
         * @param {InputCloneCourseFromTenant} [inputCloneCourseFromTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneCourseFromTenant(tenantId, inputCloneCourseFromTenant, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.cloneCourseFromTenant(tenantId, inputCloneCourseFromTenant, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"create\", \"Course\"]
         * @summary Create course | Permission required: [\"create\", \"Course\"]
         * @param {string} [tenantId] tenantId
         * @param {EditCreateCourse} [editCreateCourse]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourse(tenantId, editCreateCourse, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createCourse(tenantId, editCreateCourse, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"delete\", \"Course\"]
         * @summary Delete course | Permission required: [\"delete\", \"Course\"]
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourse(courseId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteCourse(courseId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Course\"]
         * @summary Edit course | Permission required: [\"update\", \"Course\"]
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateCourse} [editCreateCourse]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCourse(courseId, tenantId, editCreateCourse, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editCourse(courseId, tenantId, editCreateCourse, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Course\"]
         * @summary Get admin course by id | Permission required: [\"readOne\", \"Course\"]
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCourseById(courseId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminCourseById(courseId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin course modules
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCourseModules(courseId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminCourseModules(courseId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Course\"]
         * @summary Get courses | Permission required: [\"readAll\", \"Course\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourses(tenantId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCourses(tenantId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Course\"]
         * @summary Update course image | Permission required: [\"update\", \"Course\"]
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourseImage(courseId, tenantId, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateCourseImage(courseId, tenantId, file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminCourseApi - factory interface
 * @export
 */
exports.AdminCourseApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminCourseApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"Course\"]
         * @summary Clone course from tenant | Permission required: [\"create\", \"Course\"]
         * @param {string} [tenantId] tenantId
         * @param {InputCloneCourseFromTenant} [inputCloneCourseFromTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneCourseFromTenant(tenantId, inputCloneCourseFromTenant, options) {
            return localVarFp.cloneCourseFromTenant(tenantId, inputCloneCourseFromTenant, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"create\", \"Course\"]
         * @summary Create course | Permission required: [\"create\", \"Course\"]
         * @param {string} [tenantId] tenantId
         * @param {EditCreateCourse} [editCreateCourse]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourse(tenantId, editCreateCourse, options) {
            return localVarFp.createCourse(tenantId, editCreateCourse, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"delete\", \"Course\"]
         * @summary Delete course | Permission required: [\"delete\", \"Course\"]
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourse(courseId, tenantId, options) {
            return localVarFp.deleteCourse(courseId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Course\"]
         * @summary Edit course | Permission required: [\"update\", \"Course\"]
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateCourse} [editCreateCourse]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCourse(courseId, tenantId, editCreateCourse, options) {
            return localVarFp.editCourse(courseId, tenantId, editCreateCourse, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Course\"]
         * @summary Get admin course by id | Permission required: [\"readOne\", \"Course\"]
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCourseById(courseId, tenantId, options) {
            return localVarFp.getAdminCourseById(courseId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin course modules
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCourseModules(courseId, tenantId, options) {
            return localVarFp.getAdminCourseModules(courseId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Course\"]
         * @summary Get courses | Permission required: [\"readAll\", \"Course\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourses(tenantId, filter, options) {
            return localVarFp.getCourses(tenantId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Course\"]
         * @summary Update course image | Permission required: [\"update\", \"Course\"]
         * @param {string} courseId
         * @param {string} [tenantId] tenantId
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourseImage(courseId, tenantId, file, options) {
            return localVarFp.updateCourseImage(courseId, tenantId, file, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminCourseApi - object-oriented interface
 * @export
 * @class AdminCourseApi
 * @extends {BaseAPI}
 */
class AdminCourseApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"create\", \"Course\"]
     * @summary Clone course from tenant | Permission required: [\"create\", \"Course\"]
     * @param {string} [tenantId] tenantId
     * @param {InputCloneCourseFromTenant} [inputCloneCourseFromTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCourseApi
     */
    cloneCourseFromTenant(tenantId, inputCloneCourseFromTenant, options) {
        return exports.AdminCourseApiFp(this.configuration).cloneCourseFromTenant(tenantId, inputCloneCourseFromTenant, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"create\", \"Course\"]
     * @summary Create course | Permission required: [\"create\", \"Course\"]
     * @param {string} [tenantId] tenantId
     * @param {EditCreateCourse} [editCreateCourse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCourseApi
     */
    createCourse(tenantId, editCreateCourse, options) {
        return exports.AdminCourseApiFp(this.configuration).createCourse(tenantId, editCreateCourse, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"delete\", \"Course\"]
     * @summary Delete course | Permission required: [\"delete\", \"Course\"]
     * @param {string} courseId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCourseApi
     */
    deleteCourse(courseId, tenantId, options) {
        return exports.AdminCourseApiFp(this.configuration).deleteCourse(courseId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Course\"]
     * @summary Edit course | Permission required: [\"update\", \"Course\"]
     * @param {string} courseId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateCourse} [editCreateCourse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCourseApi
     */
    editCourse(courseId, tenantId, editCreateCourse, options) {
        return exports.AdminCourseApiFp(this.configuration).editCourse(courseId, tenantId, editCreateCourse, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Course\"]
     * @summary Get admin course by id | Permission required: [\"readOne\", \"Course\"]
     * @param {string} courseId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCourseApi
     */
    getAdminCourseById(courseId, tenantId, options) {
        return exports.AdminCourseApiFp(this.configuration).getAdminCourseById(courseId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin course modules
     * @param {string} courseId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCourseApi
     */
    getAdminCourseModules(courseId, tenantId, options) {
        return exports.AdminCourseApiFp(this.configuration).getAdminCourseModules(courseId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Course\"]
     * @summary Get courses | Permission required: [\"readAll\", \"Course\"]
     * @param {string} [tenantId] tenantId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCourseApi
     */
    getCourses(tenantId, filter, options) {
        return exports.AdminCourseApiFp(this.configuration).getCourses(tenantId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Course\"]
     * @summary Update course image | Permission required: [\"update\", \"Course\"]
     * @param {string} courseId
     * @param {string} [tenantId] tenantId
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCourseApi
     */
    updateCourseImage(courseId, tenantId, file, options) {
        return exports.AdminCourseApiFp(this.configuration).updateCourseImage(courseId, tenantId, file, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminCourseApi = AdminCourseApi;
