"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminTenantApi = exports.AdminTenantApiFactory = exports.AdminTenantApiFp = exports.AdminTenantApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminTenantApi - axios parameter creator
 * @export
 */
exports.AdminTenantApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add manager to tenant
         * @param {string} id
         * @param {string} managerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addManagerToTenant: (id, managerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('addManagerToTenant', 'id', id);
            // verify required parameter 'managerId' is not null or undefined
            common_1.assertParamExists('addManagerToTenant', 'managerId', managerId);
            const localVarPath = `/admin/tenant/{id}/manager/{managerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"managerId"}}`, encodeURIComponent(String(managerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Add managers to tenant
         * @param {string} [tenantId] tenantId
         * @param {InputAddManagersToTenant} [inputAddManagersToTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addManagersToTenant: (tenantId, inputAddManagersToTenant, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/tenant/manager/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputAddManagersToTenant, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"create\", \"User\"]
         * @summary Add user to tenant | Permission required: [\"create\", \"User\"]
         * @param {string} [tenantId] tenantId
         * @param {EditCreateUser} [editCreateUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToTenant: (tenantId, editCreateUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/tenant/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateUser, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"create\", \"Tenant\"]
         * @summary Create tenant | Permission required: [\"create\", \"Tenant\"]
         * @param {EditCreateTenant} [editCreateTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: (editCreateTenant, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/tenant/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateTenant, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"manage\", \"Tenant\"]
         * @summary Edit admin tenant by id | Permission required: [\"manage\", \"Tenant\"]
         * @param {string} id
         * @param {EditCreateTenant} [editCreateTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminTenantById: (id, editCreateTenant, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editAdminTenantById', 'id', id);
            const localVarPath = `/admin/tenant/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateTenant, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Tenant\"]
         * @summary Edit tenant services | Permission required: [\"update\", \"Tenant\"]
         * @param {string} [tenantId] tenantId
         * @param {InputEditTenantServices} [inputEditTenantServices]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTenantServices: (tenantId, inputEditTenantServices, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/tenant/services/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputEditTenantServices, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"User\"]
         * @summary Edit user | Permission required: [\"update\", \"User\"]
         * @param {string} userId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateUser} [editCreateUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser: (userId, tenantId, editCreateUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            common_1.assertParamExists('editUser', 'userId', userId);
            const localVarPath = `/admin/tenant/user/{userId}/`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateUser, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin tenant by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTenantById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAdminTenantById', 'id', id);
            const localVarPath = `/admin/tenant/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Tenant\"]
         * @summary Get all tenants | Permission required: [\"readAll\", \"Tenant\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTenants: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/tenant/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Tenant\"]
         * @summary Get tenant managers | Permission required: [\"readAll\", \"Tenant\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantManagers: (filter, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/tenant/manager/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"User\"]
         * @summary Get tenant users | Permission required: [\"readAll\", \"User\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'common' | 'mentor' | 'all'} [userType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantUsers: (tenantId, filter, userType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/tenant/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (userType !== undefined) {
                localVarQueryParameter['userType'] = userType;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove manager from tenant
         * @param {string} id
         * @param {string} managerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeManagerFromTenant: (id, managerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('removeManagerFromTenant', 'id', id);
            // verify required parameter 'managerId' is not null or undefined
            common_1.assertParamExists('removeManagerFromTenant', 'managerId', managerId);
            const localVarPath = `/admin/tenant/{id}/manager/{managerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"managerId"}}`, encodeURIComponent(String(managerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove managers from tenant
         * @param {string} [tenantId] tenantId
         * @param {InputRemoveManagersFromTenant} [inputRemoveManagersFromTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeManagersFromTenant: (tenantId, inputRemoveManagersFromTenant, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/tenant/manager/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputRemoveManagersFromTenant, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"delete\", \"User\"]
         * @summary Remove user from tenant | Permission required: [\"delete\", \"User\"]
         * @param {string} userId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromTenant: (userId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            common_1.assertParamExists('removeUserFromTenant', 'userId', userId);
            const localVarPath = `/admin/tenant/user/{userId}/`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminTenantApi - functional programming interface
 * @export
 */
exports.AdminTenantApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminTenantApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add manager to tenant
         * @param {string} id
         * @param {string} managerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addManagerToTenant(id, managerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addManagerToTenant(id, managerId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Add managers to tenant
         * @param {string} [tenantId] tenantId
         * @param {InputAddManagersToTenant} [inputAddManagersToTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addManagersToTenant(tenantId, inputAddManagersToTenant, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addManagersToTenant(tenantId, inputAddManagersToTenant, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"create\", \"User\"]
         * @summary Add user to tenant | Permission required: [\"create\", \"User\"]
         * @param {string} [tenantId] tenantId
         * @param {EditCreateUser} [editCreateUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToTenant(tenantId, editCreateUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addUserToTenant(tenantId, editCreateUser, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"create\", \"Tenant\"]
         * @summary Create tenant | Permission required: [\"create\", \"Tenant\"]
         * @param {EditCreateTenant} [editCreateTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant(editCreateTenant, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createTenant(editCreateTenant, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"manage\", \"Tenant\"]
         * @summary Edit admin tenant by id | Permission required: [\"manage\", \"Tenant\"]
         * @param {string} id
         * @param {EditCreateTenant} [editCreateTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminTenantById(id, editCreateTenant, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editAdminTenantById(id, editCreateTenant, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Tenant\"]
         * @summary Edit tenant services | Permission required: [\"update\", \"Tenant\"]
         * @param {string} [tenantId] tenantId
         * @param {InputEditTenantServices} [inputEditTenantServices]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTenantServices(tenantId, inputEditTenantServices, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editTenantServices(tenantId, inputEditTenantServices, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"User\"]
         * @summary Edit user | Permission required: [\"update\", \"User\"]
         * @param {string} userId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateUser} [editCreateUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser(userId, tenantId, editCreateUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editUser(userId, tenantId, editCreateUser, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin tenant by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTenantById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminTenantById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Tenant\"]
         * @summary Get all tenants | Permission required: [\"readAll\", \"Tenant\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTenants(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllTenants(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Tenant\"]
         * @summary Get tenant managers | Permission required: [\"readAll\", \"Tenant\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantManagers(filter, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTenantManagers(filter, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"User\"]
         * @summary Get tenant users | Permission required: [\"readAll\", \"User\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'common' | 'mentor' | 'all'} [userType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantUsers(tenantId, filter, userType, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTenantUsers(tenantId, filter, userType, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Remove manager from tenant
         * @param {string} id
         * @param {string} managerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeManagerFromTenant(id, managerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeManagerFromTenant(id, managerId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Remove managers from tenant
         * @param {string} [tenantId] tenantId
         * @param {InputRemoveManagersFromTenant} [inputRemoveManagersFromTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeManagersFromTenant(tenantId, inputRemoveManagersFromTenant, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeManagersFromTenant(tenantId, inputRemoveManagersFromTenant, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"delete\", \"User\"]
         * @summary Remove user from tenant | Permission required: [\"delete\", \"User\"]
         * @param {string} userId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromTenant(userId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeUserFromTenant(userId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminTenantApi - factory interface
 * @export
 */
exports.AdminTenantApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminTenantApiFp(configuration);
    return {
        /**
         *
         * @summary Add manager to tenant
         * @param {string} id
         * @param {string} managerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addManagerToTenant(id, managerId, options) {
            return localVarFp.addManagerToTenant(id, managerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Add managers to tenant
         * @param {string} [tenantId] tenantId
         * @param {InputAddManagersToTenant} [inputAddManagersToTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addManagersToTenant(tenantId, inputAddManagersToTenant, options) {
            return localVarFp.addManagersToTenant(tenantId, inputAddManagersToTenant, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"create\", \"User\"]
         * @summary Add user to tenant | Permission required: [\"create\", \"User\"]
         * @param {string} [tenantId] tenantId
         * @param {EditCreateUser} [editCreateUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToTenant(tenantId, editCreateUser, options) {
            return localVarFp.addUserToTenant(tenantId, editCreateUser, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"create\", \"Tenant\"]
         * @summary Create tenant | Permission required: [\"create\", \"Tenant\"]
         * @param {EditCreateTenant} [editCreateTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant(editCreateTenant, options) {
            return localVarFp.createTenant(editCreateTenant, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"manage\", \"Tenant\"]
         * @summary Edit admin tenant by id | Permission required: [\"manage\", \"Tenant\"]
         * @param {string} id
         * @param {EditCreateTenant} [editCreateTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminTenantById(id, editCreateTenant, options) {
            return localVarFp.editAdminTenantById(id, editCreateTenant, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Tenant\"]
         * @summary Edit tenant services | Permission required: [\"update\", \"Tenant\"]
         * @param {string} [tenantId] tenantId
         * @param {InputEditTenantServices} [inputEditTenantServices]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTenantServices(tenantId, inputEditTenantServices, options) {
            return localVarFp.editTenantServices(tenantId, inputEditTenantServices, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"User\"]
         * @summary Edit user | Permission required: [\"update\", \"User\"]
         * @param {string} userId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateUser} [editCreateUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser(userId, tenantId, editCreateUser, options) {
            return localVarFp.editUser(userId, tenantId, editCreateUser, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin tenant by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTenantById(id, options) {
            return localVarFp.getAdminTenantById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Tenant\"]
         * @summary Get all tenants | Permission required: [\"readAll\", \"Tenant\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTenants(filter, options) {
            return localVarFp.getAllTenants(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Tenant\"]
         * @summary Get tenant managers | Permission required: [\"readAll\", \"Tenant\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantManagers(filter, tenantId, options) {
            return localVarFp.getTenantManagers(filter, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"User\"]
         * @summary Get tenant users | Permission required: [\"readAll\", \"User\"]
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'common' | 'mentor' | 'all'} [userType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantUsers(tenantId, filter, userType, options) {
            return localVarFp.getTenantUsers(tenantId, filter, userType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove manager from tenant
         * @param {string} id
         * @param {string} managerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeManagerFromTenant(id, managerId, options) {
            return localVarFp.removeManagerFromTenant(id, managerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove managers from tenant
         * @param {string} [tenantId] tenantId
         * @param {InputRemoveManagersFromTenant} [inputRemoveManagersFromTenant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeManagersFromTenant(tenantId, inputRemoveManagersFromTenant, options) {
            return localVarFp.removeManagersFromTenant(tenantId, inputRemoveManagersFromTenant, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"delete\", \"User\"]
         * @summary Remove user from tenant | Permission required: [\"delete\", \"User\"]
         * @param {string} userId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromTenant(userId, tenantId, options) {
            return localVarFp.removeUserFromTenant(userId, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminTenantApi - object-oriented interface
 * @export
 * @class AdminTenantApi
 * @extends {BaseAPI}
 */
class AdminTenantApi extends base_1.BaseAPI {
    /**
     *
     * @summary Add manager to tenant
     * @param {string} id
     * @param {string} managerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    addManagerToTenant(id, managerId, options) {
        return exports.AdminTenantApiFp(this.configuration).addManagerToTenant(id, managerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Add managers to tenant
     * @param {string} [tenantId] tenantId
     * @param {InputAddManagersToTenant} [inputAddManagersToTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    addManagersToTenant(tenantId, inputAddManagersToTenant, options) {
        return exports.AdminTenantApiFp(this.configuration).addManagersToTenant(tenantId, inputAddManagersToTenant, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"create\", \"User\"]
     * @summary Add user to tenant | Permission required: [\"create\", \"User\"]
     * @param {string} [tenantId] tenantId
     * @param {EditCreateUser} [editCreateUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    addUserToTenant(tenantId, editCreateUser, options) {
        return exports.AdminTenantApiFp(this.configuration).addUserToTenant(tenantId, editCreateUser, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"create\", \"Tenant\"]
     * @summary Create tenant | Permission required: [\"create\", \"Tenant\"]
     * @param {EditCreateTenant} [editCreateTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    createTenant(editCreateTenant, options) {
        return exports.AdminTenantApiFp(this.configuration).createTenant(editCreateTenant, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"manage\", \"Tenant\"]
     * @summary Edit admin tenant by id | Permission required: [\"manage\", \"Tenant\"]
     * @param {string} id
     * @param {EditCreateTenant} [editCreateTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    editAdminTenantById(id, editCreateTenant, options) {
        return exports.AdminTenantApiFp(this.configuration).editAdminTenantById(id, editCreateTenant, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Tenant\"]
     * @summary Edit tenant services | Permission required: [\"update\", \"Tenant\"]
     * @param {string} [tenantId] tenantId
     * @param {InputEditTenantServices} [inputEditTenantServices]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    editTenantServices(tenantId, inputEditTenantServices, options) {
        return exports.AdminTenantApiFp(this.configuration).editTenantServices(tenantId, inputEditTenantServices, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"User\"]
     * @summary Edit user | Permission required: [\"update\", \"User\"]
     * @param {string} userId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateUser} [editCreateUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    editUser(userId, tenantId, editCreateUser, options) {
        return exports.AdminTenantApiFp(this.configuration).editUser(userId, tenantId, editCreateUser, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin tenant by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    getAdminTenantById(id, options) {
        return exports.AdminTenantApiFp(this.configuration).getAdminTenantById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Tenant\"]
     * @summary Get all tenants | Permission required: [\"readAll\", \"Tenant\"]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    getAllTenants(filter, options) {
        return exports.AdminTenantApiFp(this.configuration).getAllTenants(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Tenant\"]
     * @summary Get tenant managers | Permission required: [\"readAll\", \"Tenant\"]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    getTenantManagers(filter, tenantId, options) {
        return exports.AdminTenantApiFp(this.configuration).getTenantManagers(filter, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"User\"]
     * @summary Get tenant users | Permission required: [\"readAll\", \"User\"]
     * @param {string} [tenantId] tenantId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {'common' | 'mentor' | 'all'} [userType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    getTenantUsers(tenantId, filter, userType, options) {
        return exports.AdminTenantApiFp(this.configuration).getTenantUsers(tenantId, filter, userType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove manager from tenant
     * @param {string} id
     * @param {string} managerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    removeManagerFromTenant(id, managerId, options) {
        return exports.AdminTenantApiFp(this.configuration).removeManagerFromTenant(id, managerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove managers from tenant
     * @param {string} [tenantId] tenantId
     * @param {InputRemoveManagersFromTenant} [inputRemoveManagersFromTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    removeManagersFromTenant(tenantId, inputRemoveManagersFromTenant, options) {
        return exports.AdminTenantApiFp(this.configuration).removeManagersFromTenant(tenantId, inputRemoveManagersFromTenant, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"delete\", \"User\"]
     * @summary Remove user from tenant | Permission required: [\"delete\", \"User\"]
     * @param {string} userId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantApi
     */
    removeUserFromTenant(userId, tenantId, options) {
        return exports.AdminTenantApiFp(this.configuration).removeUserFromTenant(userId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminTenantApi = AdminTenantApi;
