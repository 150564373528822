"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminSprintInteractionApi = exports.AdminSprintInteractionApiFactory = exports.AdminSprintInteractionApiFp = exports.AdminSprintInteractionApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminSprintInteractionApi - axios parameter creator
 * @export
 */
exports.AdminSprintInteractionApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get admin evaluation
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEvaluation: (sprintInteractionId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintInteractionId' is not null or undefined
            common_1.assertParamExists('getAdminEvaluation', 'sprintInteractionId', sprintInteractionId);
            const localVarPath = `/admin/sprintInteraction/{sprintInteractionId}/evaluation/`
                .replace(`{${"sprintInteractionId"}}`, encodeURIComponent(String(sprintInteractionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get sprint answers
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSprintAnswers: (sprintInteractionId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintInteractionId' is not null or undefined
            common_1.assertParamExists('getSprintAnswers', 'sprintInteractionId', sprintInteractionId);
            const localVarPath = `/admin/sprintInteraction/{sprintInteractionId}/answers/`
                .replace(`{${"sprintInteractionId"}}`, encodeURIComponent(String(sprintInteractionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Give admin evaluation
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {InputGiveAdminEvaluation} [inputGiveAdminEvaluation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveAdminEvaluation: (sprintInteractionId, tenantId, inputGiveAdminEvaluation, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintInteractionId' is not null or undefined
            common_1.assertParamExists('giveAdminEvaluation', 'sprintInteractionId', sprintInteractionId);
            const localVarPath = `/admin/sprintInteraction/{sprintInteractionId}/evaluation/`
                .replace(`{${"sprintInteractionId"}}`, encodeURIComponent(String(sprintInteractionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputGiveAdminEvaluation, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Skip admin evaluation
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipAdminEvaluation: (sprintInteractionId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintInteractionId' is not null or undefined
            common_1.assertParamExists('skipAdminEvaluation', 'sprintInteractionId', sprintInteractionId);
            const localVarPath = `/admin/sprintInteraction/{sprintInteractionId}/evaluation/skip/`
                .replace(`{${"sprintInteractionId"}}`, encodeURIComponent(String(sprintInteractionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminSprintInteractionApi - functional programming interface
 * @export
 */
exports.AdminSprintInteractionApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminSprintInteractionApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get admin evaluation
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEvaluation(sprintInteractionId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminEvaluation(sprintInteractionId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get sprint answers
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSprintAnswers(sprintInteractionId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSprintAnswers(sprintInteractionId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Give admin evaluation
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {InputGiveAdminEvaluation} [inputGiveAdminEvaluation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveAdminEvaluation(sprintInteractionId, tenantId, inputGiveAdminEvaluation, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.giveAdminEvaluation(sprintInteractionId, tenantId, inputGiveAdminEvaluation, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Skip admin evaluation
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipAdminEvaluation(sprintInteractionId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.skipAdminEvaluation(sprintInteractionId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminSprintInteractionApi - factory interface
 * @export
 */
exports.AdminSprintInteractionApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminSprintInteractionApiFp(configuration);
    return {
        /**
         *
         * @summary Get admin evaluation
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEvaluation(sprintInteractionId, tenantId, options) {
            return localVarFp.getAdminEvaluation(sprintInteractionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get sprint answers
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSprintAnswers(sprintInteractionId, tenantId, options) {
            return localVarFp.getSprintAnswers(sprintInteractionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Give admin evaluation
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {InputGiveAdminEvaluation} [inputGiveAdminEvaluation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveAdminEvaluation(sprintInteractionId, tenantId, inputGiveAdminEvaluation, options) {
            return localVarFp.giveAdminEvaluation(sprintInteractionId, tenantId, inputGiveAdminEvaluation, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Skip admin evaluation
         * @param {string} sprintInteractionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipAdminEvaluation(sprintInteractionId, tenantId, options) {
            return localVarFp.skipAdminEvaluation(sprintInteractionId, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminSprintInteractionApi - object-oriented interface
 * @export
 * @class AdminSprintInteractionApi
 * @extends {BaseAPI}
 */
class AdminSprintInteractionApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get admin evaluation
     * @param {string} sprintInteractionId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintInteractionApi
     */
    getAdminEvaluation(sprintInteractionId, tenantId, options) {
        return exports.AdminSprintInteractionApiFp(this.configuration).getAdminEvaluation(sprintInteractionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get sprint answers
     * @param {string} sprintInteractionId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintInteractionApi
     */
    getSprintAnswers(sprintInteractionId, tenantId, options) {
        return exports.AdminSprintInteractionApiFp(this.configuration).getSprintAnswers(sprintInteractionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Give admin evaluation
     * @param {string} sprintInteractionId
     * @param {string} [tenantId] tenantId
     * @param {InputGiveAdminEvaluation} [inputGiveAdminEvaluation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintInteractionApi
     */
    giveAdminEvaluation(sprintInteractionId, tenantId, inputGiveAdminEvaluation, options) {
        return exports.AdminSprintInteractionApiFp(this.configuration).giveAdminEvaluation(sprintInteractionId, tenantId, inputGiveAdminEvaluation, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Skip admin evaluation
     * @param {string} sprintInteractionId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintInteractionApi
     */
    skipAdminEvaluation(sprintInteractionId, tenantId, options) {
        return exports.AdminSprintInteractionApiFp(this.configuration).skipAdminEvaluation(sprintInteractionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminSprintInteractionApi = AdminSprintInteractionApi;
