"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeepeningSectionApi = exports.DeepeningSectionApiFactory = exports.DeepeningSectionApiFp = exports.DeepeningSectionApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * DeepeningSectionApi - axios parameter creator
 * @export
 */
exports.DeepeningSectionApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get all deepening section lessons
         * @param {string} deepeningId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeepeningSectionLessons: (deepeningId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deepeningId' is not null or undefined
            common_1.assertParamExists('getAllDeepeningSectionLessons', 'deepeningId', deepeningId);
            const localVarPath = `/deepeningSection/{deepeningId}/lesson/`
                .replace(`{${"deepeningId"}}`, encodeURIComponent(String(deepeningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all deepening sections
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeepeningSections: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/deepeningSection/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get deepening section by id
         * @param {string} deepeningId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeepeningSectionById: (deepeningId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deepeningId' is not null or undefined
            common_1.assertParamExists('getDeepeningSectionById', 'deepeningId', deepeningId);
            const localVarPath = `/deepeningSection/{deepeningId}/`
                .replace(`{${"deepeningId"}}`, encodeURIComponent(String(deepeningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get deepening section sidebar
         * @param {string} deepeningId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeepeningSectionSidebar: (deepeningId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deepeningId' is not null or undefined
            common_1.assertParamExists('getDeepeningSectionSidebar', 'deepeningId', deepeningId);
            const localVarPath = `/deepeningSection/{deepeningId}/sidebar/`
                .replace(`{${"deepeningId"}}`, encodeURIComponent(String(deepeningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DeepeningSectionApi - functional programming interface
 * @export
 */
exports.DeepeningSectionApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.DeepeningSectionApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get all deepening section lessons
         * @param {string} deepeningId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeepeningSectionLessons(deepeningId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllDeepeningSectionLessons(deepeningId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all deepening sections
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeepeningSections(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllDeepeningSections(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get deepening section by id
         * @param {string} deepeningId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeepeningSectionById(deepeningId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDeepeningSectionById(deepeningId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get deepening section sidebar
         * @param {string} deepeningId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeepeningSectionSidebar(deepeningId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDeepeningSectionSidebar(deepeningId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * DeepeningSectionApi - factory interface
 * @export
 */
exports.DeepeningSectionApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.DeepeningSectionApiFp(configuration);
    return {
        /**
         *
         * @summary Get all deepening section lessons
         * @param {string} deepeningId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeepeningSectionLessons(deepeningId, filter, options) {
            return localVarFp.getAllDeepeningSectionLessons(deepeningId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all deepening sections
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeepeningSections(filter, options) {
            return localVarFp.getAllDeepeningSections(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get deepening section by id
         * @param {string} deepeningId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeepeningSectionById(deepeningId, filter, options) {
            return localVarFp.getDeepeningSectionById(deepeningId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get deepening section sidebar
         * @param {string} deepeningId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeepeningSectionSidebar(deepeningId, filter, options) {
            return localVarFp.getDeepeningSectionSidebar(deepeningId, filter, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DeepeningSectionApi - object-oriented interface
 * @export
 * @class DeepeningSectionApi
 * @extends {BaseAPI}
 */
class DeepeningSectionApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get all deepening section lessons
     * @param {string} deepeningId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeepeningSectionApi
     */
    getAllDeepeningSectionLessons(deepeningId, filter, options) {
        return exports.DeepeningSectionApiFp(this.configuration).getAllDeepeningSectionLessons(deepeningId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all deepening sections
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeepeningSectionApi
     */
    getAllDeepeningSections(filter, options) {
        return exports.DeepeningSectionApiFp(this.configuration).getAllDeepeningSections(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get deepening section by id
     * @param {string} deepeningId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeepeningSectionApi
     */
    getDeepeningSectionById(deepeningId, filter, options) {
        return exports.DeepeningSectionApiFp(this.configuration).getDeepeningSectionById(deepeningId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get deepening section sidebar
     * @param {string} deepeningId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeepeningSectionApi
     */
    getDeepeningSectionSidebar(deepeningId, filter, options) {
        return exports.DeepeningSectionApiFp(this.configuration).getDeepeningSectionSidebar(deepeningId, filter, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DeepeningSectionApi = DeepeningSectionApi;
