import { TableCell, TableHead, TableRow } from "@mui/material";
import type { DataTableColumn } from "../interfaces/DataTable";

type DataTableHeadProps<T> = {
	columns?: DataTableColumn<T>[];
};

function DataTableHead<T>(props: DataTableHeadProps<T>) {
	const { columns } = props;

	return (
		<TableHead>
			<TableRow>
				{columns?.map((column) => (
					<TableCell children={column.label} key={column.id as string} {...column.columnProps} />
				))}
			</TableRow>
		</TableHead>
	);
}

export default DataTableHead;
