"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LessonApi = exports.LessonApiFactory = exports.LessonApiFp = exports.LessonApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * LessonApi - axios parameter creator
 * @export
 */
exports.LessonApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get all favorite lessons
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFavoriteLessons: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/lesson/favorite/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Lesson\"]
         * @summary Get all lessons | Permission required: [\"readAll\", \"Lesson\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLessons: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/lesson/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get favorite lessons sidebar
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteLessonsSidebar: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/lesson/favorite/sidebar/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Lesson\"]
         * @summary Get lesson by id | Permission required: [\"readOne\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonById: (lessonId, learningModuleId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'lessonId' is not null or undefined
            common_1.assertParamExists('getLessonById', 'lessonId', lessonId);
            const localVarPath = `/lesson/{lessonId}/`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (learningModuleId !== undefined) {
                localVarQueryParameter['learningModuleId'] = learningModuleId;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get lesson history
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonHistory: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/lesson/history/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get lesson resume
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonResume: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/lesson/resume/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get lessons history sidebar
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonsHistorySidebar: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/lesson/history/sidebar/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get suggested lessons sidebar
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestedLessonsSidebar: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/lesson/suggested/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Set lesson as completed
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLessonAsCompleted: (lessonId, learningModuleId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'lessonId' is not null or undefined
            common_1.assertParamExists('setLessonAsCompleted', 'lessonId', lessonId);
            const localVarPath = `/lesson/{lessonId}/complete/`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (learningModuleId !== undefined) {
                localVarQueryParameter['learningModuleId'] = learningModuleId;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Lesson\"]
         * @summary Set lesson as favorite | Permission required: [\"readOne\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLessonAsFavorite: (lessonId, learningModuleId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'lessonId' is not null or undefined
            common_1.assertParamExists('setLessonAsFavorite', 'lessonId', lessonId);
            const localVarPath = `/lesson/{lessonId}/favorite/`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (learningModuleId !== undefined) {
                localVarQueryParameter['learningModuleId'] = learningModuleId;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Set lesson as uncompleted
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLessonAsUncompleted: (lessonId, learningModuleId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'lessonId' is not null or undefined
            common_1.assertParamExists('setLessonAsUncompleted', 'lessonId', lessonId);
            const localVarPath = `/lesson/{lessonId}/complete/`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (learningModuleId !== undefined) {
                localVarQueryParameter['learningModuleId'] = learningModuleId;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Lesson\"]
         * @summary Set lesson progress | Permission required: [\"readOne\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {InputSetLessonProgress} [inputSetLessonProgress]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLessonProgress: (lessonId, learningModuleId, inputSetLessonProgress, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'lessonId' is not null or undefined
            common_1.assertParamExists('setLessonProgress', 'lessonId', lessonId);
            const localVarPath = `/lesson/{lessonId}/progress/`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (learningModuleId !== undefined) {
                localVarQueryParameter['learningModuleId'] = learningModuleId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputSetLessonProgress, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Unset lesson as favorite
         * @param {string} lessonId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsetLessonAsFavorite: (lessonId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'lessonId' is not null or undefined
            common_1.assertParamExists('unsetLessonAsFavorite', 'lessonId', lessonId);
            const localVarPath = `/lesson/{lessonId}/favorite/`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * LessonApi - functional programming interface
 * @export
 */
exports.LessonApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.LessonApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get all favorite lessons
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFavoriteLessons(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllFavoriteLessons(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Lesson\"]
         * @summary Get all lessons | Permission required: [\"readAll\", \"Lesson\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLessons(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllLessons(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get favorite lessons sidebar
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteLessonsSidebar(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFavoriteLessonsSidebar(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Lesson\"]
         * @summary Get lesson by id | Permission required: [\"readOne\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonById(lessonId, learningModuleId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLessonById(lessonId, learningModuleId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get lesson history
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonHistory(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLessonHistory(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get lesson resume
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonResume(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLessonResume(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get lessons history sidebar
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonsHistorySidebar(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLessonsHistorySidebar(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get suggested lessons sidebar
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestedLessonsSidebar(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSuggestedLessonsSidebar(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Set lesson as completed
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLessonAsCompleted(lessonId, learningModuleId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setLessonAsCompleted(lessonId, learningModuleId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Lesson\"]
         * @summary Set lesson as favorite | Permission required: [\"readOne\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLessonAsFavorite(lessonId, learningModuleId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setLessonAsFavorite(lessonId, learningModuleId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Set lesson as uncompleted
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLessonAsUncompleted(lessonId, learningModuleId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setLessonAsUncompleted(lessonId, learningModuleId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Lesson\"]
         * @summary Set lesson progress | Permission required: [\"readOne\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {InputSetLessonProgress} [inputSetLessonProgress]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLessonProgress(lessonId, learningModuleId, inputSetLessonProgress, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setLessonProgress(lessonId, learningModuleId, inputSetLessonProgress, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Unset lesson as favorite
         * @param {string} lessonId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsetLessonAsFavorite(lessonId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unsetLessonAsFavorite(lessonId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * LessonApi - factory interface
 * @export
 */
exports.LessonApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.LessonApiFp(configuration);
    return {
        /**
         *
         * @summary Get all favorite lessons
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFavoriteLessons(filter, options) {
            return localVarFp.getAllFavoriteLessons(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Lesson\"]
         * @summary Get all lessons | Permission required: [\"readAll\", \"Lesson\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLessons(filter, options) {
            return localVarFp.getAllLessons(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get favorite lessons sidebar
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteLessonsSidebar(filter, options) {
            return localVarFp.getFavoriteLessonsSidebar(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Lesson\"]
         * @summary Get lesson by id | Permission required: [\"readOne\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonById(lessonId, learningModuleId, options) {
            return localVarFp.getLessonById(lessonId, learningModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get lesson history
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonHistory(filter, options) {
            return localVarFp.getLessonHistory(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get lesson resume
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonResume(options) {
            return localVarFp.getLessonResume(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get lessons history sidebar
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonsHistorySidebar(filter, options) {
            return localVarFp.getLessonsHistorySidebar(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get suggested lessons sidebar
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestedLessonsSidebar(filter, options) {
            return localVarFp.getSuggestedLessonsSidebar(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set lesson as completed
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLessonAsCompleted(lessonId, learningModuleId, options) {
            return localVarFp.setLessonAsCompleted(lessonId, learningModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Lesson\"]
         * @summary Set lesson as favorite | Permission required: [\"readOne\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLessonAsFavorite(lessonId, learningModuleId, options) {
            return localVarFp.setLessonAsFavorite(lessonId, learningModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set lesson as uncompleted
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLessonAsUncompleted(lessonId, learningModuleId, options) {
            return localVarFp.setLessonAsUncompleted(lessonId, learningModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Lesson\"]
         * @summary Set lesson progress | Permission required: [\"readOne\", \"Lesson\"]
         * @param {string} lessonId
         * @param {string} [learningModuleId]
         * @param {InputSetLessonProgress} [inputSetLessonProgress]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLessonProgress(lessonId, learningModuleId, inputSetLessonProgress, options) {
            return localVarFp.setLessonProgress(lessonId, learningModuleId, inputSetLessonProgress, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Unset lesson as favorite
         * @param {string} lessonId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsetLessonAsFavorite(lessonId, options) {
            return localVarFp.unsetLessonAsFavorite(lessonId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * LessonApi - object-oriented interface
 * @export
 * @class LessonApi
 * @extends {BaseAPI}
 */
class LessonApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get all favorite lessons
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    getAllFavoriteLessons(filter, options) {
        return exports.LessonApiFp(this.configuration).getAllFavoriteLessons(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Lesson\"]
     * @summary Get all lessons | Permission required: [\"readAll\", \"Lesson\"]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    getAllLessons(filter, options) {
        return exports.LessonApiFp(this.configuration).getAllLessons(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get favorite lessons sidebar
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    getFavoriteLessonsSidebar(filter, options) {
        return exports.LessonApiFp(this.configuration).getFavoriteLessonsSidebar(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Lesson\"]
     * @summary Get lesson by id | Permission required: [\"readOne\", \"Lesson\"]
     * @param {string} lessonId
     * @param {string} [learningModuleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    getLessonById(lessonId, learningModuleId, options) {
        return exports.LessonApiFp(this.configuration).getLessonById(lessonId, learningModuleId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get lesson history
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    getLessonHistory(filter, options) {
        return exports.LessonApiFp(this.configuration).getLessonHistory(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get lesson resume
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    getLessonResume(options) {
        return exports.LessonApiFp(this.configuration).getLessonResume(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get lessons history sidebar
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    getLessonsHistorySidebar(filter, options) {
        return exports.LessonApiFp(this.configuration).getLessonsHistorySidebar(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get suggested lessons sidebar
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    getSuggestedLessonsSidebar(filter, options) {
        return exports.LessonApiFp(this.configuration).getSuggestedLessonsSidebar(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Set lesson as completed
     * @param {string} lessonId
     * @param {string} [learningModuleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    setLessonAsCompleted(lessonId, learningModuleId, options) {
        return exports.LessonApiFp(this.configuration).setLessonAsCompleted(lessonId, learningModuleId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Lesson\"]
     * @summary Set lesson as favorite | Permission required: [\"readOne\", \"Lesson\"]
     * @param {string} lessonId
     * @param {string} [learningModuleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    setLessonAsFavorite(lessonId, learningModuleId, options) {
        return exports.LessonApiFp(this.configuration).setLessonAsFavorite(lessonId, learningModuleId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Set lesson as uncompleted
     * @param {string} lessonId
     * @param {string} [learningModuleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    setLessonAsUncompleted(lessonId, learningModuleId, options) {
        return exports.LessonApiFp(this.configuration).setLessonAsUncompleted(lessonId, learningModuleId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Lesson\"]
     * @summary Set lesson progress | Permission required: [\"readOne\", \"Lesson\"]
     * @param {string} lessonId
     * @param {string} [learningModuleId]
     * @param {InputSetLessonProgress} [inputSetLessonProgress]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    setLessonProgress(lessonId, learningModuleId, inputSetLessonProgress, options) {
        return exports.LessonApiFp(this.configuration).setLessonProgress(lessonId, learningModuleId, inputSetLessonProgress, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Unset lesson as favorite
     * @param {string} lessonId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    unsetLessonAsFavorite(lessonId, options) {
        return exports.LessonApiFp(this.configuration).unsetLessonAsFavorite(lessonId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.LessonApi = LessonApi;
