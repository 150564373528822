"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminSprintContentApi = exports.AdminSprintContentApiFactory = exports.AdminSprintContentApiFp = exports.AdminSprintContentApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminSprintContentApi - axios parameter creator
 * @export
 */
exports.AdminSprintContentApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create sprint content
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprintContent} [editCreateSprintContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSprintContent: (sprintId, tenantId, editCreateSprintContent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintId' is not null or undefined
            common_1.assertParamExists('createSprintContent', 'sprintId', sprintId);
            const localVarPath = `/admin/sprint/{sprintId}/content/`
                .replace(`{${"sprintId"}}`, encodeURIComponent(String(sprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateSprintContent, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete sprint content
         * @param {string} id
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSprintContent: (id, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteSprintContent', 'id', id);
            const localVarPath = `/admin/content/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit sprint content
         * @param {string} id
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprintContent} [editCreateSprintContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSprintContent: (id, tenantId, editCreateSprintContent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editSprintContent', 'id', id);
            const localVarPath = `/admin/content/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateSprintContent, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin sprint contents
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSprintContents: (sprintId, tenantId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintId' is not null or undefined
            common_1.assertParamExists('getAdminSprintContents', 'sprintId', sprintId);
            const localVarPath = `/admin/sprint/{sprintId}/content/`
                .replace(`{${"sprintId"}}`, encodeURIComponent(String(sprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Reorder sprint content
         * @param {string} id
         * @param {string} [tenantId] tenantId
         * @param {InputReorderSprintContent} [inputReorderSprintContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderSprintContent: (id, tenantId, inputReorderSprintContent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('reorderSprintContent', 'id', id);
            const localVarPath = `/admin/content/{id}/order/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputReorderSprintContent, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminSprintContentApi - functional programming interface
 * @export
 */
exports.AdminSprintContentApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminSprintContentApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Create sprint content
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprintContent} [editCreateSprintContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSprintContent(sprintId, tenantId, editCreateSprintContent, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createSprintContent(sprintId, tenantId, editCreateSprintContent, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete sprint content
         * @param {string} id
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSprintContent(id, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteSprintContent(id, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit sprint content
         * @param {string} id
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprintContent} [editCreateSprintContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSprintContent(id, tenantId, editCreateSprintContent, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editSprintContent(id, tenantId, editCreateSprintContent, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin sprint contents
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSprintContents(sprintId, tenantId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminSprintContents(sprintId, tenantId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Reorder sprint content
         * @param {string} id
         * @param {string} [tenantId] tenantId
         * @param {InputReorderSprintContent} [inputReorderSprintContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderSprintContent(id, tenantId, inputReorderSprintContent, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.reorderSprintContent(id, tenantId, inputReorderSprintContent, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminSprintContentApi - factory interface
 * @export
 */
exports.AdminSprintContentApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminSprintContentApiFp(configuration);
    return {
        /**
         *
         * @summary Create sprint content
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprintContent} [editCreateSprintContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSprintContent(sprintId, tenantId, editCreateSprintContent, options) {
            return localVarFp.createSprintContent(sprintId, tenantId, editCreateSprintContent, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete sprint content
         * @param {string} id
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSprintContent(id, tenantId, options) {
            return localVarFp.deleteSprintContent(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit sprint content
         * @param {string} id
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprintContent} [editCreateSprintContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSprintContent(id, tenantId, editCreateSprintContent, options) {
            return localVarFp.editSprintContent(id, tenantId, editCreateSprintContent, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin sprint contents
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSprintContents(sprintId, tenantId, filter, options) {
            return localVarFp.getAdminSprintContents(sprintId, tenantId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Reorder sprint content
         * @param {string} id
         * @param {string} [tenantId] tenantId
         * @param {InputReorderSprintContent} [inputReorderSprintContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderSprintContent(id, tenantId, inputReorderSprintContent, options) {
            return localVarFp.reorderSprintContent(id, tenantId, inputReorderSprintContent, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminSprintContentApi - object-oriented interface
 * @export
 * @class AdminSprintContentApi
 * @extends {BaseAPI}
 */
class AdminSprintContentApi extends base_1.BaseAPI {
    /**
     *
     * @summary Create sprint content
     * @param {string} sprintId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateSprintContent} [editCreateSprintContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintContentApi
     */
    createSprintContent(sprintId, tenantId, editCreateSprintContent, options) {
        return exports.AdminSprintContentApiFp(this.configuration).createSprintContent(sprintId, tenantId, editCreateSprintContent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete sprint content
     * @param {string} id
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintContentApi
     */
    deleteSprintContent(id, tenantId, options) {
        return exports.AdminSprintContentApiFp(this.configuration).deleteSprintContent(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit sprint content
     * @param {string} id
     * @param {string} [tenantId] tenantId
     * @param {EditCreateSprintContent} [editCreateSprintContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintContentApi
     */
    editSprintContent(id, tenantId, editCreateSprintContent, options) {
        return exports.AdminSprintContentApiFp(this.configuration).editSprintContent(id, tenantId, editCreateSprintContent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin sprint contents
     * @param {string} sprintId
     * @param {string} [tenantId] tenantId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintContentApi
     */
    getAdminSprintContents(sprintId, tenantId, filter, options) {
        return exports.AdminSprintContentApiFp(this.configuration).getAdminSprintContents(sprintId, tenantId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Reorder sprint content
     * @param {string} id
     * @param {string} [tenantId] tenantId
     * @param {InputReorderSprintContent} [inputReorderSprintContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintContentApi
     */
    reorderSprintContent(id, tenantId, inputReorderSprintContent, options) {
        return exports.AdminSprintContentApiFp(this.configuration).reorderSprintContent(id, tenantId, inputReorderSprintContent, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminSprintContentApi = AdminSprintContentApi;
