import { Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import OutletContainer from "../../../../../../components/OutletContainer";
import SprintDeliverablesCard from "./components/SprintDeliverablesCard";
import SprintFeedbackCard from "./components/SprintFeedbackCard";
import {
	SprintFeedbacksContextProvider,
	useSprintFeedbacksController,
} from "./controllers/SprintDeliverableController";

type DeliverableFeedbackTabType = "deliverable" | "feedback";

type ProjectDeliverablePageProps = {
	children?: React.ReactNode;
};

const ProjectDeliverablePage: React.FC<ProjectDeliverablePageProps> = () => {
	const sprintFeedbacksController = useSprintFeedbacksController();

	return (
		<OutletContainer fillScreen>
			<SprintFeedbacksContextProvider sprintFeedbacksController={sprintFeedbacksController}>
				<ProjectDeliverablePageWrapper />
			</SprintFeedbacksContextProvider>
		</OutletContainer>
	);
};

const ProjectDeliverablePageWrapper = () => {
	const [tab, setTab] = useState<DeliverableFeedbackTabType>("deliverable");

	return (
		<Stack spacing={8}>
			<Tabs
				value={tab}
				onChange={(event, value: DeliverableFeedbackTabType) => {
					setTab(value);
				}}
			>
				<Tab label="Feedback mancanti" value="feedback" />
				<Tab label="Riepilogo andamento sprint" value="deliverable" />
			</Tabs>
			{tab === "deliverable" && <SprintDeliverablesCard />}
			{tab === "feedback" && <SprintFeedbackCard />}
		</Stack>
	);
};

export default ProjectDeliverablePage;
