import { Box, Drawer, Slide, Toolbar } from "@mui/material";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { DRAWER_WIDTH } from "../constants/ScaffoldDrawerConstants";
import ScaffoldHomeRoutes from "./routes/ScaffoldHomeRoutes";
import ScaffoldTenantsRoutes from "./routes/ScaffoldTenantsRoutes";

type ScaffoldDrawerProps = {
	children?: React.ReactNode;
};

const ScaffoldDrawer: React.FC<ScaffoldDrawerProps> = () => {
	const slideContainer = useRef<HTMLDivElement>(null);
	const { tenantId } = useParams();
	return (
		<Drawer
			variant="permanent"
			elevation={0}
			sx={{
				width: DRAWER_WIDTH,
				flexShrink: 0,
				[`& .MuiDrawer-paper`]: { width: DRAWER_WIDTH, paddingTop: 0 },
			}}
		>
			<Toolbar />
			<Box ref={slideContainer} sx={{ position: "relative", width: "100%", height: "100%" }}>
				<Slide
					direction="right"
					in={tenantId === undefined}
					appear={false}
					mountOnEnter
					unmountOnExit
					container={slideContainer.current}
				>
					<ScaffoldHomeRoutes />
				</Slide>
				<Slide
					direction="left"
					in={tenantId !== undefined}
					appear={false}
					mountOnEnter
					unmountOnExit
					container={slideContainer.current}
				>
					<ScaffoldTenantsRoutes slideContainer={slideContainer.current} />
				</Slide>
			</Box>
		</Drawer>
	);
};
export default ScaffoldDrawer;
