"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminSprintApi = exports.AdminSprintApiFactory = exports.AdminSprintApiFp = exports.AdminSprintApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminSprintApi - axios parameter creator
 * @export
 */
exports.AdminSprintApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add sprint to project
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprint} [editCreateSprint]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSprintToProject: (projectId, tenantId, editCreateSprint, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            common_1.assertParamExists('addSprintToProject', 'projectId', projectId);
            const localVarPath = `/admin/project/{projectId}/sprint/`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateSprint, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete sprint
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSprint: (sprintId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintId' is not null or undefined
            common_1.assertParamExists('deleteSprint', 'sprintId', sprintId);
            const localVarPath = `/admin/sprint/{sprintId}/`
                .replace(`{${"sprintId"}}`, encodeURIComponent(String(sprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit sprint
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprint} [editCreateSprint]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSprint: (sprintId, tenantId, editCreateSprint, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintId' is not null or undefined
            common_1.assertParamExists('editSprint', 'sprintId', sprintId);
            const localVarPath = `/admin/sprint/{sprintId}/`
                .replace(`{${"sprintId"}}`, encodeURIComponent(String(sprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateSprint, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin sprint by id
         * @param {string} sprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSprintById: (sprintId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintId' is not null or undefined
            common_1.assertParamExists('getAdminSprintById', 'sprintId', sprintId);
            const localVarPath = `/admin/sprint/{sprintId}/`
                .replace(`{${"sprintId"}}`, encodeURIComponent(String(sprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin team deliverables
         * @param {string} sprintId
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamDeliverables: (sprintId, teamId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintId' is not null or undefined
            common_1.assertParamExists('getAdminTeamDeliverables', 'sprintId', sprintId);
            // verify required parameter 'teamId' is not null or undefined
            common_1.assertParamExists('getAdminTeamDeliverables', 'teamId', teamId);
            const localVarPath = `/admin/sprint/{sprintId}/team/{teamId}/deliverable`
                .replace(`{${"sprintId"}}`, encodeURIComponent(String(sprintId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all project sprints
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectSprints: (projectId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            common_1.assertParamExists('getAllProjectSprints', 'projectId', projectId);
            const localVarPath = `/admin/project/{projectId}/sprint/`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminSprintApi - functional programming interface
 * @export
 */
exports.AdminSprintApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminSprintApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add sprint to project
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprint} [editCreateSprint]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSprintToProject(projectId, tenantId, editCreateSprint, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addSprintToProject(projectId, tenantId, editCreateSprint, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete sprint
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSprint(sprintId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteSprint(sprintId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit sprint
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprint} [editCreateSprint]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSprint(sprintId, tenantId, editCreateSprint, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editSprint(sprintId, tenantId, editCreateSprint, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin sprint by id
         * @param {string} sprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSprintById(sprintId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminSprintById(sprintId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin team deliverables
         * @param {string} sprintId
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamDeliverables(sprintId, teamId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminTeamDeliverables(sprintId, teamId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all project sprints
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectSprints(projectId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllProjectSprints(projectId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminSprintApi - factory interface
 * @export
 */
exports.AdminSprintApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminSprintApiFp(configuration);
    return {
        /**
         *
         * @summary Add sprint to project
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprint} [editCreateSprint]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSprintToProject(projectId, tenantId, editCreateSprint, options) {
            return localVarFp.addSprintToProject(projectId, tenantId, editCreateSprint, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete sprint
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSprint(sprintId, tenantId, options) {
            return localVarFp.deleteSprint(sprintId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit sprint
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprint} [editCreateSprint]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSprint(sprintId, tenantId, editCreateSprint, options) {
            return localVarFp.editSprint(sprintId, tenantId, editCreateSprint, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin sprint by id
         * @param {string} sprintId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSprintById(sprintId, options) {
            return localVarFp.getAdminSprintById(sprintId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin team deliverables
         * @param {string} sprintId
         * @param {string} teamId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamDeliverables(sprintId, teamId, tenantId, options) {
            return localVarFp.getAdminTeamDeliverables(sprintId, teamId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all project sprints
         * @param {string} projectId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectSprints(projectId, tenantId, options) {
            return localVarFp.getAllProjectSprints(projectId, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminSprintApi - object-oriented interface
 * @export
 * @class AdminSprintApi
 * @extends {BaseAPI}
 */
class AdminSprintApi extends base_1.BaseAPI {
    /**
     *
     * @summary Add sprint to project
     * @param {string} projectId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateSprint} [editCreateSprint]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintApi
     */
    addSprintToProject(projectId, tenantId, editCreateSprint, options) {
        return exports.AdminSprintApiFp(this.configuration).addSprintToProject(projectId, tenantId, editCreateSprint, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete sprint
     * @param {string} sprintId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintApi
     */
    deleteSprint(sprintId, tenantId, options) {
        return exports.AdminSprintApiFp(this.configuration).deleteSprint(sprintId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit sprint
     * @param {string} sprintId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateSprint} [editCreateSprint]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintApi
     */
    editSprint(sprintId, tenantId, editCreateSprint, options) {
        return exports.AdminSprintApiFp(this.configuration).editSprint(sprintId, tenantId, editCreateSprint, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin sprint by id
     * @param {string} sprintId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintApi
     */
    getAdminSprintById(sprintId, options) {
        return exports.AdminSprintApiFp(this.configuration).getAdminSprintById(sprintId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin team deliverables
     * @param {string} sprintId
     * @param {string} teamId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintApi
     */
    getAdminTeamDeliverables(sprintId, teamId, tenantId, options) {
        return exports.AdminSprintApiFp(this.configuration).getAdminTeamDeliverables(sprintId, teamId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all project sprints
     * @param {string} projectId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintApi
     */
    getAllProjectSprints(projectId, tenantId, options) {
        return exports.AdminSprintApiFp(this.configuration).getAllProjectSprints(projectId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminSprintApi = AdminSprintApi;
