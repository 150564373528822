"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminSprintQuestionApi = exports.AdminSprintQuestionApiFactory = exports.AdminSprintQuestionApiFp = exports.AdminSprintQuestionApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminSprintQuestionApi - axios parameter creator
 * @export
 */
exports.AdminSprintQuestionApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"create\", \"SprintQuestion\"]
         * @summary Create sprint question | Permission required: [\"create\", \"SprintQuestion\"]
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprintQuestion} [editCreateSprintQuestion]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSprintQuestion: (sprintId, tenantId, editCreateSprintQuestion, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintId' is not null or undefined
            common_1.assertParamExists('createSprintQuestion', 'sprintId', sprintId);
            const localVarPath = `/admin/sprint/{sprintId}/question/`
                .replace(`{${"sprintId"}}`, encodeURIComponent(String(sprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateSprintQuestion, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"delete\", \"SprintQuestion\"]
         * @summary Delete sprint question | Permission required: [\"delete\", \"SprintQuestion\"]
         * @param {string} sprintQuestionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSprintQuestion: (sprintQuestionId, tenantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintQuestionId' is not null or undefined
            common_1.assertParamExists('deleteSprintQuestion', 'sprintQuestionId', sprintQuestionId);
            const localVarPath = `/admin/sprintQuestion/{sprintQuestionId}/`
                .replace(`{${"sprintQuestionId"}}`, encodeURIComponent(String(sprintQuestionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"SprintQuestion\"]
         * @summary Edit sprint question | Permission required: [\"update\", \"SprintQuestion\"]
         * @param {string} sprintQuestionId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprintQuestion} [editCreateSprintQuestion]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSprintQuestion: (sprintQuestionId, tenantId, editCreateSprintQuestion, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintQuestionId' is not null or undefined
            common_1.assertParamExists('editSprintQuestion', 'sprintQuestionId', sprintQuestionId);
            const localVarPath = `/admin/sprintQuestion/{sprintQuestionId}/`
                .replace(`{${"sprintQuestionId"}}`, encodeURIComponent(String(sprintQuestionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateSprintQuestion, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"SprintQuestion\"]
         * @summary Get all admin sprint questions in sprint | Permission required: [\"readAll\", \"SprintQuestion\"]
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminSprintQuestionsInSprint: (sprintId, tenantId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintId' is not null or undefined
            common_1.assertParamExists('getAllAdminSprintQuestionsInSprint', 'sprintId', sprintId);
            const localVarPath = `/admin/sprint/{sprintId}/question/`
                .replace(`{${"sprintId"}}`, encodeURIComponent(String(sprintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Reorder sprint question
         * @param {string} sprintQuestionId
         * @param {string} [tenantId] tenantId
         * @param {InputReorderSprintQuestion} [inputReorderSprintQuestion]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderSprintQuestion: (sprintQuestionId, tenantId, inputReorderSprintQuestion, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sprintQuestionId' is not null or undefined
            common_1.assertParamExists('reorderSprintQuestion', 'sprintQuestionId', sprintQuestionId);
            const localVarPath = `/admin/sprintQuestion/{sprintQuestionId}/order/`
                .replace(`{${"sprintQuestionId"}}`, encodeURIComponent(String(sprintQuestionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tenantId !== undefined && tenantId !== null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputReorderSprintQuestion, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminSprintQuestionApi - functional programming interface
 * @export
 */
exports.AdminSprintQuestionApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminSprintQuestionApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"SprintQuestion\"]
         * @summary Create sprint question | Permission required: [\"create\", \"SprintQuestion\"]
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprintQuestion} [editCreateSprintQuestion]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSprintQuestion(sprintId, tenantId, editCreateSprintQuestion, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createSprintQuestion(sprintId, tenantId, editCreateSprintQuestion, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"delete\", \"SprintQuestion\"]
         * @summary Delete sprint question | Permission required: [\"delete\", \"SprintQuestion\"]
         * @param {string} sprintQuestionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSprintQuestion(sprintQuestionId, tenantId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteSprintQuestion(sprintQuestionId, tenantId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"SprintQuestion\"]
         * @summary Edit sprint question | Permission required: [\"update\", \"SprintQuestion\"]
         * @param {string} sprintQuestionId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprintQuestion} [editCreateSprintQuestion]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSprintQuestion(sprintQuestionId, tenantId, editCreateSprintQuestion, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editSprintQuestion(sprintQuestionId, tenantId, editCreateSprintQuestion, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"SprintQuestion\"]
         * @summary Get all admin sprint questions in sprint | Permission required: [\"readAll\", \"SprintQuestion\"]
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminSprintQuestionsInSprint(sprintId, tenantId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllAdminSprintQuestionsInSprint(sprintId, tenantId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Reorder sprint question
         * @param {string} sprintQuestionId
         * @param {string} [tenantId] tenantId
         * @param {InputReorderSprintQuestion} [inputReorderSprintQuestion]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderSprintQuestion(sprintQuestionId, tenantId, inputReorderSprintQuestion, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.reorderSprintQuestion(sprintQuestionId, tenantId, inputReorderSprintQuestion, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminSprintQuestionApi - factory interface
 * @export
 */
exports.AdminSprintQuestionApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminSprintQuestionApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"SprintQuestion\"]
         * @summary Create sprint question | Permission required: [\"create\", \"SprintQuestion\"]
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprintQuestion} [editCreateSprintQuestion]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSprintQuestion(sprintId, tenantId, editCreateSprintQuestion, options) {
            return localVarFp.createSprintQuestion(sprintId, tenantId, editCreateSprintQuestion, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"delete\", \"SprintQuestion\"]
         * @summary Delete sprint question | Permission required: [\"delete\", \"SprintQuestion\"]
         * @param {string} sprintQuestionId
         * @param {string} [tenantId] tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSprintQuestion(sprintQuestionId, tenantId, options) {
            return localVarFp.deleteSprintQuestion(sprintQuestionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"SprintQuestion\"]
         * @summary Edit sprint question | Permission required: [\"update\", \"SprintQuestion\"]
         * @param {string} sprintQuestionId
         * @param {string} [tenantId] tenantId
         * @param {EditCreateSprintQuestion} [editCreateSprintQuestion]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSprintQuestion(sprintQuestionId, tenantId, editCreateSprintQuestion, options) {
            return localVarFp.editSprintQuestion(sprintQuestionId, tenantId, editCreateSprintQuestion, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"SprintQuestion\"]
         * @summary Get all admin sprint questions in sprint | Permission required: [\"readAll\", \"SprintQuestion\"]
         * @param {string} sprintId
         * @param {string} [tenantId] tenantId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminSprintQuestionsInSprint(sprintId, tenantId, filter, options) {
            return localVarFp.getAllAdminSprintQuestionsInSprint(sprintId, tenantId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Reorder sprint question
         * @param {string} sprintQuestionId
         * @param {string} [tenantId] tenantId
         * @param {InputReorderSprintQuestion} [inputReorderSprintQuestion]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderSprintQuestion(sprintQuestionId, tenantId, inputReorderSprintQuestion, options) {
            return localVarFp.reorderSprintQuestion(sprintQuestionId, tenantId, inputReorderSprintQuestion, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminSprintQuestionApi - object-oriented interface
 * @export
 * @class AdminSprintQuestionApi
 * @extends {BaseAPI}
 */
class AdminSprintQuestionApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"create\", \"SprintQuestion\"]
     * @summary Create sprint question | Permission required: [\"create\", \"SprintQuestion\"]
     * @param {string} sprintId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateSprintQuestion} [editCreateSprintQuestion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintQuestionApi
     */
    createSprintQuestion(sprintId, tenantId, editCreateSprintQuestion, options) {
        return exports.AdminSprintQuestionApiFp(this.configuration).createSprintQuestion(sprintId, tenantId, editCreateSprintQuestion, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"delete\", \"SprintQuestion\"]
     * @summary Delete sprint question | Permission required: [\"delete\", \"SprintQuestion\"]
     * @param {string} sprintQuestionId
     * @param {string} [tenantId] tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintQuestionApi
     */
    deleteSprintQuestion(sprintQuestionId, tenantId, options) {
        return exports.AdminSprintQuestionApiFp(this.configuration).deleteSprintQuestion(sprintQuestionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"SprintQuestion\"]
     * @summary Edit sprint question | Permission required: [\"update\", \"SprintQuestion\"]
     * @param {string} sprintQuestionId
     * @param {string} [tenantId] tenantId
     * @param {EditCreateSprintQuestion} [editCreateSprintQuestion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintQuestionApi
     */
    editSprintQuestion(sprintQuestionId, tenantId, editCreateSprintQuestion, options) {
        return exports.AdminSprintQuestionApiFp(this.configuration).editSprintQuestion(sprintQuestionId, tenantId, editCreateSprintQuestion, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"SprintQuestion\"]
     * @summary Get all admin sprint questions in sprint | Permission required: [\"readAll\", \"SprintQuestion\"]
     * @param {string} sprintId
     * @param {string} [tenantId] tenantId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintQuestionApi
     */
    getAllAdminSprintQuestionsInSprint(sprintId, tenantId, filter, options) {
        return exports.AdminSprintQuestionApiFp(this.configuration).getAllAdminSprintQuestionsInSprint(sprintId, tenantId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Reorder sprint question
     * @param {string} sprintQuestionId
     * @param {string} [tenantId] tenantId
     * @param {InputReorderSprintQuestion} [inputReorderSprintQuestion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSprintQuestionApi
     */
    reorderSprintQuestion(sprintQuestionId, tenantId, inputReorderSprintQuestion, options) {
        return exports.AdminSprintQuestionApiFp(this.configuration).reorderSprintQuestion(sprintQuestionId, tenantId, inputReorderSprintQuestion, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminSprintQuestionApi = AdminSprintQuestionApi;
