"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MentorProjectApi = exports.MentorProjectApiFactory = exports.MentorProjectApiFp = exports.MentorProjectApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * MentorProjectApi - axios parameter creator
 * @export
 */
exports.MentorProjectApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"readAll\", \"Project\"]
         * @summary Get all mentored projects | Permission required: [\"readAll\", \"Project\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMentoredProjects: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/project/mentored/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Project\"]
         * @summary Get mentor dashboard | Permission required: [\"readAll\", \"Project\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMentorDashboard: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/project/mentored/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Project\"]
         * @summary Get project teams summaries | Permission required: [\"readOne\", \"Project\"]
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTeamsSummaries: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getProjectTeamsSummaries', 'id', id);
            const localVarPath = `/project/{id}/mentor/teamsSummaries`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * MentorProjectApi - functional programming interface
 * @export
 */
exports.MentorProjectApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.MentorProjectApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"readAll\", \"Project\"]
         * @summary Get all mentored projects | Permission required: [\"readAll\", \"Project\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMentoredProjects(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllMentoredProjects(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Project\"]
         * @summary Get mentor dashboard | Permission required: [\"readAll\", \"Project\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMentorDashboard(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMentorDashboard(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Project\"]
         * @summary Get project teams summaries | Permission required: [\"readOne\", \"Project\"]
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTeamsSummaries(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getProjectTeamsSummaries(id, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * MentorProjectApi - factory interface
 * @export
 */
exports.MentorProjectApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.MentorProjectApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"readAll\", \"Project\"]
         * @summary Get all mentored projects | Permission required: [\"readAll\", \"Project\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMentoredProjects(filter, options) {
            return localVarFp.getAllMentoredProjects(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Project\"]
         * @summary Get mentor dashboard | Permission required: [\"readAll\", \"Project\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMentorDashboard(options) {
            return localVarFp.getMentorDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Project\"]
         * @summary Get project teams summaries | Permission required: [\"readOne\", \"Project\"]
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTeamsSummaries(id, filter, options) {
            return localVarFp.getProjectTeamsSummaries(id, filter, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * MentorProjectApi - object-oriented interface
 * @export
 * @class MentorProjectApi
 * @extends {BaseAPI}
 */
class MentorProjectApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"readAll\", \"Project\"]
     * @summary Get all mentored projects | Permission required: [\"readAll\", \"Project\"]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorProjectApi
     */
    getAllMentoredProjects(filter, options) {
        return exports.MentorProjectApiFp(this.configuration).getAllMentoredProjects(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Project\"]
     * @summary Get mentor dashboard | Permission required: [\"readAll\", \"Project\"]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorProjectApi
     */
    getMentorDashboard(options) {
        return exports.MentorProjectApiFp(this.configuration).getMentorDashboard(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Project\"]
     * @summary Get project teams summaries | Permission required: [\"readOne\", \"Project\"]
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorProjectApi
     */
    getProjectTeamsSummaries(id, filter, options) {
        return exports.MentorProjectApiFp(this.configuration).getProjectTeamsSummaries(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.MentorProjectApi = MentorProjectApi;
