/** @jsxImportSource @emotion/react */

import { Accordion, AccordionDetails, AccordionSummary, alpha, Button, Menu, MenuItem, useTheme } from "@mui/material";
import type { ContentBlock, ContentState } from "draft-js";
import type { ContentInteraction, SprintInteraction } from "innovation-api-manager";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { backendMediaUrlParser } from "../utils/functions/backendMediaUrlParser";
import { getUserFullName } from "../utils/functions/getUserFullName";
import { onFileRequestOpen } from "../utils/functions/onFileRequestOpen";
import type { OnAcceptedFilesCallback } from "./ContentEditor";
import IPlatformIcon from "./icons/IPlatformIcon";

export const FileAreaUpload = (props: {
	contentState: ContentState;
	block: ContentBlock;
	blockProps: {
		disabled?: boolean;
		onAcceptedFiles?: OnAcceptedFilesCallback;
		onDeletedFile?: (interactionId: string) => void;
		contentInteractions?: ContentInteraction[];
		sprintInteraction?: SprintInteraction;
	};
}) => {
	const { t } = useTranslation();
	const theme = useTheme();

	// const { data: user } = useAccount();
	const { disabled, contentInteractions, sprintInteraction, onDeletedFile } = props.blockProps;

	// const contentInteractionApi = useApi(ContentInteractionApi);

	if (disabled) {
		if (contentInteractions?.length) return renderFileInteractions();
		return (
			<div
				css={{
					padding: "24px 12px",
					borderRadius: 12,
					backgroundColor: alpha(theme.palette.primary.main, 0.05),
					// fontSize: 18,
					textAlign: "center",
				}}
				children={contentInteractions ? t("sprint.noDocumentUploaded") : t("sprint.documentWillBeUploadedHere")}
			/>
		);
	}

	return <div css={{ position: "relative" }}>{renderFileInteractions()}</div>;

	function renderFileInteractions() {
		if (!contentInteractions?.length) return null;

		const mainInteraction = contentInteractions?.[0];
		const mainFile = backendMediaUrlParser(contentInteractions?.[0]?.file);
		// console.log("mainFile", mainFile);

		return (
			<div css={{ width: "100%", overflowX: "auto" }}>
				<div css={{ minWidth: 580 }}>
					<Accordion>
						<AccordionSummary expandIcon={<i className="material-icons" children="expand_more" />}>
							{!!contentInteractions?.length && (
								<div
									css={{
										flex: 1,
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									{/* <div children={fileInteractions[0].fileName} /> */}
									<div children={mainInteraction?.originalFileName} />
									<div children={DateTime.fromISO(mainInteraction.createdAt as string).toFormat("dd/MM/yyyy")} />
									<Button
										children={"Apri il file"}
										endIcon={<IPlatformIcon name="arrow_mini_outline_right" />}
										onMouseDown={(e) => {
											e.stopPropagation();
											e.preventDefault();
										}}
										onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
											setTimeout(() => {
												onFileRequestOpen(mainFile);
											}, 0);
										}}
									/>
								</div>
							)}
						</AccordionSummary>

						<AccordionDetails>
							<table
								width="100%"
								css={{
									flex: 1,
									borderCollapse: "collapse",
									td: { padding: "5px 10px" },
								}}
							>
								<thead>
									<tr css={{ td: { opacity: 0.5 } }}>
										<td children={t("global.file")} />
										<td children={t("global.date")} />
										<td children={t("sprint.fileUploadedBy")} />
										<td css={{ width: "1%" }} />
									</tr>
								</thead>
								<tbody>
									{contentInteractions?.map((interaction, index) => (
										<FileInteractionRow
											key={index}
											// key={interaction.id || index}
											interaction={interaction}
											single={contentInteractions?.length === 1 && sprintInteraction?.isCompleted}
											onDeletedFile={onDeletedFile}
										/>
									))}
								</tbody>
							</table>
						</AccordionDetails>
					</Accordion>
				</div>
			</div>
		);
	}

	function FileInteractionRow(props: {
		interaction: ContentInteraction;
		single?: boolean;
		onDeletedFile?: (interactionId: string) => void;
	}) {
		const { interaction /*single = false, onDeletedFile*/ } = props;
		const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);

		// console.log(321321, interaction);

		const handleClose = () => setAnchorEl(null);

		const fileActions: { title: string; onClick: () => void }[] = [];

		// // TODO delete
		// if (interaction?.ownerId === user?.id) {
		// 	//action delete - only if file is mine
		// 	fileActions.push({
		// 		title: t("global.remove"),
		// 		onClick: () => {
		// 			// console.log('432423')
		// 			!single
		// 				? onDeleteClick?.(interaction?.id)
		// 				: MLDialog.showModal(t("sprint.youCantDeleteThisContent"), t("sprint.youMustUploadAnotherContent"));
		// 		},
		// 	});
		// }

		// console.log("interaction", interaction);

		const file = backendMediaUrlParser(interaction?.file);

		return (
			<tr
				css={{
					td: {
						borderTop: "1px solid rgba(0, 0, 0, 0.1)",
					},
				}}
			>
				<td
					children={interaction.originalFileName}
					css={{
						cursor: "pointer",
						transition: "background-color 200ms",
						":hover": {
							backgroundColor: "rgba(0, 0, 0, 0.03)",
						},
					}}
					onClick={() => {
						// console.log("💥💥", file);
						onFileRequestOpen(file);
					}}
				/>
				<td children={DateTime.fromISO(interaction.createdAt as string).toFormat("dd MMM yyyy, HH:mm")} />
				<td children={!!interaction?.owner && getUserFullName(interaction?.owner)} />
				<td css={{ width: "1%", textAlign: "right" }}>
					{fileActions?.length > 0 && (
						<React.Fragment>
							<Button
								aria-haspopup="true"
								onClick={(e) => setAnchorEl(e.currentTarget)}
								children={<IPlatformIcon name="more" />}
							/>
							<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
								{fileActions.map((action, index) => (
									<MenuItem key={index} onClick={action.onClick} children={action.title} />
								))}
							</Menu>
						</React.Fragment>
					)}
				</td>
			</tr>
		);

		// function onDeleteClick(id: string) {
		// 	handleClose();

		// 	contentInteractionApi
		// 		.deleteContentInteraction(id)
		// 		.then((res) => {
		// 			onDeletedFile?.(id);
		// 		})
		// 		.catch((err) => {
		// 			console.log("err", err);
		// 		});
		// }
	}
};
