"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationEntityEnum = exports.NotificationTypeEnum = void 0;
exports.NotificationTypeEnum = {
    New: 'new',
    Update: 'update'
};
exports.NotificationEntityEnum = {
    Software: 'software',
    Discount: 'discount',
    Book: 'book',
    Deliverable: 'deliverable',
    Feedback: 'feedback',
    Course: 'course',
    Project: 'project',
    Lesson: 'lesson'
};
