import { Box, Modal, ModalProps, Slide, SxProps, Theme, useTheme } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDimensions } from "../hooks/withDimensions";

export interface BottomSheetProps extends ModalProps {
	containerSx?: SxProps<Theme>;
	isBehind?: boolean;
	index?: number;
	frontBsHeight?: number;
	boxRef?: React.RefObject<HTMLDivElement>;
	onHeightChange?: (height: number | undefined) => void;
}

const BottomSheet: React.FC<BottomSheetProps> = ({
	children,
	containerSx,
	isBehind = false,
	index = 1,
	frontBsHeight,
	boxRef,
	onHeightChange,
	...otherProps
}) => {
	const theme = useTheme();

	const innerRef = useRef<HTMLDivElement>(null);

	//effect to get height of the content container on first paint, which happens every time open is true (this is because the container is mounted and unmounted on every open change due to be wrapped in Slide)
	useEffect(() => {
		const timeout = setTimeout(() => {
			onHeightChange?.(boxRef ? boxRef?.current?.offsetHeight : innerRef.current?.offsetHeight);
		}, 0);
		return () => clearTimeout(timeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [otherProps.open]);

	const [{ width: pageWidth }] = useDimensions();

	return (
		<Modal
			closeAfterTransition
			BackdropProps={{
				timeout: 500,
			}}
			{...otherProps}
		>
			<Slide direction="up" in={otherProps.open}>
				<Box
					sx={{
						position: "absolute",
						bottom: 0,
						outline: "none",
					}}
				>
					<Box
						ref={boxRef ? boxRef : innerRef}
						sx={{
							width: "100vw",
							transform: `translate(${0}px, ${
								isBehind
									? -(
											90 * index +
											(frontBsHeight ?? 0) -
											((boxRef ? boxRef?.current?.clientHeight : innerRef.current?.clientHeight) ?? 0)
									  )
									: 0
							}px) scale(${isBehind ? (pageWidth - 48 * 2 * index) / pageWidth : 1})`,
							bgcolor: "background.paper",
							boxShadow: 24,
							borderRadius: "48px 48px 0px 0px",
							boxSizing: "border-box",
							pt: { xs: 6 },
							transition: theme.transitions.create(["transform"]),
						}}
					>
						<Box
							sx={{
								overflowY: "auto",
								minHeight: "50vh",
								maxHeight: "90vh",
								boxSizing: "border-box",
								height: "100%",
								pb: { xs: 6 },
								px: { xs: 6 },
								...containerSx,
							}}
						>
							{children}
						</Box>
					</Box>
				</Box>
			</Slide>
		</Modal>
	);
};

export default BottomSheet;
