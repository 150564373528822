import { Card, CardContent, Stack, Typography } from "@mui/material";
import { contentPadding } from "../../../../../../../utils/functions/contentPadding";
import { useProjectSprintsContextSelector } from "../controllers/ProjectSprintsController";
import { SprintContentsContextProvider, useSprintContentsController } from "../controllers/SprintContentsController";
import SprintContentsSection from "./SprintContentsSection";
import SprintSelector from "./SprintSelector";
import SwitchProjectSprintsOpening from "./SwitchProjectSprintsOpening";

type ProjectSprintsCardProps = {};

const ProjectSprintsCard: React.FC<ProjectSprintsCardProps> = () => {
	const sprintContentsController = useSprintContentsController();

	const sprints = useProjectSprintsContextSelector("sprints");
	const selectedSprint = useProjectSprintsContextSelector("selectedSprint");

	const readOnly = useProjectSprintsContextSelector("readOnly");

	return (
		<Card sx={{ maxWidth: 864 }}>
			<CardContent sx={{ ...contentPadding(6) }}>
				<Stack spacing={6}>
					<Stack spacing={1}>
						<Typography children="Sprint del progetto" variant="h6" sx={{ fontWeight: 600 }} />
						<Typography
							children="Crea e gestisci gli sprint per questo progetto"
							variant="subtitle2"
							color="text.secondary"
							sx={{ fontWeight: 600 }}
						/>
					</Stack>
					{!readOnly && <SwitchProjectSprintsOpening />}
					<SprintSelector />
					{Boolean(selectedSprint) && sprints?.length !== 0 && (
						<SprintContentsContextProvider sprintContentsController={sprintContentsController}>
							<SprintContentsSection readOnly={readOnly} key={selectedSprint?.id} />
						</SprintContentsContextProvider>
					)}
				</Stack>
			</CardContent>
		</Card>
	);
};
export default ProjectSprintsCard;
