import { DatePicker } from "@mui/x-date-pickers";
import BetterTextInput from "../textInput/BetterTextInput";
import type { BetterTextInputProps } from "../textInput/interfaces/BetterTextInputProps";

type BetterDatePickerProps = {
	value?: string;
	onChange: (date: any, keyboardInputValue?: string | undefined) => void;
	label?: string;
	disabled?: boolean;
	textInputProps?: Partial<BetterTextInputProps>;
};
const BetterDatePicker: React.FC<BetterDatePickerProps> = (props) => {
	const { value, onChange, label, disabled, textInputProps = {} } = props;

	return (
		<DatePicker
			value={value}
			onChange={onChange}
			label={label}
			disabled={disabled}
			inputFormat="dd/MM/yyyy"
			renderInput={({ inputRef, inputProps, InputProps }) => (
				<BetterTextInput
					readOnly
					inputRef={inputRef}
					value={inputProps?.value}
					onChange={inputProps?.onChange}
					endAdornment={InputProps?.endAdornment}
					placeholder={label}
					fullWidth
					size="medium"
					disabled={disabled}
					sx={{ position: "relative" }}
					{...textInputProps}
				/>
			)}
		/>
	);
};
export default BetterDatePicker;
