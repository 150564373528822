/** @jsxImportSource @emotion/react */

import { AppBar, Box, Fade, Stack, Toolbar } from "@mui/material";
import type { AxiosError } from "axios";
import { useCallback, useState } from "react";
import SgeeksLogo from "../../../assets/images/sgeeks-logo.svg";
import { apiErrorParser } from "../../../hooks/api";
import LoadingIconButton from "../../buttons/LoadingIconButton";
import IPlatformIcon from "../../icons/IPlatformIcon";
import MLDialog from "../../poppers/MLDialog";
import { APP_BAR_TOOLBAR_HEIGHT } from "../constants/ScaffoldAppBarConstants";
import { DRAWER_WIDTH } from "../constants/ScaffoldDrawerConstants";
import { useTopAction } from "../controllers/TopActionController";

type ScaffoldAppBarProps = {
	children?: React.ReactNode;
};

const TOOLBAR_X_PADDING = 24;

function isAxiosError(error: any): error is AxiosError {
	return error.isAxiosError;
}

const ScaffoldAppBar: React.FC<ScaffoldAppBarProps> = () => {
	// const [topActionState] = useAtom(topActionAtom);
	const topActionState = useTopAction();

	const [loading, setLoading] = useState(false);

	const onClick = useCallback(async () => {
		const res = topActionState.onClick();
		if (res?.then !== undefined) {
			setLoading(true);
			try {
				await res;
			} catch (e) {
				MLDialog.showSnackbar(isAxiosError(e) ? apiErrorParser(e) : e, { variant: "error" });
			}
			setLoading(false);
		}
	}, [topActionState]);

	return (
		<AppBar
			elevation={0}
			position="fixed"
			className="mui-fixed"
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "background.paper" }}
		>
			<Toolbar sx={{ px: `${TOOLBAR_X_PADDING}px !important` }}>
				<Box sx={{ height: APP_BAR_TOOLBAR_HEIGHT, width: DRAWER_WIDTH - TOOLBAR_X_PADDING, paddingTop: 6 }}>
					<img src={SgeeksLogo} alt="Startup Geeks logo" css={{ height: 25 }} />
				</Box>
				<Stack direction="row" justifyContent={"flex-end"} sx={{ flexGrow: 1 }}>
					<Fade in={topActionState.visible}>
						<LoadingIconButton
							variant="contained"
							icon={<IPlatformIcon name={topActionState.icon ?? "success"} color="inherit" />}
							loading={loading}
							onClick={onClick}
							children={topActionState.title}
							disabled={topActionState.disabled}
						/>
					</Fade>
				</Stack>
			</Toolbar>
		</AppBar>
	);
};
export default ScaffoldAppBar;
