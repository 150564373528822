import ContentCopy from "@mui/icons-material/ContentCopy";
import {
	Alert,
	alpha,
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	chipClasses,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	Fade,
	FormControl,
	FormLabel,
	Grid,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BetterDatePicker from "../../../../../../../components/datePicker/BetterDatePicker";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import IOSSwitch from "../../../../../../../components/IOSSwitch";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { useTopAction } from "../../../../../../../components/scaffold/controllers/TopActionController";
import BetterTextInput from "../../../../../../../components/textInput/BetterTextInput";
import { contentPadding } from "../../../../../../../utils/functions/contentPadding";
import { useProjectInfoContextSelector } from "../controllers/ProjectInfoController";
import CloseProjectModal from "./CloseProjectModal";

type ProjectInfoCardProps = {
	children?: React.ReactNode;
};

const ProjectInfoCard: React.FC<ProjectInfoCardProps> = () => {
	const theme = useTheme();
	const { t } = useTranslation();
	const { projectId } = useParams();

	const project = useProjectInfoContextSelector("project");
	const setProject = useProjectInfoContextSelector("setProject");

	const data = useProjectInfoContextSelector("data");
	const loading = useProjectInfoContextSelector("loading");

	const updateProject = useProjectInfoContextSelector("updateProject");
	const deleteProject = useProjectInfoContextSelector("deleteProject");

	const readOnly = useProjectInfoContextSelector("readOnly");

	const updateTopAction = useTopAction((state) => state.updateTopAction);
	const removeTopAction = useTopAction((state) => state.removeTopAction);

	const [hasSomethingChanged, setHasSomethingChanged] = useState(false);

	const [dialogOpen, setDialogOpen] = useState(false);
	const [projectNameToDelete, setProjectNameToDelete] = useState("");

	const [errors, setErrors] = useState({
		title: false,
		closingDate: false,
	});

	useEffect(() => {
		if (readOnly) {
			removeTopAction();
			return;
		}

		updateTopAction({
			disabled: false,
			visible: hasSomethingChanged,
			icon: "success",
			title: "Salva modifiche",
			onClick: async () => {
				const isClosingDateBeforeOpeningDate =
					project.closingDate !== undefined &&
					project.openingDate !== undefined &&
					DateTime.fromISO(project.closingDate).startOf("day") < DateTime.fromISO(project.openingDate).startOf("day");
				if (!project.name || (project.hasClosingDate && isClosingDateBeforeOpeningDate)) {
					setErrors({
						title: !project.name,
						closingDate: (project.hasClosingDate ?? false) && isClosingDateBeforeOpeningDate,
					});
				} else {
					await updateProject();
					setHasSomethingChanged(false);
				}
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasSomethingChanged, updateProject, readOnly]);

	const [closeProjectModalOpen, setCloseProjectModalOpen] = useState(false);

	return (
		<Card sx={{ width: "100%", maxWidth: theme.breakpoints.values.md }}>
			{!loading ? (
				<CardContent sx={{ ...contentPadding(6) }}>
					<Stack direction="row" alignItems={"center"} justifyContent="space-between">
						<Stack spacing={1}>
							<Typography children={t("project.projectGenericInfo")} variant="h6" sx={{ fontWeight: 600 }} />
							<Typography
								children={t("project.insertProjectGenericInfo")}
								variant="subtitle2"
								color="text.secondary"
								sx={{ fontWeight: 600 }}
							/>
						</Stack>
						<Stack direction="row" alignItems="center" spacing={2}>
							{data?.isClosed && (
								<Chip
									label="Progetto chiuso"
									variant="filled"
									icon={<IPlatformIcon name="clock_outline_02" color="secondary" size={20} />}
									sx={{
										backgroundColor: alpha(theme.palette.secondary.main, 0.06),
										color: theme.palette.secondary.main,
										[`.${chipClasses.icon}`]: { color: theme.palette.secondary.main },
									}}
								/>
							)}
							<Chip
								label="Copia ID progetto"
								variant="outlined"
								color="primary"
								onClick={() => {
									if (Boolean(projectId)) {
										navigator.clipboard.writeText(projectId as string);
										MLDialog.showSnackbar("ID del progetto copiato nella clipboard", { variant: "success" });
									}
								}}
								icon={<ContentCopy sx={{ fontSize: 20 }} />}
							/>
						</Stack>
					</Stack>
					<Stack spacing={6} sx={{ marginTop: 12 }}>
						<FormControl fullWidth>
							<BetterTextInput
								value={project?.name || ""}
								onChange={(event) => {
									setHasSomethingChanged(true);
									setProject((draft) => {
										draft.name = event.target.value;
									});
									setErrors((prev) => ({ ...prev, title: false }));
								}}
								placeholder={t("project.projectName")}
								size="medium"
								label={t("project.projectName")}
								fullWidth
								status={errors.title ? "error" : undefined}
								helperText="Il nome del progetto è obbligatorio"
								disabled={readOnly}
							/>
						</FormControl>
						<FormControl fullWidth sx={{ marginTop: "48px !important" }}>
							<FormLabel children={t("project.connectACalendarToScheduleEvents")} />
							{/* <Typography children="Collega un calendario Google per programmare gli eventi" variant="caption" color="text.secondary" /> */}
							<Select variant="filled" size="small" disabled={readOnly}>
								<MenuItem children="Calendario 1" />
								<MenuItem children="Calendario 2" />
								<MenuItem children="Calendario 3" />
							</Select>
						</FormControl>
					</Stack>
					<Box sx={{ marginTop: 12 }}>
						<Stack direction="row" alignItems={"center"} justifyContent="space-between">
							<Typography children={t("project.isVisibleInTenant")} variant="subtitle2" />
							<IOSSwitch
								checked={project?.isVisible}
								onChange={(ev) => {
									setHasSomethingChanged(true);
									setProject((draft) => {
										draft.isVisible = ev.target.checked;
									});
								}}
								disabled={readOnly}
							/>
						</Stack>
						<Typography
							children={t("project.isVisibleInTenantDescription")}
							variant="caption"
							component={"p"}
							sx={{ opacity: 0.6, pt: 1 }}
						/>
					</Box>
					<Stack spacing={3} sx={{ marginTop: 12 }}>
						<Box>
							<Stack direction="row" alignItems={"center"} justifyContent="space-between">
								<Typography children={t("projects.withStartAndEnd")} variant="subtitle2" />
								<IOSSwitch
									checked={project.hasClosingDate}
									onChange={(event) => {
										setHasSomethingChanged(true);
										setProject((project) => {
											project.hasClosingDate = event.target.checked;
										});
									}}
									disabled={readOnly}
								/>
							</Stack>
							<Typography
								children={t("project.hasClosingDateDescription")}
								variant="caption"
								component={"p"}
								sx={{ opacity: 0.6, pt: 1 }}
							/>
						</Box>
						<Collapse in={project.hasClosingDate}>
							<Grid container columnSpacing={3}>
								<Grid item xs={6}>
									<FormControl fullWidth sx={{ position: "relative" }}>
										<FormLabel children={t("project.startDate")} />
										<BetterDatePicker
											label={t("project.startDate")}
											value={project?.openingDate}
											onChange={(date) => {
												setHasSomethingChanged(true);
												setProject((draft) => {
													draft.openingDate = date || DateTime.now().toISODate();
												});
												if (
													project.closingDate &&
													DateTime.fromISO(date).startOf("day") <= DateTime.fromISO(project.closingDate).startOf("day")
												) {
													setErrors((prev) => ({ ...prev, closingDate: false }));
												}
											}}
											disabled={readOnly}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl fullWidth sx={{ position: "relative" }}>
										<FormLabel children={t("project.endDate")} />
										<BetterDatePicker
											label={t("project.endDate")}
											value={project?.closingDate}
											onChange={(date) => {
												setHasSomethingChanged(true);
												setProject((draft) => {
													draft.closingDate = date || DateTime.now().toISODate();
												});
												if (
													project.openingDate &&
													DateTime.fromISO(date).startOf("day") >= DateTime.fromISO(project.openingDate).startOf("day")
												) {
													setErrors((prev) => ({ ...prev, closingDate: false }));
												}
											}}
											disabled={readOnly}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Collapse>
					</Stack>
					<Collapse in={errors.closingDate}>
						<Alert
							children="La data di chiusura deve essere successiva alla data di apertura"
							severity="error"
							sx={{ marginTop: 3 }}
						/>
					</Collapse>
					{!readOnly && (
						<Stack direction="row" gap={3} justifyContent="flex-end" sx={{ marginTop: 12 }}>
							<Fade in={!data?.datesEnabled && !data?.isClosed}>
								<Button
									children={t("project.closeProject")}
									variant="outlined"
									color="error"
									startIcon={<IPlatformIcon name="megaphone-sg" />}
									onClick={() => {
										setCloseProjectModalOpen(true);
									}}
								/>
							</Fade>
							<Button
								children={t("project.deleteProject")}
								variant="contained"
								color="error"
								startIcon={<IPlatformIcon name="delete_outline" />}
								onClick={() => {
									setDialogOpen(true);
								}}
							/>
						</Stack>
					)}
				</CardContent>
			) : (
				<CardContent sx={{ ...contentPadding(6) }}>
					<Stack direction="row" justifyContent="center" alignItems="center">
						<CircularProgress />
					</Stack>
				</CardContent>
			)}
			<Dialog
				open={dialogOpen}
				onClose={() => {
					setDialogOpen(false);
				}}
				fullWidth
				maxWidth="sm"
			>
				<DialogContent>
					<div style={{ overflow: "hidden", height: "calc(100% + 2px)", width: "calc(100% + 2px)", padding: 2, margin: -2 }}>
						<Stack spacing={6}>
							<Typography children="Vuoi davvero eliminare il progetto" variant="h6" sx={{ fontWeight: 600 }} />
							<Typography children="Una volta eliminato non sarà in nessun modo recuperabile. Per eliminare definitivamente il progetto riscrivine il nome qui sotto" />
							<FormControl fullWidth>
								<BetterTextInput
									value={projectNameToDelete}
									onChange={(event) => {
										setProjectNameToDelete(event.target.value);
									}}
									placeholder={t("project.projectName")}
									size="medium"
									label={t("project.projectName")}
									fullWidth
								/>
							</FormControl>
							<Stack direction="row" justifyContent="space-between" alignItems="center">
								<Button
									children="Annulla"
									startIcon={<IPlatformIcon name="close" />}
									onClick={() => {
										setDialogOpen(false);
									}}
								/>
								<Button
									children="Elimina progetto"
									variant="contained"
									color="error"
									disabled={projectNameToDelete !== data?.name}
									startIcon={<IPlatformIcon name="delete_outline" />}
									onClick={() => {
										// setDialogOpen(false);
										deleteProject();
									}}
								/>
							</Stack>
						</Stack>
					</div>
				</DialogContent>
			</Dialog>
			<CloseProjectModal open={closeProjectModalOpen} setOpen={setCloseProjectModalOpen} projectName={data?.name ?? ""} />
		</Card>
	);
};

export default ProjectInfoCard;
