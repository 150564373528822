import { AdminSprintContentApi, SprintContent, SprintContentTypeEnum } from "innovation-api-manager";
import { useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { createContext, useContextSelector } from "use-context-selector";
import { useImmer } from "use-immer";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { transformToStringFrom } from "../../../../../../../components/tables/AdminTable";
import { useApi, useFetch } from "../../../../../../../hooks/api";

type TemporarySprintContent = {
	title: string;
	content: { body: string } | undefined;
};

export function useSprintContentsController() {
	const { tenantId, projectId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const sprintId = searchParams.get("sprint");

	const adminSprintContentApi = useApi(AdminSprintContentApi);

	const [selectedTab, setSelectedTab] = useState<SprintContentTypeEnum | "questions">("content");

	const {
		data: sprintContents,
		loading: loadingSprintContents,
		error: errorSprintContents,
		revalidate: revalidateSprintContents,
	} = useFetch(
		adminSprintContentApi.getAdminSprintContents,
		sprintId as string,
		tenantId as string,
		transformToStringFrom({ perPage: 10_000, orderBy: "order", order: "asc" }),
		{
			skip: !sprintId,
		}
	);

	const [selectedSprintContent, setSelectedSprintContent] = useState<SprintContent>();

	const [temporarySprintContent, setTemporarySprintContent] = useImmer<TemporarySprintContent>({
		title: "",
		content: {
			body: "",
		},
	});

	const addSprintContent = () => {
		if (!temporarySprintContent.title || temporarySprintContent?.title === "") {
			MLDialog.showSnackbar("Aggiungi un titolo per questo contenuto", { variant: "warning" });
			return;
		}

		return adminSprintContentApi.createSprintContent(sprintId as string, tenantId as string, {
			title: temporarySprintContent?.title,
			content: { ...temporarySprintContent?.content },
			type: selectedTab as SprintContentTypeEnum,
		});
	};

	const editSprintContent = () => {
		if (!temporarySprintContent.title || temporarySprintContent?.title === "") {
			MLDialog.showSnackbar("Aggiungi un titolo per questo contenuto", { variant: "warning" });
			return;
		}

		return adminSprintContentApi.editSprintContent(selectedSprintContent?.id as string, tenantId as string, {
			title: temporarySprintContent?.title,
			content: { ...temporarySprintContent?.content },
			type: selectedTab as SprintContentTypeEnum,
		});
	};

	const deleteSprintContent = () => {
		return adminSprintContentApi.deleteSprintContent(selectedSprintContent?.id as string, tenantId as string);
	};

	return {
		sprintId,
		sprintContents,
		loadingSprintContents,
		errorSprintContents,
		revalidateSprintContents,
		selectedSprintContent,
		setSelectedSprintContent,
		temporarySprintContent,
		setTemporarySprintContent,
		selectedTab,
		setSelectedTab,
		addSprintContent,
		editSprintContent,
		deleteSprintContent,
	};
}

type SprintContentsContextType = ReturnType<typeof useSprintContentsController>;

const SprintContentsContext = createContext<SprintContentsContextType | undefined>(undefined);

export const SprintContentsContextProvider: React.FC<{
	sprintContentsController: SprintContentsContextType;
	children: React.ReactNode;
}> = ({ children, sprintContentsController }) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoChildren = useMemo(() => children, []);
	return (
		<SprintContentsContext.Provider value={sprintContentsController}>{memoChildren}</SprintContentsContext.Provider>
	);
};

export function useSprintContentsContextSelector<T extends keyof SprintContentsContextType>(
	field: T
): SprintContentsContextType[T] {
	return useContextSelector(SprintContentsContext, (v) => v![field]);
}

export function useSprintContentsContextUnoptimized() {
	const context = useContextSelector(SprintContentsContext, (v) => v);

	if (context === undefined) {
		throw new Error("Trying to use context outside of provider");
	}

	return context;
}
