/** @jsxImportSource @emotion/react */

import type { ContentBlock, ContentState } from "draft-js";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export const EmbeddedLink = (props: {
	contentState: ContentState;
	block: ContentBlock;
	blockProps: {
		disabled?: boolean;
		// fileInteractions?: ContentInteraction[];
		onDelete?: () => void;
	};
}) => {
	const { block, contentState } = props;
	// const { disabled, fileInteractions, onDelete } = props.blockProps;

	let videoUrl;
	try {
		const data = contentState.getEntity(block.getEntityAt(0)).getData();
		videoUrl = data?.src;
	} catch (e) {}

	return <EmbeddedLinkComponent urlLink={videoUrl} />;
};

export interface ITimeUpdate {
	seconds: number;
	percent: number;
	duratin: number;
}

export const EmbeddedLinkComponent = (props: {
	urlLink: string;
	initialProgress?: number;
	onVimeoUpdate?: (action: "play" | "pause" | "ended" | "timeupdate", timeupdate?: ITimeUpdate) => void;
	[x: string]: any;
}) => {
	const { t } = useTranslation();

	const { urlLink, onVimeoUpdate, initialProgress, ...otherProps } = props;
	const iframeRef = useRef<any>();

	useEffect(syncVimeoProgress, []);

	try {
		const linkParser = [
			{
				starts: "https://vimeo.com/",
				target: "https://player.vimeo.com/video/",
			},
			{
				starts: "https://www.youtube.com/watch?v=",
				target: "https://www.youtube.com/embed/",
			},
			{
				starts: "https://youtu.be/",
				target: "https://www.youtube.com/embed/",
			},
		];

		let link: string = urlLink?.trim() || "";

		for (var p = 0; p < linkParser.length; p++) {
			if (link.startsWith(linkParser[p].starts)) {
				link = link.replace(linkParser[p].starts, linkParser[p].target);
				continue;
			}
		}

		return (
			<div
				className="sg-ce-container"
				css={{
					width: "100%",
					height: 0,
					paddingTop: "56.25%",
					position: "relative",
				}}
				{...otherProps}
			>
				<div className="sg-ce-wrapper" css={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0 }}>
					<iframe
						ref={(r) => (iframeRef.current = r)}
						src={link}
						width="100%"
						height="100%"
						frameBorder={0}
						allowFullScreen
						// TODO serve localizzare questo?
						title={t("sprint.contentVideo")}
					/>
				</div>
			</div>
		);
	} catch (e) {
		return (
			<div css={{ padding: 12, border: "1px solid #ccc", borderRadius: 4 }} children={t("sprint.invalidVideoUrl")} />
		);
	}

	function syncVimeoProgress() {
		if (!onVimeoUpdate) return;

		const onSyncError = (e: any) => console.log("❌ ref ❌ ", e);
		try {
			const vimeoScriptName = "vimeo-player-src-lib";
			const existingScript = document.getElementById(vimeoScriptName);

			const onScriptReady = async () => {
				try {
					const Vimeo = (window as any).Vimeo;
					const iframe = iframeRef.current;
					var player = new Vimeo.Player(iframe);

					player.on("play", () => onVimeoUpdate("play"));
					player.on("pause", () => onVimeoUpdate("pause"));
					player.on("ended", () => onVimeoUpdate("ended"));
					player.on("timeupdate", (time: any) => onVimeoUpdate("timeupdate", time));

					const seconds = await player.getDuration();
					const curSeconds = (seconds || 0) * ((initialProgress || 0) / 100 || 0);
					player.setCurrentTime(curSeconds);
				} catch (e) {
					onSyncError(e);
				}
			};

			if (existingScript) {
				onScriptReady();
			} else {
				const script = document.createElement("script");
				script.src = "https://player.vimeo.com/api/player.js";
				script.id = vimeoScriptName;
				document.body.appendChild(script);

				script.onload = () => {
					onScriptReady();
				};
			}
		} catch (e) {
			onSyncError(e);
		}
	}
};
