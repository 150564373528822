import { AdminSprintContentApi } from "innovation-api-manager";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { apiErrorParser, useApi } from "../../../../../../../hooks/api";
import { useSprintContentsContextSelector } from "../controllers/SprintContentsController";

export function useReorderSprintContent() {
	const { tenantId } = useParams();
	const revalidateSprints = useSprintContentsContextSelector("revalidateSprintContents");
	const adminSprintContentApi = useApi(AdminSprintContentApi);

	const orderContent = useCallback(
		(contentId: string, newIndex: number) => {
			adminSprintContentApi
				.reorderSprintContent(contentId, tenantId, {
					order: newIndex + 1,
				})
				.catch((err) => {
					MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
				})
				.finally(() => {
					revalidateSprints();
				});
		},
		[adminSprintContentApi, revalidateSprints, tenantId]
	);

	return { orderContent };
}
