import { AdminSprintQuestionApi } from "innovation-api-manager";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { apiErrorParser, useApi } from "../../../../../../../hooks/api";
import { useSprintQuestionsContextSelector } from "../controllers/SprintQuestionsController";

export function useReorderSprintQuestion() {
	const { tenantId } = useParams();
	const revalidateQuestions = useSprintQuestionsContextSelector("revalidateSprintQuestions");
	const adminSprintQuestionApi = useApi(AdminSprintQuestionApi);

	const orderQuestion = useCallback(
		(questionId: string, newIndex: number) => {
			adminSprintQuestionApi
				.reorderSprintQuestion(questionId, tenantId, {
					order: newIndex + 1,
				})
				.catch((err) => {
					MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
				})
				.finally(() => {
					revalidateQuestions();
				});
		},
		[adminSprintQuestionApi, revalidateQuestions, tenantId]
	);

	return { orderQuestion };
}
