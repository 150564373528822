/** @jsxImportSource @emotion/react */
import {
	alpha,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Dialog,
	DialogContent,
	Grid,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import Loading from "../../../../../../../components/Loading";
import MLDialog from "../../../../../../../components/poppers/MLDialog";
import { contentPadding } from "../../../../../../../utils/functions/contentPadding";
import { useProjectTeamContextSelector } from "../controllers/ProjectTeamController";

type ProjectTeamFeedbackCardProps = {};

type FeedbackOption = {
	value: number;
	label: string;
	color: string;
};

const ProjectTeamFeedbackCard: React.FC<ProjectTeamFeedbackCardProps> = (props) => {
	const theme = useTheme();

	const [open, setOpen] = useState(false);

	const adminEvaluation = useProjectTeamContextSelector("adminEvaluation");
	const loadingAdminEvaluation = useProjectTeamContextSelector("loadingAdminEvaluation");
	const errorAdminEvaluation = useProjectTeamContextSelector("errorAdminEvaluation");
	const revalidateAdminEvaluation = useProjectTeamContextSelector("revalidateAdminEvaluation");

	const giveEvaluation = useProjectTeamContextSelector("giveEvaluation");

	const sprintAnswers = useProjectTeamContextSelector("sprintAnswers");
	const loadingSprintAnswers = useProjectTeamContextSelector("loadingSprintAnswers");
	const errorSprintAnswers = useProjectTeamContextSelector("errorSprintAnswers");

	const sprintInteractionId = useProjectTeamContextSelector("sprintInteractionId");

	const readOnly = useProjectTeamContextSelector("readOnly");

	if (!sprintInteractionId) {
		return null;
	}

	return (
		<React.Fragment>
			<Card>
				<CardContent sx={{ ...contentPadding(6) }}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<Stack spacing={3} alignItems="flex-start">
								<Typography children="Risposte alle domande per gli utenti" variant="subtitle1" />
								<Button
									children="Vedi le risposte"
									variant="outlined"
									onClick={() => {
										setOpen(true);
									}}
									sx={{ height: 50.6 }}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Stack spacing={3} alignItems="flex-start">
								<Typography children="Giudizio sui deliverable" variant="subtitle1" />
								<Select
									value={loadingAdminEvaluation ? -1 : adminEvaluation?.adminEvaluation || 0}
									onChange={(event) => {
										giveEvaluation(event.target.value as number)
											.then((response) => {
												revalidateAdminEvaluation();
												MLDialog.showSnackbar("Giudizio salvato", { variant: "success" });
											})
											.catch((error) => {
												console.log("error", error);
												MLDialog.showSnackbar("C'è stato un errore durante il salvataggio del giudizio", { variant: "error" });
											});
									}}
									variant="filled"
									disabled={loadingAdminEvaluation || readOnly}
								>
									{!loadingAdminEvaluation &&
										!errorAdminEvaluation &&
										deliverableOptions?.map((option) => {
											return (
												<MenuItem
													children={
														<Stack direction="row" spacing={2} alignItems="center">
															<div
																css={[
																	{ width: 20, height: 20, borderRadius: 20, backgroundColor: option?.color, boxSizing: "border-box" },
																	option?.value === 0 && { border: `1px solid ${alpha(theme.palette.text.primary, 0.24)}` },
																]}
															/>
															<Typography children={option?.label} />
														</Stack>
													}
													value={option?.value}
													key={option?.value}
												/>
											);
										})}
									{loadingAdminEvaluation && (
										<MenuItem
											children={
												<Stack direction="row" spacing={2} alignItems="center">
													<CircularProgress size={18} />
													<Typography children={"Caricamento"} />
												</Stack>
											}
											value={-1}
											key={"loading"}
										/>
									)}
								</Select>
							</Stack>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
			<Dialog
				open={open}
				onClose={() => {
					setOpen(false);
				}}
				fullWidth
				maxWidth="sm"
			>
				<DialogContent>
					<Stack spacing={6}>
						<Typography children="Risposte alle domande" variant="h6" sx={{ fontWeight: 600 }} />
						{loadingSprintAnswers && <Loading />}
						{!loadingSprintAnswers &&
							!errorSprintAnswers &&
							sprintAnswers?.map((answer) => {
								return (
									<Stack spacing={3} key={answer?.questionId}>
										<Stack spacing={0.5}>
											<Typography children={answer?.question?.question} variant="subtitle1" />
											{Boolean(answer?.question?.description) && (
												<Typography children={answer?.question?.description} variant="body2" color="text.secondary" />
											)}
										</Stack>
										<Stack spacing={1}>
											<Typography
												children={answer?.answers && answer?.answers?.length > 1 ? "Risposte dell'utente" : "Risposta dell'utente"}
												variant="overline"
												color="text.secondary"
											/>
											{answer?.answers?.map((userAnswer) => {
												if (answer?.question?.questionType === "text") {
													return <Typography children={userAnswer?.value} key={userAnswer?.value} />;
												}

												const answerContent = answer?.question?.possibleAnswers?.find((possibleAnswer) => {
													return possibleAnswer?.value === userAnswer?.value;
												})?.content;

												return <Typography children={answerContent} key={userAnswer?.value} />;
											})}
										</Stack>
									</Stack>
								);
							})}
						{!loadingSprintAnswers && (errorSprintAnswers || !sprintAnswers?.length) && (
							<Typography children="Nessuna risposta" variant="body2" color="text.secondary" key={"error"} />
						)}
						<Stack direction="row" justifyContent="flex-end">
							<Button
								children="Chiudi"
								startIcon={<IPlatformIcon name="close" />}
								onClick={() => {
									setOpen(false);
								}}
							/>
						</Stack>
					</Stack>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};

const deliverableOptions: FeedbackOption[] = [
	{
		value: 0,
		label: "Non ancora visto",
		color: "#FFFFFF",
	},
	{
		value: 1,
		label: "Positivo",
		color: "#07B338",
	},
	{
		value: 2,
		label: "Così così",
		color: "#FF9400",
	},
	{
		value: 3,
		label: "Negativo",
		color: "#FE3D3D",
	},
];

export default ProjectTeamFeedbackCard;
