import { createTheme, CssBaseline, ThemeProvider as MUIThemeProvider } from "@mui/material";
import { createContext } from "react";
import type { ThemeContextParams } from "./interfaces";
import { themeObject } from "./theme";

const ThemeContext = createContext<ThemeContextParams>({} as ThemeContextParams);

// const defaultColors: ThemeColors = {
// 	primary: "#003e7e",
// 	secondary: "#ADEFD1FF",
// 	// background: "linear-gradient(142.74deg, #0D5BA6 0%, #094074 100%)",
// 	background: "#0D5BA6",
// 	paper: "#ffffff",
// };

const ThemeProvider = (props: any) => {
	// const [palette, setPalette] = useState(defaultColors);
	// const [defaultTheme, setDefaultTheme] = useState(true);

	// const setCustomTheme = (palette: ThemeColors) => {
	// 	setPalette(palette);
	// 	setDefaultTheme(false);
	// };

	// const customTheme = {
	// 	palette: {
	// 		primary: {
	// 			main: palette.primary,
	// 		},
	// 		secondary: {
	// 			main: palette.secondary,
	// 		},
	// 		background: {
	// 			default: palette.background,
	// 			paper: palette.paper,
	// 		},
	// 	},
	// };

	// const deepmixTheme = deepmerge(themeObject, customTheme);

	const themePalette = createTheme(themeObject);

	return (
		// <ThemeContext.Provider value={{ palette, defaultTheme, setCustomTheme }}>
		<MUIThemeProvider theme={themePalette}>
			<CssBaseline />
			{props.children}
		</MUIThemeProvider>
		// </ThemeContext.Provider>
	);
};

export { ThemeContext, ThemeProvider };
