"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminManagerApi = exports.AdminManagerApiFactory = exports.AdminManagerApiFp = exports.AdminManagerApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminManagerApi - axios parameter creator
 * @export
 */
exports.AdminManagerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"delete\", \"Admin\"]
         * @summary Delete manager | Permission required: [\"delete\", \"Admin\"]
         * @param {string} managerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManager: (managerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'managerId' is not null or undefined
            common_1.assertParamExists('deleteManager', 'managerId', managerId);
            const localVarPath = `/admin/manager/{managerId}/`
                .replace(`{${"managerId"}}`, encodeURIComponent(String(managerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Admin\"]
         * @summary Edit manager | Permission required: [\"update\", \"Admin\"]
         * @param {string} managerId
         * @param {EditCreateManager} [editCreateManager]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editManager: (managerId, editCreateManager, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'managerId' is not null or undefined
            common_1.assertParamExists('editManager', 'managerId', managerId);
            const localVarPath = `/admin/manager/{managerId}/`
                .replace(`{${"managerId"}}`, encodeURIComponent(String(managerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateManager, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Admin\"]
         * @summary Get all managers | Permission required: [\"readAll\", \"Admin\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllManagers: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/manager/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"create\", \"Admin\"]
         * @summary Invite manager | Permission required: [\"create\", \"Admin\"]
         * @param {EditCreateManager} [editCreateManager]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteManager: (editCreateManager, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/manager/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateManager, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminManagerApi - functional programming interface
 * @export
 */
exports.AdminManagerApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminManagerApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"delete\", \"Admin\"]
         * @summary Delete manager | Permission required: [\"delete\", \"Admin\"]
         * @param {string} managerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManager(managerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteManager(managerId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Admin\"]
         * @summary Edit manager | Permission required: [\"update\", \"Admin\"]
         * @param {string} managerId
         * @param {EditCreateManager} [editCreateManager]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editManager(managerId, editCreateManager, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editManager(managerId, editCreateManager, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Admin\"]
         * @summary Get all managers | Permission required: [\"readAll\", \"Admin\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllManagers(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllManagers(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"create\", \"Admin\"]
         * @summary Invite manager | Permission required: [\"create\", \"Admin\"]
         * @param {EditCreateManager} [editCreateManager]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteManager(editCreateManager, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.inviteManager(editCreateManager, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminManagerApi - factory interface
 * @export
 */
exports.AdminManagerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminManagerApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"delete\", \"Admin\"]
         * @summary Delete manager | Permission required: [\"delete\", \"Admin\"]
         * @param {string} managerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManager(managerId, options) {
            return localVarFp.deleteManager(managerId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Admin\"]
         * @summary Edit manager | Permission required: [\"update\", \"Admin\"]
         * @param {string} managerId
         * @param {EditCreateManager} [editCreateManager]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editManager(managerId, editCreateManager, options) {
            return localVarFp.editManager(managerId, editCreateManager, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Admin\"]
         * @summary Get all managers | Permission required: [\"readAll\", \"Admin\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllManagers(filter, options) {
            return localVarFp.getAllManagers(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"create\", \"Admin\"]
         * @summary Invite manager | Permission required: [\"create\", \"Admin\"]
         * @param {EditCreateManager} [editCreateManager]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteManager(editCreateManager, options) {
            return localVarFp.inviteManager(editCreateManager, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminManagerApi - object-oriented interface
 * @export
 * @class AdminManagerApi
 * @extends {BaseAPI}
 */
class AdminManagerApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"delete\", \"Admin\"]
     * @summary Delete manager | Permission required: [\"delete\", \"Admin\"]
     * @param {string} managerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminManagerApi
     */
    deleteManager(managerId, options) {
        return exports.AdminManagerApiFp(this.configuration).deleteManager(managerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Admin\"]
     * @summary Edit manager | Permission required: [\"update\", \"Admin\"]
     * @param {string} managerId
     * @param {EditCreateManager} [editCreateManager]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminManagerApi
     */
    editManager(managerId, editCreateManager, options) {
        return exports.AdminManagerApiFp(this.configuration).editManager(managerId, editCreateManager, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Admin\"]
     * @summary Get all managers | Permission required: [\"readAll\", \"Admin\"]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminManagerApi
     */
    getAllManagers(filter, options) {
        return exports.AdminManagerApiFp(this.configuration).getAllManagers(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"create\", \"Admin\"]
     * @summary Invite manager | Permission required: [\"create\", \"Admin\"]
     * @param {EditCreateManager} [editCreateManager]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminManagerApi
     */
    inviteManager(editCreateManager, options) {
        return exports.AdminManagerApiFp(this.configuration).inviteManager(editCreateManager, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminManagerApi = AdminManagerApi;
