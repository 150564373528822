/** @jsxImportSource @emotion/react */
import {
	alpha,
	Checkbox,
	Grid,
	LinearProgress,
	linearProgressClasses,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import queryString from "query-string";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import FillScreenScrollView from "../FillScreenScrollView";
import TableHeaderCell from "./cells/TableHeaderCell";
import TouchTargetCell from "./cells/TouchTargetCell";
export interface IHeadCellMenuItem {
	label: string;
	onClick: () => void;
}
export interface IHeadCell {
	id: string;
	label: string;
	subtitle?: string;
	notSelectable?: boolean;
	colSpan?: number;
	width?: number;
	menu?: IHeadCellMenuItem[];
}
export type Order = "asc" | "desc";

export interface FilterObject {
	[x: string]: any;
}

export interface IQueryFilter {
	page?: number;
	perPage?: number;
	order?: Order;
	orderBy?: string;
	filter?: FilterObject[];
	// filter?: string;
}

function AdminTable<T>(props: {
	data: T[] | undefined;
	queryFilter: [IQueryFilter, (value: IQueryFilter) => void];
	count: number;
	loading?: boolean;
	headCells: IHeadCell[];
	children: (data: T[]) => any;
	occlusionHeight?: number;
	size?: "medium" | "small";
	fullWidth?: boolean;
	disablePagination?: boolean;
	color?: "primary" | "secondary";
	disableAllRowSelectable?: boolean;
	allRowSelected?: boolean;
	onSelectAllRow?: () => void;
	hasOption?: boolean;
	preContentSection?: React.ReactNode | React.ReactNode[];
}) {
	const { queryFilter } = props;

	const currentFilter = queryFilter[0];

	const theme = useTheme();

	const setFilter = (name: keyof IQueryFilter, value: any) => {
		const newFilter = { ...currentFilter };
		newFilter[name] = value;
		queryFilter[1](newFilter);
	};

	const { t } = useTranslation();

	const isSm = useBetterMediaQuery("sm");

	return (
		<FillScreenScrollView
			debugName="adminTable"
			sx={{
				minWidth: props?.fullWidth ? "100%" : "inherit",
				background: "transparent",
			}}
		>
			<TableContainer
				css={{
					width: "100%",
					overflowY: "auto",
					maxHeight: `calc(100% - ${60}px)`,
				}}
			>
				<Table stickyHeader size={props.size || "medium"} sx={{ tableLayout: "fixed", width: "100%" }}>
					<TableHead
						sx={{
							"& .MuiTableCell-root": {
								py: 2,
								px: { xs: 2, lg: 3 },
								"& .MuiTableCell-paddingCheckbox": {
									px: { xs: 1, lg: 2 },
								},
							},
						}}
					>
						<TableRow
							sx={{
								cursor: "unset",
								"&:hover": {
									backgroundColor: "unset",
								},
							}}
						>
							{!props.disableAllRowSelectable && (
								<TouchTargetCell
									css={{
										borderBottomLeftRadius: 16,
										"&.MuiTableCell-paddingCheckbox": {
											padding: "0px 8px",
										},
									}}
								>
									<Checkbox
										checked={props.allRowSelected}
										onChange={() => {
											props.onSelectAllRow?.();
										}}
										size="medium"
										color={props.color ?? "primary"}
									/>
								</TouchTargetCell>
							)}
							{props.headCells?.map((headCell, index) => {
								if (headCell.colSpan === 0) return null; //custom rules to remove head cells
								return (
									<TableHeaderCell
										key={headCell.id}
										headCell={headCell}
										currentFilter={currentFilter}
										queryFilter={queryFilter}
									/>
								);
							})}
							{props.hasOption && (
								<TableCell
									padding="checkbox"
									css={{
										width: 60,
										border: "none",
									}}
								/>
							)}
						</TableRow>
					</TableHead>
					<TableBody
						sx={{
							"& .MuiTableCell-root": {
								padding: { xs: 2, lg: 3 },
								borderTop: `4px solid ${theme.palette.background.default}`,
								borderBottom: `4px solid ${theme.palette.background.default}`,
							},
							"& .MuiTableCell-paddingCheckbox": {
								px: { xs: 1, lg: 2 },
							},
						}}
					>
						{props.preContentSection}
						{props.children(props?.data || [])}
					</TableBody>
				</Table>
			</TableContainer>
			{props.loading && (
				<LinearProgress
					sx={{
						[`& .${linearProgressClasses.bar}`]: {
							borderRadius: 5,
							backgroundColor: `${theme.palette[props.color ?? "primary"].main} !important`,
						},
					}}
					color={props.color ?? "primary"}
					css={{ zIndex: 1000, top: 0, left: 0, right: 0 }}
				/>
			)}
			{!props?.disablePagination && (
				<TablePagination
					rowsPerPageOptions={[1, 5, 10, 25, 50]}
					labelRowsPerPage={t("adminTable.rowsPerPage")}
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						"& .MuiToolbar-root": {
							backgroundColor: "transparent",
							height: "100%",
							alignItems: "center",
						},
						"& div:first-of-type": {
							maxWidth: "100%",
							flexWrap: "wrap",
						},
						"& .MuiTablePagination-displayedRows": {
							margin: 0,
						},
						"& .MuiTablePagination-selectLabel": {
							marginTop: "7px",
							marginBottom: "7px",
							fontWeight: 700,
						},
						"& .MuiTablePagination-actions": {
							marginLeft: "8px !important",
						},
						"& .MuiTablePagination-spacer": {
							display: "none",
						},
						"& .MuiTablePagination-select": {
							py: "8px !important",
						},
					}}
					nextIconButtonProps={{
						color: props.color || "primary",
						sx: {
							background: alpha(theme.palette.primary.main, 0.06),
							marginLeft: 1,
							borderRadius: "18px",
							position: "relative",
							"&.Mui-disabled": {
								background: alpha(theme.palette.primary.main, 0.06),
								"&::before": {
									border: `2px solid rgba(0, 0, 0, 0.26)`,
								},
							},
							"&::before": {
								content: '""',
								display: "inline-block",
								width: 24,
								height: 24,
								borderRadius: 12,
								border: `2px solid ${theme.palette[props.color ?? "primary"].main}`,
								flexShrink: 0,
								position: "absolute",
							},
						},
					}}
					backIconButtonProps={{
						color: props.color || "primary",
						sx: {
							background: alpha(theme.palette.primary.main, 0.06),
							borderRadius: 18,
							"&::before": {
								content: '""',
								display: "inline-block",
								width: 24,
								height: 24,
								borderRadius: 12,
								border: `2px solid ${theme.palette[props.color ?? "primary"].main}`,
								flexShrink: 0,
								position: "absolute",
							},
							"&.Mui-disabled": {
								background: alpha(theme.palette.primary.main, 0.06),
								"&::before": {
									border: `2px solid rgba(0, 0, 0, 0.26)`,
								},
							},
						},
					}}
					labelDisplayedRows={({ from, to, count, page }) => {
						const rowsPerPage = currentFilter?.perPage || 10;
						const totalPages = Math.ceil(count / rowsPerPage);
						const maxPages = isSm ? 7 : 5;
						let deltaBounds = Math.floor(maxPages / 2);
						if (deltaBounds < 0) deltaBounds = 0;
						const remainingPages = totalPages > maxPages ? maxPages : totalPages;
						let lb = page > deltaBounds ? page - deltaBounds : 0;
						if (lb > totalPages - maxPages) lb = totalPages - maxPages;
						if (lb < 0) lb = 0;
						let ub = lb + remainingPages;
						if (ub > totalPages) ub = totalPages;
						const pages = [];
						for (let i = 0; i < ub; i++) {
							if (i >= lb) pages.push({ index: i, selected: i === page });
						}
						return (
							<span>
								<Grid
									component="span"
									container
									alignItems="center"
									spacing={2}
									css={{ flex: 1 }}
									justifyContent="space-between"
								>
									<Grid component="span" item sx={{ fontWeight: 700 }}>
										{t("adminTable.resultsFromTo", { from, to, count })}
									</Grid>
									<Grid component="span" item>
										<Grid
											container
											component="span"
											css={{ height: 28, overflow: "visible", borderRadius: 16 }}
											alignItems="center"
										>
											{pages.map((p) => {
												return (
													<Grid
														component="span"
														key={"page-" + p.index}
														css={{
															marginTop: -2,
															height: 32,
															width: 32,
															borderRadius: 16,
															backgroundColor: p.selected ? theme.palette[props.color || "primary"].main : "transparent",
															display: "flex",
															alignItems: "center",
															justifyContent: "center",
															transition: theme.transitions.create(["background-color", "color", "font-weight"]),
															cursor: p.selected ? "inherit" : "pointer",
															":hover": {
																backgroundColor: p.selected
																	? theme.palette[props.color || "primary"].main
																	: alpha(theme.palette[props.color || "primary"].main, 0.2),
															},
														}}
														item
														onClick={() => {
															setFilter("page", p.index);
														}}
													>
														<Typography
															component="span"
															css={{
																fontWeight: p.selected ? "bold" : "normal",
																color: p.selected ? "white" : theme.palette.text.primary,
																transition: theme.transitions.create(["background-color", "color", "font-weight"]),
															}}
															children={p.index + 1}
														/>
													</Grid>
												);
											})}
										</Grid>
									</Grid>
								</Grid>
							</span>
						);
					}}
					count={props.count || 0}
					component="div"
					title={t("adminTable.rowsPerPage")}
					lang="it-IT"
					rowsPerPage={currentFilter?.perPage || 10}
					page={currentFilter?.page || 0}
					onPageChange={(event, newPage) => {
						setFilter("page", newPage);
					}}
					onRowsPerPageChange={(event) => {
						const newPerPageValue = parseInt(event.target.value);
						const perPage = currentFilter?.perPage || 10;
						const page = currentFilter?.page || 0;
						const oldIndex = page * perPage; // 10 | 0;
						const newPage = oldIndex / newPerPageValue;
						const newFilter = { ...currentFilter };
						newFilter["page"] = Math.floor(newPage);
						newFilter["perPage"] = newPerPageValue;
						queryFilter[1](newFilter);
					}}
				/>
			)}
		</FillScreenScrollView>
	);
}

export default AdminTable;

export const defaultQueryFilter: IQueryFilter = {
	page: 0,
	perPage: 25,
	order: "asc",
	orderBy: "createdAt",
};

//! UTILITIES
export function transformToStringFrom(queryFilter: IQueryFilter): string {
	const test = queryString.stringify({
		...queryFilter,
		filter: JSON.stringify(queryFilter.filter),
	});
	return test;
}

export function parseCountFrom(rawData: any): number {
	return parseInt(rawData?.headers?.count || 0);
}

export function transformToObjectFrom(filter: string): IQueryFilter {
	const test = queryString.parse(filter);
	return {
		...defaultQueryFilter,
		...test,
		filter: JSON.parse((test.filter || "{}") as string),
	};
}
