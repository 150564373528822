import { Stack, SxProps, Theme, Typography } from "@mui/material";
import FillScreenScrollView from "./FillScreenScrollView";
import type { IPlatformIconNames } from "./icons/IPlatformIcon";
import IPlatformIcon from "./icons/IPlatformIcon";

type OutletContainerProps = {
	children?: React.ReactNode;
	title?: string;
	titleIcon?: IPlatformIconNames;
	fillScreen?: boolean;
	sx?: SxProps<Theme>;
};
const OutletContainer: React.FC<OutletContainerProps> = ({ children, title, titleIcon, fillScreen, sx = {} }) => {
	return (
		<FillScreenScrollView debugName="outletContainer" disableFixedHeight={!fillScreen} sx={{ padding: 9, ...sx }}>
			{title && titleIcon && (
				<Stack direction={"row"} alignItems="center" justifyContent={"flex-start"}>
					<IPlatformIcon size={36} color="black" name={titleIcon} iconCss={{ marginTop: 0, lineHeight: "unset" }} />
					<Typography children={title} variant="subtitle1" sx={{ marginLeft: 2 }} />
				</Stack>
			)}
			{children}
		</FillScreenScrollView>
	);
};
export default OutletContainer;
