import type { User } from "innovation-api-manager";

export function getUserFullName(user: User) {
	const fullname = (user?.name || "") + " " + (user?.surname || "");
	if (fullname.trim() === "") {
		if (!user?.email?.includes("@")) {
			return "Sconosciuto";
		} else {
			return user.email.split("@")[0];
		}
	}
	return fullname;
}
