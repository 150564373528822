/** @jsxImportSource @emotion/react */

import { alpha, Card, CardContent, Chip, Grid, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import type { AdminFeedbackSummarySprintInteraction, AdminFeedbackSummaryTeam } from "innovation-api-manager";
import { Link } from "react-router-dom";
import IPlatformIcon from "../../../../../../../components/icons/IPlatformIcon";
import DataTable from "../../../../../../../components/tables/DataTable/DataTable";
import type { DataTableColumn } from "../../../../../../../components/tables/DataTable/interfaces/DataTable";
import { useSprintFeedbacksContextSelector } from "../controllers/SprintDeliverableController";

type SprintDeliverablesCardProps = {
	children?: React.ReactNode;
};
const SprintDeliverablesCard: React.FC<SprintDeliverablesCardProps> = (props) => {
	const theme = useTheme();

	const feedbackSummary = useSprintFeedbacksContextSelector("feedbackSummary");
	const loadingFeedbackSummary = useSprintFeedbacksContextSelector("loadingFeedbackSummary");
	const errorFeedbackSummary = useSprintFeedbacksContextSelector("errorFeedbackSummary");
	const revalidateFeedbackSummary = useSprintFeedbacksContextSelector("revalidateFeedbackSummary");

	const sprints = useSprintFeedbacksContextSelector("sprints");
	const loadingSprints = useSprintFeedbacksContextSelector("loadingSprints");

	const columns: DataTableColumn<AdminFeedbackSummaryTeam>[] = [
		{
			id: "button_0",
			renderData: (data, fullObject, index, collapsibleHandler) => {
				return (
					<IconButton onClick={collapsibleHandler}>
						<IPlatformIcon name="arrow_mini_outline_down" />
					</IconButton>
				);
			},
			isCollapsibleHandler: true,
		},
		{
			id: "name",
			label: "Team",
			renderData: (value) => {
				return <Typography children={value} variant="subtitle1" sx={{ fontWeight: 600 }} />;
			},
		},
		{
			id: "mentors",
			label: "Mentor",
			renderData: (mentors) => {
				return (
					<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
						{mentors?.map((mentor: any, index: number) => {
							return (
								<Chip
									label={`${mentor?.name} ${mentor?.surname}`}
									sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.06), color: theme.palette.primary.main }}
									key={mentor?._id}
								/>
							);
						})}
						{!mentors || !mentors.length ? (
							<Typography children="Nessun mentor assegnato" variant="body2" color="text.disabled" />
						) : null}
					</Stack>
				);
			},
		},
		{
			id: "group",
			label: "Gruppo",
			renderData: (group) => {
				return (
					<Typography
						children={group?.name || "Nessun gruppo"}
						variant="body2"
						color={!!group ? "text.primary" : "text.disabled"}
					/>
				);
			},
		},
		{
			id: "sprintInteractions",
			label: "Riepilogo",
			renderData: (sprintInteractions: AdminFeedbackSummarySprintInteraction[]) => {
				return (
					<Stack direction="row" spacing={2} alignItems="center">
						<Typography children="Deliverable: " variant="body2" color="text.secondary" />
						<Stack direction="row" spacing={1.5} alignItems="center">
							{sprints?.map((sprint, index) => {
								const interactionIndex = sprintInteractions.findIndex((interaction) => interaction.sprintId === sprint?.id);
								const sprintInteraction = sprintInteractions[interactionIndex];

								const hasEvaluation =
									interactionIndex !== -1 &&
									!!sprintInteraction?.adminEvaluationAvg &&
									sprintInteraction?.adminEvaluationAvg !== 0;

								const notCompleted = interactionIndex === -1 || !sprintInteraction?.isCompleted;
								const evaluation = sprintInteraction?.adminEvaluationAvg;

								return (
									<Tooltip
										title={`Sprint ${index + 1} | ${
											notCompleted
												? "Sprint non completato"
												: !hasEvaluation
												? "Deliverable non ancora valutati"
												: evaluation === 1
												? "Feedback positivo"
												: evaluation === 2
												? "Feedback neutro"
												: "Feedback negativo"
										}`}
										key={sprint?.id}
									>
										<Stack
											direction="row"
											justifyContent="center"
											alignItems="center"
											sx={{ width: 16, height: 16 }}
											key={sprint?.id}
										>
											{!notCompleted ? (
												hasEvaluation ? (
													<div
														css={[
															{
																width: 16,
																height: 16,
																borderRadius: 16,
																marginTop: 2,
															},
															{
																backgroundColor:
																	evaluation === 1
																		? theme.palette.success.main
																		: evaluation === 2
																		? theme.palette.warning.main
																		: theme.palette.error.main,
															},
														]}
													/>
												) : (
													<IPlatformIcon name="discover_twocolors_01" color="primary" size={19} />
												)
											) : (
												<IPlatformIcon name="close" color="primary" size={19} />
											)}
										</Stack>
									</Tooltip>
								);
							})}
						</Stack>
					</Stack>
				);
			},
		},
		{
			id: "button_1",
			renderData: (data, team) => {
				return (
					<Tooltip title="Vedi deliverable">
						<Link to={`../team/${team?.id}`} target="_blank" rel="noopener noreferrer">
							<IconButton>
								<IPlatformIcon name="book-sg" />
							</IconButton>
						</Link>
					</Tooltip>
				);
			},
		},
	];

	return (
		<Card sx={{ maxWidth: theme.breakpoints.values.lg }}>
			<CardContent>
				<DataTable
					data={feedbackSummary || []}
					columns={columns}
					loading={loadingFeedbackSummary || loadingSprints}
					collapsible
					collapsibleRow={(item: AdminFeedbackSummaryTeam) => {
						return (
							<Grid container spacing={3}>
								{item?.users?.map((user) => {
									return (
										<Grid item xs={3} key={user?.id} sx={{ my: 3 }}>
											<Stack>
												<Typography children={`${user?.name} ${user?.surname}`} variant="subtitle1" sx={{ fontWeight: 600 }} />
												<Typography children={user?.email} variant="body2" color="text.secondary" />
											</Stack>
										</Grid>
									);
								})}
							</Grid>
						);
					}}
				/>
			</CardContent>
		</Card>
	);
};

export default SprintDeliverablesCard;
