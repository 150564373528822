/** @jsxImportSource @emotion/react */
import { Collapse, TableCell, tableCellClasses, TableRow, useTheme } from "@mui/material";
import { ReactNode, useState } from "react";
import React from "react";
import type { DataTableColumn } from "../interfaces/DataTable";

type DataTableRowProps<T> = {
	item: T;
	columns?: DataTableColumn<T>[];
	index: number;
	collapsible?: boolean;
	collapsibleRow?: (item: T) => React.ReactNode;
};

function DataTableRow<T>(props: DataTableRowProps<T>) {
	const theme = useTheme();

	const { item, columns, index: tableIndex, collapsible, collapsibleRow } = props;

	const [open, setOpen] = useState(false);

	return (
		<React.Fragment>
			<TableRow
				key={tableIndex}
				css={[
					{ backgroundColor: "transparent" },
					collapsible && { [`& .${tableCellClasses.root}`]: { borderBottom: "unset" } },
				]}
			>
				{(columns || []).map((column, index) => {
					const columnId = column.id as keyof T;

					return (
						<TableCell
							children={
								(column?.isCollapsibleHandler
									? column?.renderData?.(item?.[columnId] ?? null, item, tableIndex, () => {
											setOpen((open) => !open);
									  })
									: column?.renderData?.(item?.[columnId] ?? null, item, tableIndex)) ??
								((typeof item[columnId] === "object" ? (item[columnId] == null ? null : "OBJ") : item[columnId]) as ReactNode)
							}
							{...column?.columnProps}
							css={[{ backgroundColor: "#ffffff" }, column?.columnProps?.css || {}]}
							key={`${columnId}-${index}`}
						/>
					);
				})}
			</TableRow>
			{collapsible && collapsibleRow && (
				<TableRow>
					<TableCell sx={{ py: 0 }} colSpan={columns?.length}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<div>{collapsibleRow?.(item)}</div>
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</React.Fragment>
	);
}

export default DataTableRow;
