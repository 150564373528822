import { Button, ButtonProps, CircularProgress } from "@mui/material";

type Props = {
	loading: boolean;
} & ButtonProps;

const LoadingTextButton: React.FC<Props> = ({ loading, children, ...props }) => {
	// const theme = useTheme();
	return (
		<Button
			startIcon={
				loading && (
					<CircularProgress
						size={props.size === "small" ? 16 : props.size === "medium" ? 18 : 20}
						sx={{ color: "inherit", flexShrink: 0 }}
					/>
				)
			}
			disabled={loading}
			{...props}
		>
			{/* <Stack
				direction="row"
				alignItems="center"
				justifyContent="center"
				sx={{ position: "absolute", width: "100%", height: "100%" }}
			>
				{loading && (
					<CircularProgress
						size={props.size === "small" ? 16 : props.size === "medium" ? 20 : 24}
						sx={{ color: "inherit", flexShrink: 0 }}
					/>
				)}
				<Box sx={{ visibility: "hidden" }}>{children}</Box>
			</Stack>
			<Box
				sx={{
					transform: `translateX(${loading ? (props.size === "small" ? 16 : props.size === "medium" ? 20 : 24) : 0}px)`,
					transition: theme.transitions.create(["transform"]),
				}}
			>
				{children}
			</Box> */}
			{children}
		</Button>
	);
};

export default LoadingTextButton;
