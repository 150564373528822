import { lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Scaffold from "../components/scaffold/Scaffold";
import NotFoundPage from "../pages/404/NotFoundPage";
import ProjectDeliverablePage from "../pages/Tenants/pages/Projects/pages/ProjectDeliverable/ProjectDeliverablePage";

import ProjectInfoPage from "../pages/Tenants/pages/Projects/pages/ProjectInfo/ProjectInfoPage";
import ProjectSprintsPage from "../pages/Tenants/pages/Projects/pages/ProjectSprints/ProjectSprintsPage";
import ProjectTeamPage from "../pages/Tenants/pages/Projects/pages/ProjectTeam/ProjectTeamPage";
import SuspenseWrapper from "./components/SuspenseWrapper";

const Test = lazy(() => import("../pages/Test/Test" /* webpackChunkName: "Test" */));
const Tenants = lazy(() => import("../pages/Tenants/Tenants" /* webpackChunkName: "Tenants" */));
const Users = lazy(() => import("../pages/Users/Users" /* webpackChunkName: "Users" */));
const Settings = lazy(() => import("../pages/Settings/Settings" /* webpackChunkName: "Settings" */));
const TenantInfo = lazy(
	() => import("../pages/Tenants/pages/TenantInfo/TenantInfo" /* webpackChunkName: "TenantInfo" */)
);
const AssignTenantManager = lazy(
	() =>
		import("../pages/Tenants/pages/AssignTenantManager/AssignTenantManager" /* webpackChunkName: "AssignTenantManager" */)
);
const InvitedUsers = lazy(
	() => import("../pages/Tenants/pages/InvitedUsers/InvitedUsers" /* webpackChunkName: "InvitedUsers" */)
);
const CustomizeTenant = lazy(
	() => import("../pages/Tenants/pages/CustomizeTenant/CustomizeTenant" /* webpackChunkName: "CustomizeTenant" */)
);
const MentorEPM = lazy(() => import("../pages/Tenants/pages/MentorEPM/MentorEPM" /* webpackChunkName: "MentorEPM" */));
const Projects = lazy(() => import("../pages/Tenants/pages/Projects/Projects" /* webpackChunkName: "Projects" */));
const MailSettings = lazy(
	() => import("../pages/Tenants/pages/MailSettings/MailSettings" /* webpackChunkName: "MailSettings" */)
);
const Tags = lazy(() => import("../pages/Tenants/pages/Tags/Tags" /* webpackChunkName: "Tags" */));
const VisualizedAvatar = lazy(
	() => import("../pages/Tenants/pages/VisualizedAvatar/VisualizedAvatar" /* webpackChunkName: "VisualizedAvatar" */)
);
const Training = lazy(() => import("../pages/Tenants/pages/Training/Training" /* webpackChunkName: "Training" */));
const SuggestedContents = lazy(
	() => import("../pages/Tenants/pages/SuggestedContents/SuggestedContents" /* webpackChunkName: "SuggestedContents" */)
);
const Courses = lazy(() => import("../pages/Tenants/pages/Courses/Courses" /* webpackChunkName: "Courses" */));
const SingleCourse = lazy(
	() => import("../pages/Tenants/pages/Courses/pages/SingleCourse/SingleCourse" /* webpackChunkName: "SingleCourse" */)
);
const ExternalContents = lazy(
	() =>
		import(
			"../pages/Tenants/pages/Tags/pages/ExternalContents/ExternalContents" /* webpackChunkName: "ExternalContents" */
		)
);
const ProjectGroups = lazy(
	() =>
		import("../pages/Tenants/pages/Projects/pages/ProjectGroups/ProjectGroups" /* webpackChunkName: "ProjectGroups" */)
);
const ProjectParticipants = lazy(
	() =>
		import(
			"../pages/Tenants/pages/Projects/pages/ProjectParticipants/ProjectParticipants" /* webpackChunkName: "ProjectParticipants" */
		)
);

const RoutesList = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Scaffold />}>
					<Route index element={<Navigate to="/tenants" replace />} />
					{/* <Route
						path="test"
						element={
							<SuspenseWrapper>
								<Test />
							</SuspenseWrapper>
						}
					/> */}
					<Route
						path="tenants"
						element={
							<SuspenseWrapper>
								<Tenants />
							</SuspenseWrapper>
						}
					/>
					<Route
						path="users"
						element={
							<SuspenseWrapper>
								<Users />
							</SuspenseWrapper>
						}
					/>
					<Route
						path="settings"
						element={
							<SuspenseWrapper>
								<Settings />
							</SuspenseWrapper>
						}
					/>

					<Route path="tenants/:tenantId">
						<Route index element={<Navigate to="assign_tm" replace />} />
						<Route
							path="assign_tm"
							element={
								<SuspenseWrapper>
									<AssignTenantManager />
								</SuspenseWrapper>
							}
						/>
						<Route
							path="info"
							element={
								<SuspenseWrapper>
									<TenantInfo />
								</SuspenseWrapper>
							}
						/>
						<Route
							path="invited_users"
							element={
								<SuspenseWrapper>
									<InvitedUsers />
								</SuspenseWrapper>
							}
						/>
						<Route
							path="mentors"
							element={
								<SuspenseWrapper>
									<MentorEPM />
								</SuspenseWrapper>
							}
						/>
						<Route
							path="customize"
							element={
								<SuspenseWrapper>
									<CustomizeTenant />
								</SuspenseWrapper>
							}
						/>
						<Route
							path="projects"
							element={
								<SuspenseWrapper>
									<Projects />
								</SuspenseWrapper>
							}
						/>
						<Route
							path="mail_settings"
							element={
								<SuspenseWrapper>
									<MailSettings />
								</SuspenseWrapper>
							}
						/>
						<Route
							path="tags"
							element={
								<SuspenseWrapper>
									<Tags />
								</SuspenseWrapper>
							}
						/>
						<Route path="tags/:tagId">
							<Route index element={<Navigate to="external_contents" replace />} />
							<Route
								path="external_contents"
								element={
									<SuspenseWrapper>
										<ExternalContents />
									</SuspenseWrapper>
								}
							/>
						</Route>
						<Route
							path="visualized_avatar"
							element={
								<SuspenseWrapper>
									<VisualizedAvatar />
								</SuspenseWrapper>
							}
						/>
						<Route
							path="suggested_contents"
							element={
								<SuspenseWrapper>
									<SuggestedContents />
								</SuspenseWrapper>
							}
						/>
						<Route
							path="training"
							element={
								<SuspenseWrapper>
									<Training />
								</SuspenseWrapper>
							}
						/>
						<Route
							path="courses"
							element={
								<SuspenseWrapper>
									<Courses />
								</SuspenseWrapper>
							}
						/>

						<Route
							path="courses/:courseId"
							element={
								<SuspenseWrapper>
									<SingleCourse />
								</SuspenseWrapper>
							}
						/>

						<Route path="projects/:projectId">
							<Route index element={<Navigate to="info" replace />} />
							<Route
								path="info"
								element={
									<SuspenseWrapper>
										<ProjectInfoPage />
									</SuspenseWrapper>
								}
							/>
							<Route
								path="groups"
								element={
									<SuspenseWrapper>
										<ProjectGroups />
									</SuspenseWrapper>
								}
							/>
							<Route
								path="participants"
								element={
									<SuspenseWrapper>
										<ProjectParticipants />
									</SuspenseWrapper>
								}
							/>
							<Route
								path="sprints"
								element={
									<SuspenseWrapper>
										<ProjectSprintsPage />
									</SuspenseWrapper>
								}
							/>
							<Route
								path="deliverable"
								element={
									<SuspenseWrapper>
										<ProjectDeliverablePage />
									</SuspenseWrapper>
								}
							/>
							<Route
								path="team/:teamId"
								element={
									<SuspenseWrapper>
										<ProjectTeamPage />
									</SuspenseWrapper>
								}
							/>
						</Route>
					</Route>
				</Route>
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</BrowserRouter>
	);
};

export default RoutesList;
