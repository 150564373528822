"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.15.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoftwareApi = exports.SoftwareApiFactory = exports.SoftwareApiFp = exports.SoftwareApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * SoftwareApi - axios parameter creator
 * @export
 */
exports.SoftwareApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"readAll\", \"Software\"]
         * @summary Get all software | Permission required: [\"readAll\", \"Software\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSoftware: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/software/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * SoftwareApi - functional programming interface
 * @export
 */
exports.SoftwareApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.SoftwareApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"readAll\", \"Software\"]
         * @summary Get all software | Permission required: [\"readAll\", \"Software\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSoftware(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllSoftware(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * SoftwareApi - factory interface
 * @export
 */
exports.SoftwareApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.SoftwareApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"readAll\", \"Software\"]
         * @summary Get all software | Permission required: [\"readAll\", \"Software\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSoftware(filter, options) {
            return localVarFp.getAllSoftware(filter, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SoftwareApi - object-oriented interface
 * @export
 * @class SoftwareApi
 * @extends {BaseAPI}
 */
class SoftwareApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"readAll\", \"Software\"]
     * @summary Get all software | Permission required: [\"readAll\", \"Software\"]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoftwareApi
     */
    getAllSoftware(filter, options) {
        return exports.SoftwareApiFp(this.configuration).getAllSoftware(filter, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.SoftwareApi = SoftwareApi;
